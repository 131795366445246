import {
  Box,
  Fab,
  Typography,
  InputAdornment,
  CircularProgress
} from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Cell, ListComponent } from "../listComponent/ListComponent";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { TextFieldStyled } from "../inputs/text-field/text-field.styles";
import axios from "axios";
import { Role } from "../../data/models/Role";
import { urlRole } from "../../services/global";
import { DrawerStyled } from "./RolesDrawer.styles";
import { Button } from "../buttons/Button/Button";
import { PermissionsDrawer } from "./permissions/PermissionsDrawer";
import { useAlert } from "../../hooks/alert";
import { StatusActive } from "../statusActive/StatusActive";

const cells: Cell[] = [
  {
    id: "name",
    label: "name",
    size: 0.9,
    button: true
  },
  {
    id: "isactive",
    label: "Status",
    size: 0.1,
    button: true,
    format: (value) => (
      <StatusActive value={value.isactive} sx={{ ml: "10%" }} />
    )
  }
];

interface RolesDrawerProps {
  onChange?: () => void;
}

export const RolesDrawer: React.FC<RolesDrawerProps> = ({ onChange }) => {
  const [data, setData] = useState<Role[]>([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viewList, setViewList] = useState(true);
  const [selectedRole, setSelectedRole] = useState<Role | null>(null);
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");
  const { AlertMessage } = useAlert();

  const filteredData = search
    ? data?.filter((role) =>
        role.name.toUpperCase().includes(search.toUpperCase())
      )
    : data;

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await axios.get<Role[]>(urlRole);
      setLoading(false);
      setData(data);
    } catch (err) {
      setLoading(false);
      AlertMessage("error", "defaultMessage", err);
    }
  }, [AlertMessage]);

  const onCreateRole = async () => {
    if (onChange) {
      onChange();
    }
    await getData();
  };

  const onClosePermissions = () => {
    setSelectedRole(null);
    setViewList(true);
  };

  const handleEditClick = (value: Role) => {
    setSelectedRole(value);
  };

  const handleInactive = async (value: Role) => {
    try {
      await axios.delete(`${urlRole}/${value.id}`);
      AlertMessage("success", "successfullyInactiveRole");
      await getData();
      if (onChange) {
        onChange();
      }
    } catch (err) {
      AlertMessage("error", "defaultMessage", err);
    }
  };

  useEffect(() => {
    if (open) {
      getData();
    }
  }, [getData, open]);

  return (
    <Box>
      <Fab
        color="primary"
        onClick={() => setOpen((prev) => !prev)}
        sx={{
          position: "fixed",
          right: 0,
          borderRadius: "20px 0 0 20px",
          top: "70px"
        }}
      >
        <AdminPanelSettingsIcon fontSize="large" />
      </Fab>
      <DrawerStyled
        anchor="right"
        open={open}
        onClose={() => setOpen((prev) => !prev)}
        sx={{ position: "relative" }}
      >
        <Fab
          color="primary"
          onClick={() => setOpen((prev) => !prev)}
          sx={{
            position: "absolute",
            left: 0,
            borderRadius: "0 20px 20px 0",
            top: "70px"
          }}
        >
          <ChevronRightIcon fontSize="large" />
        </Fab>
        {viewList && !selectedRole ? (
          <Box
            border="1px solid #DDDDDD"
            display="flex"
            flexDirection="column"
            gap={4}
            borderRadius="15px"
            p={4}
            mb={8}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexDirection={{ xs: "column", md: "row" }}
              gap={2}
            >
              <Typography variant="body1" color="#000000">
                {t("roleList")}
              </Typography>

              <Box
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                alignItems="center"
                gap={2}
                maxWidth={{ md: "60%", xs: "100%" }}
                width="100%"
              >
                <TextFieldStyled
                  fullWidth
                  size="small"
                  value={search}
                  label={t("searchRole")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon color="primary" />
                      </InputAdornment>
                    )
                  }}
                  onChange={(e) => setSearch(e.target.value)}
                />

                <Button onClick={() => setViewList((prev) => !prev)}>
                  {t("createRole")}
                </Button>
              </Box>
            </Box>

            {loading ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt={4}
                gap={2}
              >
                <Typography variant="body1" color="#000000">
                  {t("loading")} {t("users")}
                </Typography>
                <CircularProgress />
              </Box>
            ) : (
              <ListComponent
                cells={cells}
                rows={filteredData}
                initialOrder={{
                  orderBy: "name",
                  direction: "asc"
                }}
                avatar={{
                  url: "",
                  path: "name"
                }}
                noDataText="noRoleData"
                moreButtons={[
                  { label: "edit", action: handleEditClick },
                  {
                    label: "inactivate",
                    action: handleInactive,
                    viewKey: "isactive"
                  }
                ]}
                onDoubleClick={handleEditClick}
              />
            )}
          </Box>
        ) : (
          <PermissionsDrawer
            onCreate={onCreateRole}
            onClose={onClosePermissions}
            role={selectedRole}
          />
        )}
      </DrawerStyled>
    </Box>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer,
  Box,
  Collapse
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Person,
  PowerSettingsNew,
  ExpandLess,
  ExpandMore
} from "@mui/icons-material";
import { useAuth } from "../../../contexts/authContext";
import "./DrawerNavigation.css";
import { ListItemDrawer } from "../Header.styles";
import LogoKahshPayMobileNew from "../../../images/LogoKahshPayMobileNew.svg";
import { ReactNode, useEffect, useState } from "react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { LanguageSelectorComponent } from "../../languageSelector/LanguageSelectorComponent";
import { RouteItem, RouterLinkMenu } from "../../../constants/routes";

interface DrawerNavigationProps {
  children: ReactNode;
  open: boolean;
  setOpen: () => void;
}

export const DrawerNavigation: React.FC<DrawerNavigationProps> = ({
  children,
  open,
  setOpen
}) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { merchant, user, signOut } = useAuth();
  const { width } = useWindowDimensions();
  const [routes, setRoutes] = useState(RouterLinkMenu);

  const [variant, setVariant] = useState<"permanent" | "temporary">(
    "permanent"
  );

  useEffect(() => {
    if (width > 900) {
      setVariant("permanent");
    } else {
      setVariant("temporary");
      setOpen();
    }
  }, [width]);

  const handleLogout = () => {
    signOut();
  };

  const isSelected = (route: RouteItem) => {
    if (
      location.pathname.includes("/invoicelist") &&
      route.href === "/invoice"
    ) {
      return false;
    }

    if (route?.subMenu?.some((r) => !!location.pathname.includes(r.href))) {
      return true;
    }

    return !!location.pathname.includes(route.href);
  };

  const handleOpenSettings = (r: RouteItem) => {
    if (r.subMenu?.length) {
      setRoutes((prev) =>
        prev.map((route) =>
          route.href === r.href
            ? { ...r, isOpen: !r.isOpen }
            : { ...route, isOpen: false }
        )
      );
    } else {
      if (!r?.isSubMenu) {
        setRoutes((prev) =>
          prev.map((route) =>
            route?.subMenu ? { ...route, isOpen: false } : route
          )
        );
      }

      history.push(r.href);
      setOpen();
    }
  };

  return (
    <>
      <Drawer
        variant={variant}
        className="menuDrawer"
        open={open}
        onClose={setOpen}
      >
        <Box py={2}>
          <a href="/">
            <img
              src={LogoKahshPayMobileNew}
              className="logoImageKahshDrawerWeb"
              alt=""
            />
          </a>
        </Box>
        <List
          disablePadding
          sx={{
            height: "100%",
            overflowY: "auto",
            "&::-webkit-scrollbar": { display: "none" }
          }}
        >
          {routes.map((r) => (
            <Box key={r.href} sx={{ pt: 1, px: 1 }}>
              <>
                <ListItemDrawer
                  className={`${isSelected(r) && "selected"}`}
                  onClick={() => handleOpenSettings(r)}
                  sx={{
                    "&:hover": { borderRadius: "0.5rem" }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: isSelected(r) ? "#fff" : "#ddd",
                      minWidth: "auto",
                      mr: 1,
                      svg: {
                        fontSize: "2rem"
                      }
                    }}
                  >
                    {r.src}
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      color: isSelected(r) ? "#fff" : "#787A7B"
                    }}
                    primary={t(r.title)}
                  />
                  {!!r?.subMenu?.length && (
                    <>
                      {r?.isOpen ? (
                        <ExpandLess
                          color={isSelected(r) ? "inherit" : "disabled"}
                        />
                      ) : (
                        <ExpandMore
                          color={isSelected(r) ? "inherit" : "disabled"}
                        />
                      )}
                    </>
                  )}
                </ListItemDrawer>

                {!!r?.subMenu?.length && (
                  <Collapse in={r?.isOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <Box
                        sx={{
                          mt: 2,
                          ml: 4,
                          borderLeft: "2px solid #dddddd"
                        }}
                      >
                        {r.subMenu.map((subMenu) => (
                          <ListItemDrawer
                            key={subMenu.href}
                            className={`${
                              isSelected(subMenu)
                                ? "subMenuSelected"
                                : "subMenuItem"
                            }`}
                            onClick={() => handleOpenSettings(subMenu)}
                            sx={{ mt: 1 }}
                          >
                            <ListItemText primary={t(subMenu.title)} />
                          </ListItemDrawer>
                        ))}
                      </Box>
                    </List>
                  </Collapse>
                )}
              </>
            </Box>
          ))}
        </List>

        <Box sx={{ height: "200px" }} px={1}>
          <LanguageSelectorComponent
            sx={{ display: { md: "none", xs: "inherit" }, ml: 0.5 }}
          />
          <ListItemButton
            className="listItemUserWeb"
            sx={{
              "&:hover": {
                borderRadius: "0.5rem",
                bgcolor: "transparent",
                cursor: "default"
              }
            }}
          >
            <ListItemIcon>
              <Avatar
                variant="rounded"
                sx={{ bgcolor: (theme) => theme.palette.grey[300] }}
              >
                <Person />
              </Avatar>
            </ListItemIcon>

            <ListItemText
              primary={merchant?.corporateName}
              secondary={user?.email}
            />
          </ListItemButton>
          <ListItemButton
            onClick={handleLogout}
            sx={{
              "&:hover": { borderRadius: "0.5rem" }
            }}
          >
            <ListItemIcon>
              <PowerSettingsNew sx={{ color: "#DDDDDD", fontSize: "2.5rem" }} />
            </ListItemIcon>
            <ListItemText className="listItemUserWeb" primary={t("logout")} />
          </ListItemButton>
        </Box>
      </Drawer>

      <Box
        component="main"
        p={2}
        pt={{ xs: "calc(62px + 16px)", md: 2 }}
        pl={{ xs: 2, md: "calc(256px + 16px)" }}
        maxWidth={1400}
        mx="auto"
      >
        {children}
      </Box>
    </>
  );
};

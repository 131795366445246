import { useState } from "react";

import axios from "axios";

import { Grid, Typography, Box } from "@mui/material";

import { useTranslation } from "react-i18next";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";

import LogoKahshAndName from "../../images/logoKsh.svg";
import RedefinicaoDeSenhaNew from "../../images/passwordReset/RedefinicaoDeSenhaNew.png";

import { Button } from "../buttons/Button/Button";
import { TextField } from "../inputs/text-field/text-field.component";

import { useAlert } from "../../hooks/alert";

import { RECOVERY_PASSWORD_URL } from "../../services/global";
import { useHistory } from "react-router-dom";

type ForgotPasswordProps = {
  children?: string;
};

export function ForgotPassword(props: ForgotPasswordProps) {
  const history = useHistory();
  const { t } = useTranslation();
  const { AlertMessage } = useAlert();

  const [isLoading, setIsLoading] = useState(false);

  const validator = yup.object().shape({
    email: yup.string().required("emailRequired").email("emailInvalid")
  });

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validator)
  });

  const handleResetPassword = async ({ email }: FieldValues) => {
    setIsLoading(true);

    try {
      await axios.post(RECOVERY_PASSWORD_URL, {
        email
      });

      AlertMessage("success", "passwordSuccessReset");
      setIsLoading(false);
      history.push("/login");
    } catch (error) {
      setIsLoading(false);
      AlertMessage("error", "unknownError", error);
    }
  };

  const handleKeyboardEvent = (e: KeyboardEventHandler) => {
    if (e.key === "Enter") {
      handleSubmit(handleResetPassword)();
    }
  };

  return (
    <Grid container minHeight="100vh">
      <Grid item xs={12} md={6}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          maxWidth={400}
          px={2}
          mx="auto"
          height="100%"
        >
          <img src={LogoKahshAndName} alt="logo" width="200px" />

          <Typography py={5} variant="h5" color="secondary.main">
            {t("resetPassword")}
          </Typography>

          <Typography pb={2} variant="body2">
            {t("passwordRecoverSubtitle")}
          </Typography>

          <TextField
            required
            autoFocus
            type="email"
            name="email"
            label={t("email")}
            control={control}
            onKeyDown={(e) => handleKeyboardEvent(e)}
            helperText={errors?.email?.message}
          />
          <Button
            loading={isLoading}
            onClick={handleSubmit(handleResetPassword)}
            sx={{ mt: 4 }}
          >
            {t("resetPassword")}
          </Button>

          <Box
            display="flex"
            alignItems="center"
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent="flex-end"
            width="100%"
          >
            <Typography variant="body2">{t("wannaLogin")}</Typography>
            <Button
              variant="text"
              onClick={() => history.push("/login")}
              sx={{ height: "auto", width: "auto", fontWeight: "bold" }}
            >
              {t("enterSub")}
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item md={6} display={{ xs: "none", md: "flex" }}>
        <Grid
          item
          md={9}
          height="100%"
          ml="auto"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          <img
            src={RedefinicaoDeSenhaNew}
            alt="Recovery Password"
            width="80%"
            style={{
              margin: "auto",
              position: "relative",
              marginLeft: "-35%",
              maxHeight: "80vh",
              objectFit: "contain"
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

import { styled, Tab as TabBase } from "@mui/material";

export const Tab = styled(TabBase)`
  color: #000000;
  border: 1px solid #ddd;
  border-radius: 18px 18px 0 0;
  padding: 16px 16px;

  &.Mui-selected {
    border-bottom-color: #fff;
  }
`;

import React from "react";

import { AppBar, Container, Grid, IconButton } from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import LogoKahshPayMobileNew from "../../images/LogoKahshPayMobileNew.svg";
import { useAuth } from "../../contexts/authContext";
import { LanguageSelectorComponent } from "../languageSelector/LanguageSelectorComponent";

interface HeaderProps {
  setOpen: () => void;
}
export const Header: React.FC<HeaderProps> = ({ setOpen }) => {
  const { user } = useAuth();
  return (
    <AppBar
      sx={{
        position: { xs: "fixed", md: "relative" },
        backgroundColor: "#fff",
        py: { xs: user?.email ? 1 : 0, md: 0 },
        boxShadow: "4px 4px 4px rgb(0 0 0 / 5%)"
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <Grid
          sx={{ display: { xs: "flex", md: !user?.email ? "flex" : "none" } }}
        >
          <a href="/">
            <img src={LogoKahshPayMobileNew} alt="" />
          </a>
        </Grid>

        <Grid container justifyContent="flex-end">
          <LanguageSelectorComponent
            sx={{ display: { md: "flex", xs: "none" } }}
          />
          <IconButton
            size="medium"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{
              mr: 1,
              padding: 0,
              display: { xs: "block", md: "none" }
            }}
            onClick={() => setOpen()}
          >
            <MenuIcon sx={{ color: "secondary.main", fontSize: "2rem" }} />
          </IconButton>
        </Grid>
      </Container>
    </AppBar>
  );
};

import { format, isValid } from "date-fns";
import i18n from "i18next";

export default function dataFormat(value: any, withHours?: boolean) {
  if (!isValid(new Date(value))) {
    return "";
  }
  const i18nStorage =
    localStorage.getItem("i18nextLng") ?? i18n.language.substring(0, 2);

  if (i18nStorage === "en") {
    if (withHours) {
      return format(new Date(value), "MM/dd/yyyy - HH:mm");
    }
    return format(new Date(value), "MM/dd/yyyy");
  } else {
    if (withHours) {
      return format(new Date(value), "dd/MM/yyyy - HH:mm");
    }
    return format(new Date(value), "dd/MM/yyyy");
  }
}

import React, { useState } from "react";
import { Search, ChevronRight } from "@mui/icons-material";
import { Box, Divider, Drawer, Fab, Grid, Typography } from "@mui/material";
import { Button } from "../buttons/Button/Button";
import { Checkbox } from "../inputs/checkbox/checkbox.component";
import { DatePicker } from "../inputs/date-picker/datePicker";
import { TextField } from "../inputs/text-field/text-field.component";
import { FieldValues, useForm } from "react-hook-form";
import { startOfMonth } from "date-fns";
import { useTranslation } from "react-i18next";

interface SearchInvoiceDrawerProps {
  onSearch: (query: string) => void;
  onDownload: () => void;
  loading: boolean;
}
export const SearchInvoiceDrawer: React.FC<SearchInvoiceDrawerProps> = ({
  onSearch,
  onDownload,
  loading
}) => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();
  const { control, reset, handleSubmit } = useForm();

  const handleSearch = async (values: FieldValues) => {
    const status: string[] = [];
    const query: string[] = [];

    if (values.invoiceSearchLateral) {
      query.push(`text=${values.invoiceSearchLateral}`);
    }
    if (values.invoiceCode) {
      query.push(`invoiceCode=${values.invoiceCode}`);
    }
    if (values.invoiceId) {
      query.push(`invoiceId=${values.invoiceId}`);
    }
    if (values.documentno) {
      query.push(`documentno=${values.documentno}`);
    }
    if (values.initDate) {
      query.push(`initDate=${new Date(values.initDate).toISOString()}`);
    }
    if (values.finalDate) {
      query.push(`finalDate=${new Date(values.finalDate).toISOString()}`);
    }

    if (values.PA) {
      status.push(`PA`);
    }
    if (values.PE) {
      status.push(`PE`);
    }
    if (values.CA) {
      status.push(`CA`);
    }
    if (status.length) {
      query.push(`status=${status.join(",")}`);
    }
    onSearch(`?${query.join("&")}`);
  };

  const handleResetFilter = async () => {
    reset({
      invoiceSearchLateral: "",
      invoiceCode: "",
      invoiceId: "",
      documentNumber: "",
      initDate: null,
      finalDate: null
    });
  };

  const handleKeyboardEvent = async (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSubmit(handleSearch)();
    }
  };

  return (
    <>
      <Fab
        color="primary"
        onClick={() => setOpen(true)}
        sx={{
          position: "fixed",
          right: 0,
          borderRadius: "20px 0 0 20px",
          top: "70px"
        }}
      >
        <Search fontSize="large" />
      </Fab>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ position: "relative" }}
      >
        <Fab
          color="primary"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            left: 0,
            borderRadius: "0 20px 20px 0",
            top: "12px"
          }}
        >
          <ChevronRight fontSize="large" />
        </Fab>

        <Grid mt={8} role="presentation">
          <Grid
            container
            gap={2}
            py={2}
            px={2}
            height="100%"
            flexDirection="column"
            justifyContent="space-around"
          >
            <TextField
              label={t("invoiceSearch")}
              control={control}
              name="invoiceSearchLateral"
              icon={<Search color="primary" />}
              onKeyDown={(e) => handleKeyboardEvent(e)}
            />

            <TextField
              label={t("invoicesCode")}
              control={control}
              name="invoiceCode"
              onKeyDown={(e) => handleKeyboardEvent(e)}
            />

            <TextField
              label={t("invoiceId")}
              inputProps={{ type: "number" }}
              control={control}
              name="invoiceId"
              onKeyDown={(e) => handleKeyboardEvent(e)}
            />

            <TextField
              label={t("documentNumber")}
              name="documentno"
              control={control}
              onKeyDown={(e) => handleKeyboardEvent(e)}
            />
            <DatePicker
              id="initDate"
              label="initDate"
              control={control}
              name="initDate"
              defaultValue={startOfMonth(new Date())}
              maxDate={new Date()}
              onKeyDown={(e) => handleKeyboardEvent(e)}
              onClick={(e) => {
                let doc: any = document.getElementsByClassName(
                  "css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root"
                )[0];
                doc.setAttribute("tabindex", -1);
                doc.click();
              }}
            />
            <DatePicker
              id="finalDate"
              label="finalDate"
              control={control}
              name="finalDate"
              defaultValue={new Date()}
              maxDate={new Date()}
              onKeyDown={(e) => handleKeyboardEvent(e)}
              onClick={(e) => {
                let doc: any = document.getElementsByClassName(
                  "css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root"
                )[1];
                doc.setAttribute("tabindex", -1);
                doc.click();
              }}
            />
            <Box display="flex" flexDirection="column">
              <Typography variant="h6" component="p">
                Status
              </Typography>

              <Checkbox
                size="small"
                name="PA"
                control={control}
                label={t("paid")}
              />

              <Checkbox
                size="small"
                name="PE"
                control={control}
                label={t("pending")}
              />

              <Checkbox
                size="small"
                name="CA"
                control={control}
                label={t("canceled")}
              />
            </Box>
            <Button onClick={handleSubmit(handleSearch)} loading={loading}>
              {t("applyFilter")}
            </Button>

            <Button onClick={handleResetFilter}>{t("cleanFilter")}</Button>

            <Divider />

            <Button onClick={onDownload} disabled={loading} variant="outlined">
              {t("csvExport")}
            </Button>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};

import React from "react";
import { Tooltip, SxProps, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  CheckCircleOutline,
  HighlightOff,
  RemoveCircleOutline
} from "@mui/icons-material";

interface StatusLabelProps {
  value: string;
  sx?: SxProps<Theme>;
}

export const StatusLabel: React.FC<StatusLabelProps> = ({ value, sx }) => {
  const { t } = useTranslation();

  if (value === "PE") {
    return (
      <Tooltip title={t("pendingTitle")}>
        <>
          <RemoveCircleOutline
            sx={{ ...sx, display: { md: "unset", xs: "none" } }}
            color="primary"
          />
          <Typography
            variant="caption"
            color="primary.main"
            display={{ md: "none", xs: "unset" }}
          >
            {t("pendingTitle")}
          </Typography>
        </>
      </Tooltip>
    );
  }

  if (value === "PA") {
    return (
      <Tooltip title={t("statusPaid")}>
        <>
          <CheckCircleOutline
            sx={{ ...sx, display: { md: "unset", xs: "none" } }}
            color="success"
          />
          <Typography
            variant="caption"
            color="success.main"
            display={{ md: "none", xs: "unset" }}
          >
            {t("statusPaid")}
          </Typography>
        </>
      </Tooltip>
    );
  }
  return (
    <Tooltip title={t("statusCanceled")}>
      <>
        <HighlightOff
          sx={{ ...sx, display: { md: "unset", xs: "none" } }}
          color="error"
        />
        <Typography
          variant="caption"
          color="error.main"
          display={{ md: "none", xs: "unset" }}
        >
          {t("statusCanceled")}
        </Typography>
      </>
    </Tooltip>
  );
};

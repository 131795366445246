import { Alert, Slide, Stack, AlertColor, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type AlertComponentProps = {
  title?: string;
  message: string;
  severity: AlertColor;
  onClick?: any;
  open: boolean;
  timeout?: number;
};

export function AlertComponent(props: AlertComponentProps) {
  return (
    <>
      <Slide in={props.open} direction="left" mountOnEnter>
        <Stack
          id="alertComponent"
          sx={{
            maxWidth: "20em",
            width: "calc(100% - 2em)",
            position: "fixed",
            top: "4em",
            right: "1em",
            zIndex: 99999,
          }}
          spacing={2}
        >
          <Alert
            severity={props.severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={props.onClick}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {props.message}
          </Alert>
        </Stack>
      </Slide>
    </>
  );
}

export default AlertComponent;

import { Block, InfoOutlined, Description } from "@mui/icons-material";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { FieldValues } from "react-hook-form";
import { BackAndSubmitButton } from "../../components/buttons/BackAndSubmit/BackAndSubmit";
import { DocumentCard } from "../../components/documentCard/DocumentCard";
import { FileInput } from "../../components/inputs/fileInput/FileInput";
import { TooltipCompliance } from "../../styles/TooltipCompliance";

import { useState } from "react";

import SocialContractImage from "../../images/compliance/socialContract.png";
import { useTranslation } from "react-i18next";

interface ComplianceStep {
  onSubmit: (stepData: FieldValues) => void;
  onBack: () => void;
  compliance: FieldValues | null;
}

export const SocialContract: React.FC<ComplianceStep> = ({
  onSubmit,
  onBack,
  compliance
}) => {
  const { t } = useTranslation();
  const [socialContract, setSocialContract] = useState<File | null>(
    compliance?.socialContract || null
  );
  const [hasError, setHasError] = useState(false);

  const handleSubmit = () => {
    if (!socialContract) {
      return setHasError(true);
    }
    onSubmit({ socialContract });
  };

  return (
    <Grid container spacing={4} height="calc(100vh - 160px)">
      <Grid
        item
        container
        xs={12}
        md={6}
        direction="column"
        justifyContent="space-between"
        gap={1}
      >
        <Box>
          <Box display="flex" alignItems="center" gap={2}>
            <Avatar sx={{ background: (theme) => theme.palette.primary.main }}>
              4
            </Avatar>
            <Typography fontWeight="bold">
              {t("socialContract")}
              <Typography variant="body2" component="span" display={"block"}>
                {t("yourNameDoc")}
              </Typography>
            </Typography>
          </Box>
        </Box>
        <DocumentCard
          picture={SocialContractImage}
          icon={<Block />}
          title={t("addSocialContract")}
        />

        <BackAndSubmitButton
          titleSubmit="finish"
          onSubmit={handleSubmit}
          onBack={onBack}
        />
      </Grid>
      <Grid item container xs={12} md={6} direction="column">
        <Box display="flex" flexDirection="column" gap={2} height="100%">
          <Box display="flex" gap={1} alignItems="center">
            <TooltipCompliance
              placement="top"
              title={t("validDocument")}
              style={{ backgroundColor: "#fff" }}
            >
              <InfoOutlined />
            </TooltipCompliance>
            <Typography variant="body2">{t("selectDocument")}</Typography>
          </Box>
          <FileInput
            onChange={(file) => {
              setHasError(false);
              setSocialContract(file);
            }}
            defaultValue={socialContract}
            icon={<Description />}
            accept="image/*, .pdf"
            error={hasError}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

import {
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
  Typography,
  Skeleton
} from "@mui/material";
import { TextField } from "../../components/inputs/text-field/text-field.component";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, FieldValues } from "react-hook-form";
import { DatePicker } from "../../components/inputs/date-picker/datePicker";
import { Select } from "../../components/inputs/select/select.component";
import { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAlert } from "../../hooks/alert";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { PhoneInput } from "../../components/inputs/phoneInput/PhoneInput";
import { parsePhoneNumber } from "react-phone-number-input";
import { urlPermission, urlRole, urlUser } from "../../services/global";
import { Button } from "../../components/buttons/Button/Button";
import { RolesDrawer } from "../../components/rolesDrawer/RolesDrawer";
import { User } from "../../data/models/User";
import { Checkbox as CheckboxComponent } from "../../components/inputs/checkbox/checkbox.component";

interface Permission {
  name: string;
  description: string;
  value: string;
  checked?: boolean;
}

const validatorEdit = yup.object().shape({
  name: yup
    .string()
    .required("nameRequired")
    .test(`test-two-string`, function (value) {
      const { path, createError } = this;
      return (
        (value && value?.toString().split(" ").length > 1) ||
        createError({ path, message: "fullNameInvalid" })
      );
    })
});

const validatorCreate = yup.object().shape({
  name: yup
    .string()
    .required("nameRequired")
    .test(`test-two-string`, function (value) {
      const { path, createError } = this;
      return (
        (value && value?.toString().split(" ").length > 1) ||
        createError({ path, message: "fullNameInvalid" })
      );
    }),
  email: yup.string().required("emailRequired").email("emailInvalid"),
  password: yup
    .string()
    .required("passwordRequired")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "isNotStrongPassword"
    ),
  passwordConfirmation: yup
    .string()
    .required("confirmPasswordRequired")
    .oneOf([yup.ref("password"), null], "passwordEqualsError")
});

export const CreateUser = () => {
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [roles, setRoles] = useState<any[]>([]);
  const [permissionsView, setPermissionsView] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { AlertMessage } = useAlert();
  const params: any = useParams();

  const validator = params?.userId ? validatorEdit : validatorCreate;

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validator)
  });

  const handleChange = (index: number) => {
    setPermissions((prev) =>
      prev.map((permission, i) =>
        index !== i
          ? permission
          : { ...permission, checked: !permission.checked }
      )
    );
  };

  const handleChangeAll = () => {
    const currentValue = permissions.every((permission) => permission.checked);
    setPermissions((prev) =>
      prev.map((permission) => ({ ...permission, checked: !currentValue }))
    );
  };

  const createUser = async (data: FieldValues) => {
    try {
      setLoading(true);
      if (
        !data?.roleId &&
        !permissions.some((permission) => permission.checked) &&
        !isOwner
      ) {
        setLoading(false);
        return AlertMessage("error", "noPermissionsFound");
      }
      if (data?.roleId) {
        const role = roles.find((role) => role.value === data.roleId);
        if (role) {
          data.permissions = role.permissions.map(
            (permission) => permission.value
          );
        }
      } else {
        delete data?.roleId;
        data.permissions = permissions
          .filter((permission) => permission.checked)
          .map((permission) => permission.value);
      }
      console.log(data);
      if (params?.userId) {
        data.id = params?.userId;
        delete data.email;
        delete data.password;
      }

      const parsedPhone = parsePhoneNumber(data.phone);
      if (parsedPhone) {
        data.phoneDdi = parsedPhone.countryCallingCode;
        data.phone = parsedPhone.nationalNumber;
      }

      await axios.post(urlUser, data);
      setLoading(false);
      history.push("/user");
    } catch (err) {
      setLoading(false);
      return AlertMessage("error", "defaultMessage", err);
    }
  };

  const getRoles = useCallback(async () => {
    try {
      const res = await axios.get(urlRole);
      if (!params?.userId) {
        setLoadingData(false);
      }
      setRoles(
        res.data?.map((role) => ({
          label: role.name,
          value: role.id,
          permissions: role.permissions
        }))
      );
    } catch (err) {
      setLoadingData(false);
      AlertMessage("error", "defaultMessage", err);
    }
  }, [AlertMessage, params?.userId]);

  const getPermissions = useCallback(
    async (user?: User) => {
      try {
        const res = await axios.get<Permission[]>(urlPermission, {
          params: { lang: i18n.language.substring(0, 2) }
        });

        setPermissions(
          res.data.map(({ name, description, value }) => ({
            name,
            description,
            value,
            checked: user
              ? user.permissions.some(
                  (permission) => permission?.value === value
                )
              : false
          }))
        );
      } catch (err) {
        AlertMessage("error", "defaultMessage", err);
      }
    },
    [AlertMessage, i18n.language]
  );

  const scrollToPermissions = () => {
    setTimeout(() => {
      window.scrollTo({
        top: window.innerHeight,
        behavior: "smooth"
      });
    }, 100);
  };

  const onChangeRole = async () => {
    await getRoles();
  };

  const getUser = useCallback(async () => {
    try {
      const res = await axios.get(`${urlUser}/${params?.userId}`);
      if (res.data?.isowner) {
        setIsOwner(true);
      } else {
        if (!res.data?.rolesId) {
          setPermissionsView(true);
        }
        await getRoles();
        await getPermissions(res.data);
      }
      reset({
        name: res.data?.name || "",
        roleId: res.data?.rolesId || "",
        email: res.data?.email || "",
        phone: res.data?.phoneDdi
          ? `+${res.data?.phoneDdi}${res.data?.phone}`
          : res.data?.phone || "",
        birthday: res.data?.birthdate ? new Date(res.data?.birthdate) : null,
        isactive: res.data?.isactive
      });
      setLoadingData(false);
    } catch (err) {
      setLoadingData(false);
      AlertMessage("error", "defaultMessage", err);
    }
  }, [AlertMessage, getPermissions, getRoles, params?.userId, reset]);

  useEffect(() => {
    if (params?.userId) {
      getUser();
    } else {
      getRoles();
      getPermissions();
    }
    setValue("roleId", "");
  }, [getPermissions, getRoles, setValue, getUser, params?.userId]);

  if (loadingData) {
    return (
      <Box
        borderRadius="15px"
        border="1px solid #ddd"
        minHeight="calc(100vh - 62px - 16px - 16px)"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          justifyContent="space-between"
          p={4}
        >
          <Box display="flex" width={"100%"} gap={2}>
            <Skeleton
              variant="rectangular"
              sx={{ mr: "auto" }}
              width={"30%"}
              height={60}
            />
          </Box>
          <Box display="flex" gap={2}>
            <Box width={"100%"}>
              <Skeleton variant="rectangular" width={"100%"} height={60} />
            </Box>
            <Box width={"40%"}>
              <Skeleton variant="rectangular" width={"100%"} height={60} />
            </Box>
          </Box>
          <Box display="flex" gap={2}>
            <Box width={"100%"}>
              <Skeleton variant="rectangular" width={"100%"} height={60} />
            </Box>
            <Box width={"100%"}>
              <Skeleton variant="rectangular" width={"100%"} height={60} />
            </Box>
          </Box>
          <Box display="flex" gap={2}>
            <Box width={"100%"}>
              <Skeleton variant="rectangular" width={"100%"} height={60} />
            </Box>
            <Box width={"100%"}>
              <Skeleton variant="rectangular" width={"100%"} height={60} />
            </Box>
          </Box>
          <Box display="flex" width={"100%"} gap={2}>
            <Skeleton
              variant="rectangular"
              sx={{ ml: "auto" }}
              width={"30%"}
              height={60}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        justifyContent="space-between"
        minHeight="calc(100vh - 62px - 16px - 16px)"
        width="100%"
      >
        <Box
          p={4}
          sx={{ border: "1px solid #ddd" }}
          borderRadius="15px"
          display="flex"
          flexDirection="column"
          gap={4}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexDirection={{ xs: "column", sm: "row" }}
            gap={4}
          >
            <Typography variant="body1" color="#000000">
              {params?.userId ? t("editUser") : t("registerUser")}
            </Typography>

            {!isOwner && (
              <Button
                sx={{ maxWidth: 320 }}
                onClick={() =>
                  setPermissionsView((prev) => {
                    if (!prev) {
                      scrollToPermissions();
                    }
                    return !prev;
                  })
                }
                disabled={!!watch("roleId")}
              >
                {t("permissions").toString()}
              </Button>
            )}
          </Box>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={9} md={10}>
              <TextField
                required
                name="name"
                label="name"
                control={control}
                helperText={errors?.name?.message}
              />
            </Grid>

            <Grid item xs={12} sm={3} md={2}>
              <CheckboxComponent
                size="small"
                name="isactive"
                control={control}
                label={t("activate")}
                defaultChecked={true}
                disabled={!params?.userId || isOwner}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              display={params?.userId ? "flex" : "none"}
            >
              <TextField
                required
                disabled
                name="email"
                label="email"
                type="email"
                control={control}
                helperText={errors?.email?.message}
              />
            </Grid>

            <Grid item xs={12} md={params?.userId ? 6 : 4}>
              <PhoneInput
                fullWidth
                name="phone"
                label="phone"
                control={control}
                helperText={errors?.phone?.message}
              />
            </Grid>

            <Grid item xs={12} md={params?.userId ? 6 : 4}>
              <DatePicker
                name="birthday"
                label="birthDate"
                control={control}
                maxDate={new Date()}
                helperText={errors?.birthday?.message}
              />
            </Grid>

            <Grid item xs={12} md={params?.userId ? 6 : 4}>
              <Select
                name="roleId"
                control={control}
                disabled={isOwner}
                label={isOwner ? "owner" : "role"}
                helperText={errors?.roleId?.message}
                options={[{ value: "", label: "Nenhum" }, ...roles]}
              />
            </Grid>
          </Grid>

          {params?.userId && (
            <Button
              sx={{ maxWidth: 320, ml: "auto", mr: { xs: "auto", sm: 0 } }}
              onClick={handleSubmit(createUser)}
              loading={loading}
            >
              {t("save")}
            </Button>
          )}
        </Box>

        {!params?.userId && (
          <Box
            sx={{ border: "1px solid #ddd" }}
            p={4}
            borderRadius={"15px"}
            display="flex"
            flexDirection="column"
            gap={4}
          >
            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              alignItems={{ xs: "center", sm: "flex-start" }}
              gap={4}
            >
              <TextField
                required
                name="email"
                type="email"
                label="email"
                control={control}
                helperText={errors?.email?.message}
              />

              <TextField
                required
                type="password"
                name="password"
                label="password"
                control={control}
                helperText={errors?.password?.message}
              />

              <TextField
                required
                control={control}
                name="passwordConfirmation"
                label="confirmPassword"
                type="password"
                helperText={errors?.passwordConfirmation?.message}
              />
            </Box>

            <Button
              sx={{ maxWidth: 320 }}
              onClick={handleSubmit(createUser)}
              loading={loading}
            >
              {t("registerUser")}
            </Button>
          </Box>
        )}

        {permissionsView && !watch("roleId") && (
          <Box
            sx={{ border: "1px solid #ddd" }}
            p={4}
            borderRadius={"15px"}
            display="flex"
            flexDirection="column"
            gap={4}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexDirection={{ xs: "column", sm: "row" }}
              gap={2}
            >
              <Typography variant="body1" color="#000000">
                {t("permissions")}
              </Typography>

              <Box
                sx={{ border: "1px solid #ddd" }}
                borderRadius={"15px"}
                px={2}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={permissions.every(
                        (permission) => permission.checked
                      )}
                      onChange={handleChangeAll}
                    />
                  }
                  label={t("selectAll")}
                />
              </Box>
            </Box>

            <Grid container spacing={2}>
              {permissions.map((permission, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={permission.value}>
                  <FormControlLabel
                    key={permission.value}
                    label={
                      <Typography variant="body2">{permission.name}</Typography>
                    }
                    control={
                      <Checkbox
                        size="small"
                        checked={permission.checked}
                        onChange={() => {
                          handleChange(index);
                        }}
                      />
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
        <Button
          onClick={() => history.push("/user")}
          sx={{ maxWidth: 320, ml: "auto", mr: { xs: "auto", sm: 4 } }}
        >
          {t("back")}
        </Button>
      </Box>
      <RolesDrawer onChange={onChangeRole} />
    </>
  );
};

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Box, Grid, Typography } from "@mui/material";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useTranslation } from "react-i18next";

import LoginImg from "../../images/login/Login.png";
import LogoKahshAndName from "../../images/logoKsh.svg";

import { FieldValues, useForm } from "react-hook-form";

import { Button } from "../../components/buttons/Button/Button";
import { useAuth } from "../../contexts/authContext";
import { TextField } from "../../components/inputs/text-field/text-field.component";

import { useAlert } from "../../hooks/alert";
import { getCookie } from "cookies-next";

type LoginProps = {
  children?: string;
};

export function Login(props: LoginProps) {
  const history = useHistory();
  const { signIn, signOut } = useAuth();
  const { AlertMessage } = useAlert();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const validator = yup.object().shape({
    username: yup.string().required("emailRequired").email("emailInvalid"),
    password: yup.string().required("passwordRequired")
  });

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validator)
  });

  const handleLogin = async ({ username, password }: FieldValues) => {
    setIsLoading(true);

    try {
      await signIn({ username, password });
      history.push("/dashboard");
    } catch (error) {
      AlertMessage("error", "defaultMessage", error);
      setIsLoading(false);
      return;
    }
  };

  const handleKeyboardEvent = (e: KeyboardEventHandler) => {
    if (e.key === "Enter") {
      handleSubmit(handleLogin)();
    }
  };

  useEffect(() => {
    if (getCookie("@kahsh-pay:token")) {
      signOut();
    }
  }, [signOut]);

  return (
    <Grid container minHeight="100vh">
      <Grid item xs={12} md={6}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          maxWidth={400}
          px={2}
          mx="auto"
          height="100%"
        >
          <img src={LogoKahshAndName} alt="logo" width="200px" />

          <Typography py={5} variant="h5" color="secondary.main">
            {t("merchantLogin")}
          </Typography>

          <TextField
            sx={{ paddingBottom: 3 }}
            name="username"
            type="email"
            label="email"
            control={control}
            onKeyDown={(e) => handleKeyboardEvent(e)}
            helperText={errors.username && errors.username.message}
          />

          <TextField
            name="password"
            type="password"
            label="password"
            control={control}
            onKeyDown={(e) => handleKeyboardEvent(e)}
            helperText={errors.password && errors.password.message}
          />

          <Button
            tabIndex={-1}
            variant="text"
            onClick={() => history.push("/forgot-password")}
            sx={{ display: "flex", width: "auto", height: "auto", ml: "auto" }}
          >
            <Typography variant="caption">{t("forgotPassword")}</Typography>
          </Button>

          <Button
            loading={isLoading}
            onClick={handleSubmit(handleLogin)}
            sx={{ mt: 4 }}
          >
            {t("enter")}
          </Button>

          <Box
            display="flex"
            alignItems="center"
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent="flex-end"
            width="100%"
          >
            <Typography variant="body2">
              {t("dontHaveAccountSiginUp")}
            </Typography>
            <Button
              variant="text"
              onClick={() => history.push("/register")}
              sx={{ height: "auto", width: "auto", fontWeight: "bold" }}
            >
              {t("signUp")}
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item md={6} display={{ xs: "none", md: "flex" }}>
        <Grid
          item
          md={9}
          height="100%"
          ml="auto"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          <img
            src={LoginImg}
            alt="Recovery Password"
            width="80%"
            style={{
              margin: "auto",
              position: "relative",
              marginLeft: "-35%",
              maxHeight: "80vh",
              objectFit: "contain"
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

import * as React from "react";
import { styled } from "@mui/material/styles";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { Tooltip } from "@mui/material";

export const TooltipCompliance = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background.paper
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.3)",
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
    fontSize: theme.typography.body2
  }
}));

import React from "react";
import InputMask from "react-input-mask";
import { useController } from "react-hook-form";
import { TextFieldStyledProps } from "../text-field/text-field.component";
import { TextFieldStyled } from "../text-field/text-field.styles";
import { useTranslation } from "react-i18next";

export interface TextFieldMaskProps extends TextFieldStyledProps {
  mask: string;
  required?: boolean;
  size?: "medium" | "small";
}

export const TextFieldMask: React.FC<TextFieldMaskProps> = ({
  control,
  helperText,
  name,
  value,
  mask,
  defaultValue,
  watch,
  label,
  onChange,
  disabled,
  ...rest
}) => {
  const { t } = useTranslation();

  const { field } = useController({
    name,
    control,
    defaultValue: defaultValue || ""
  });
  return (
    <InputMask
      mask={mask}
      value={!!control ? field.value : value}
      disabled={disabled}
      onChange={(event) => {
        if (control) {
          if (!!watch) {
            watch(event.target.value);
          }
          return field.onChange(event);
        }
        if (onChange) {
          return onChange(event);
        }
      }}
    >
      <TextFieldStyled
        size={rest.size || "small"}
        error={!!helperText}
        label={!!label && t(label.toString())}
        helperText={!!helperText && t(helperText.toString())}
        variant={"outlined"}
        name={field.name}
        value={!!control ? field.value : value}
        fullWidth
        {...rest}
      />
    </InputMask>
  );
};

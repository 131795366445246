import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { currencyFormatter } from "../../../hooks/currencyFormatter";
import {
  Cell,
  ListComponent
} from "../../../components/listComponent/ListComponent";
import { Visibility } from "@mui/icons-material";
import { InvoiceDetailDrawer } from "../../../components/InvoiceDetailDrawer/InvoiceDetailDrawer";
import { IExtract } from "../../../data/models/Extract";
import { BaseCard } from "../../../components/baseCard/BaseCard";

const cells: Cell[] = [
  {
    id: "amount",
    label: "amountLowerCase",
    size: 0.25,
    button: true,
    format: (value) => (
      <Typography variant="body2">
        {value?.currency?.iso_code} {currencyFormatter(value.amount)}
      </Typography>
    )
  },
  {
    id: "fee_amount",
    label: "feesAmount",
    size: 0.25,
    button: true,
    format: (value) => (
      <Typography ml={{ xs: 0, md: 1.6 }} variant="body2">
        {value?.currency?.iso_code} {currencyFormatter(value.fee_amount)}
      </Typography>
    )
  },
  {
    id: "reward_amount",
    label: "rewardAmount",
    size: 0.25,
    button: true,
    format: (value) => (
      <Typography ml={{ xs: 0, md: 2.6 }} variant="body2">
        {value?.currency?.iso_code} {currencyFormatter(value.reward_amount)}
      </Typography>
    )
  },
  {
    id: "netamount",
    label: "netAmount",
    size: 0.25,
    button: true,
    format: (value) => (
      <Typography ml={{ xs: 0, md: 3.6 }} variant="body2">
        {value?.currency?.iso_code} {currencyFormatter(value.netamount)}
      </Typography>
    )
  }
];

interface ExtractInvoicesProps {
  extract: IExtract | null;
}
export const ExtractInvoices: React.FC<ExtractInvoicesProps> = ({
  extract
}) => {
  const { t } = useTranslation();
  const [openDetail, setOpenDetail] = useState("");

  return (
    <>
      <Grid container>
        <Box width="100%">
          <Box maxWidth={300}>
            <BaseCard
              title={
                <Typography fontWeight="bold">
                  {t("balance")}
                  <Typography variant="caption" ml={1}>
                    {!!extract?.payments?.length
                      ? `${extract?.payments[0]?.currency?.name} (${extract?.payments[0]?.currency?.iso_code})`
                      : `(${extract?.currencyIsoCode})`}
                  </Typography>
                </Typography>
              }
              value={
                <Typography variant="h4" component="p" textAlign="center">
                  {currencyFormatter(
                    extract?.payments?.reduce(
                      (acc, cur) => acc + Number(cur?.amount),
                      0
                    )
                  )}
                  <Typography variant="caption" ml={1}>
                    {!!extract?.payments?.length
                      ? `${extract?.payments[0]?.currency?.iso_code}`
                      : `${extract?.currencyIsoCode}`}
                  </Typography>
                </Typography>
              }
            />
          </Box>
          <Typography variant="body1" my={4}>
            {t("historic")}
          </Typography>
          <ListComponent
            cells={cells}
            rows={extract?.payments || []}
            dynamicId="invoiceId"
            initialOrder={{
              orderBy: "invoiceId",
              direction: "asc"
            }}
            noDataText="noInvoiceData"
            button={{
              label: "Detalhes",
              action: (invoice) => setOpenDetail(invoice.id),
              icon: <Visibility sx={{ color: "#B4B4C1" }} />
            }}
            onDoubleClick={(invoice) => setOpenDetail(invoice.id)}
          />
        </Box>
      </Grid>
      <InvoiceDetailDrawer
        open={openDetail}
        setOpen={() => setOpenDetail("")}
      />
    </>
  );
};

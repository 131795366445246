export type Totals = {
  initialDate: Date;
  finalDate: Date;
  amount: string;
  feeAmount: string;
  rewardAmount: string;
  netamount: string;
};

export interface IExtract {
  currencyIsoCode: string;
  qty: string;
  amount: string;
  currencyId: string;
  datePlanned: Date;
  id: string;
  status: string;
  totals: Totals;
  payments: [
    {
      created: string;
      createdby: string;
      isactive: boolean;
      id: string;
      invoiceId: string;
      dateplanned: string;
      currencyId: string;
      amount: string;
      fee: string;
      pc_fee: string;
      fee_amount: string;
      reward: string;
      pc_reward: string;
      reward_amount: string;
      netamount: string;
      quotation: string;
      currency: {
        created: string;
        createdby: string;
        isactive: boolean;
        id: string;
        name: string;
        iso_code: string;
        sign: string;
        precision: number;
        iscrypto: boolean;
        classname: string;
      };
    }
  ];
}

export const StatusLabelEnum = {
  DE: { color: "#FF0000", label: "statusDefaulter" },
  PA: { color: "#0B6B20", label: "statusPaid" },
  PE: { color: "#FF8C39", label: "statusPending" }
};

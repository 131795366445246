import { ArrowBack } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useHistory } from "react-router-dom";

interface BackButtonProps {
  backRoute: string;
}

export const BackButton: React.FC<BackButtonProps> = ({ backRoute }) => {
  const history = useHistory();
  return (
    <IconButton
      onClick={() => history.push(backRoute)}
      sx={{
        bgcolor: "primary.main",
        color: "#fff",
        "&:hover": { opacity: 0.9, bgcolor: "primary.main" }
      }}
    >
      <ArrowBack />
    </IconButton>
  );
};

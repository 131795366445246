import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Typography, Box, Grid, Skeleton } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BackButton } from "../../../components/buttons/BackButton/BackButton";
import { IExtract } from "../../../data/models/Extract";
import { useAlert } from "../../../hooks/alert";
import { EXTRACT_URL } from "../../../services/global";
import { Tab } from "../../../styles/Tab";
import { ExtractGeneral } from "./general";
import { ExtractInvoices } from "./invoices";

export const ExtractDetail = () => {
  const { t } = useTranslation();
  const params: any = useParams();
  const { AlertMessage } = useAlert();

  const [extract, setExtract] = useState<IExtract | null>(null);
  const [loading, setLoading] = useState(true);

  const tabs = [
    { title: "general", component: <ExtractGeneral extract={extract} /> },
    { title: "invoices", component: <ExtractInvoices extract={extract} /> }
  ];
  const [tabSelected, setTabSelected] = useState(tabs[0].title);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get<IExtract>(
          params?.datePlanned
            ? `${EXTRACT_URL}/planned?dateplanned=${params.datePlanned}&currencyId=${params.extractId}`
            : `${EXTRACT_URL}/${params.extractId}`
        );
        const status =
          data.status === "PE" && new Date(data.datePlanned) < new Date()
            ? "DE"
            : data.status;
        setExtract({ ...data, status });
        setLoading(false);
      } catch (err) {
        AlertMessage("error", "defaultMessage", err);
        setLoading(false);
      }
    };
    fetchData();
  }, [AlertMessage, params]);

  if (loading) {
    return (
      <Box
        borderRadius="15px"
        border="1px solid #ddd"
        minHeight="calc(100vh - 62px - 16px - 16px)"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          justifyContent="space-between"
          p={4}
        >
          <Box display="flex" width={"100%"} gap={2}>
            <Skeleton
              variant="rectangular"
              sx={{ mr: "auto" }}
              width={"30%"}
              height={60}
            />
          </Box>
          <Box display="flex" gap={2}>
            <Box width={"100%"}>
              <Skeleton variant="rectangular" width={"100%"} height={60} />
            </Box>
            <Box width={"40%"}>
              <Skeleton variant="rectangular" width={"100%"} height={60} />
            </Box>
          </Box>
          <Box display="flex" gap={2}>
            <Box width={"100%"}>
              <Skeleton variant="rectangular" width={"100%"} height={60} />
            </Box>
            <Box width={"100%"}>
              <Skeleton variant="rectangular" width={"100%"} height={60} />
            </Box>
          </Box>
          <Box display="flex" gap={2}>
            <Box width={"100%"}>
              <Skeleton variant="rectangular" width={"100%"} height={60} />
            </Box>
            <Box width={"100%"}>
              <Skeleton variant="rectangular" width={"100%"} height={60} />
            </Box>
          </Box>
          <Box display="flex" width={"100%"} gap={2}>
            <Skeleton
              variant="rectangular"
              sx={{ ml: "auto" }}
              width={"30%"}
              height={60}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Grid
      container
      direction="column"
      minHeight="calc(100vh - 62px - 16px - 16px)"
      flexWrap="unset"
      gap={4}
    >
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Typography display="flex" alignItems="center" gap={1}>
          <BackButton backRoute="/extract" /> {t("extract")}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" flex="1">
        <TabContext value={tabSelected}>
          <TabList
            onChange={(_e, newValue) => setTabSelected(newValue)}
            TabIndicatorProps={{ sx: { display: "none" } }}
          >
            {tabs.map(({ title }) => (
              <Tab label={t(title)} value={title} />
            ))}
          </TabList>
          <Grid
            container
            borderRadius="0 15px 15px 15px"
            border="1px solid #DDDDDD"
            direction="column"
            flexWrap="unset"
            mt="-1px"
            flex="1"
          >
            {tabs.map((tab) => (
              <TabPanel key={tab.title} value={tab.title}>
                {tab.component}
              </TabPanel>
            ))}
          </Grid>
        </TabContext>
      </Box>
    </Grid>
  );
};

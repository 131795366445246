import i18n from "i18next";
export const currencyFormatter = (value: any) => {
  if (value) {
    value = Number(value).toFixed(2).toString();
    value = value.replace(/\D+/g, "");
    if (!value) value = "0";
    if (/(0\d0\d)/.test(value) && value.length === 4) {
      value = value.replaceAll("0", "");
    }
    value =
      value.substring(0, value.length - 2) +
      "." +
      value.substring(value.length - 2);
    const res = new Intl.NumberFormat(
      i18n.language.includes("pt") ? "pt-BR" : "en-US",
      {
        minimumFractionDigits: 2
      }
    ).format(parseFloat(value));
    return res;
  }
  return "";
};

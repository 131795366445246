import { useCallback, useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import axios from "axios";

import { Grid, Typography, Box } from "@mui/material";

import { useTranslation } from "react-i18next";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";

import LogoKahshAndName from "../../images/logoKsh.svg";
import RedefinicaoDeSenhaNew from "../../images/passwordReset/RedefinicaoDeSenhaNew.png";

import { Button } from "../buttons/Button/Button";
import { TextField } from "../inputs/text-field/text-field.component";

import { useAlert } from "../../hooks/alert";

import {
  RECOVERY_PASSWORD_REDEFINE_URL,
  RECOVERY_PASSWORD_VERIFY_URL
} from "../../services/global";

type ChangePasswordProps = {
  children?: string;
};

export function ChangePassword(props: ChangePasswordProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const params: any = useParams();
  const { AlertMessage } = useAlert();

  const [isLoading, setIsLoading] = useState(false);

  const validator = yup.object().shape({
    password: yup
      .string()
      .required("passwordRequired")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        "isNotStrongPassword"
      ),
    passwordConfirmation: yup
      .string()
      .required("confirmPasswordRequired")
      .oneOf([yup.ref("password"), null], "passwordEqualsError")
  });

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validator)
  });

  const getToken = useCallback(async () => {
    try {
      await axios.get(RECOVERY_PASSWORD_VERIFY_URL, {
        params: { token: params.token }
      });
    } catch (e) {
      history.push("/login");
      AlertMessage("error", "invalidToken", e);
    }
  }, [AlertMessage, history, params.token]);

  useEffect(() => {
    if (params?.token) {
      getToken();
    }
  }, [getToken, params?.token]);

  const handleNewPassword = async (data: FieldValues) => {
    setIsLoading(true);

    try {
      await axios.post(RECOVERY_PASSWORD_REDEFINE_URL, {
        token: params.token,
        newPassword: data.passwordConfirmation,
        returnUrl: "https://kahsh.com"
      });

      AlertMessage("success", "passwordResetSuccessfully");
      setIsLoading(false);
      history.push("/login");
    } catch (error) {
      setIsLoading(false);
      AlertMessage("error", "unknownError", error);
    }
  };

  const handleKeyboardEvent = (e: KeyboardEventHandler) => {
    if (e.key === "Enter") {
      handleSubmit(handleNewPassword)();
    }
  };

  return (
    <Grid container minHeight="100vh">
      <Grid item xs={12} md={6}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          maxWidth={400}
          px={2}
          mx="auto"
          height="100%"
        >
          <img src={LogoKahshAndName} alt="logo" width="200px" />

          <Typography py={5} variant="h5" color="secondary.main">
            {t("passwordReset")}
          </Typography>

          <Typography pb={2} variant="body2">
            {t("passwordRecoverSubtitle")}
          </Typography>

          <TextField
            sx={{ paddingBottom: 3 }}
            required
            name="password"
            type="password"
            control={control}
            label={t("password")}
            onKeyDown={(e) => handleKeyboardEvent(e)}
            helperText={errors?.password?.message}
          />

          <TextField
            required
            control={control}
            type="password"
            name="passwordConfirmation"
            label={t("confirmPassword")}
            onKeyDown={(e) => handleKeyboardEvent(e)}
            helperText={errors?.passwordConfirmation?.message}
          />

          <Button
            loading={isLoading}
            onClick={handleSubmit(handleNewPassword)}
            sx={{ mt: 4 }}
          >
            {t("resetPassword")}
          </Button>
        </Box>
      </Grid>
      <Grid item md={6} display={{ xs: "none", md: "flex" }}>
        <Grid
          item
          md={9}
          height="100%"
          ml="auto"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          <img
            src={RedefinicaoDeSenhaNew}
            alt="Recovery Password"
            width="80%"
            style={{
              margin: "auto",
              position: "relative",
              marginLeft: "-35%",
              maxHeight: "80vh",
              objectFit: "contain"
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

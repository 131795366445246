import {
  OutlinedTextFieldProps,
  InputAdornment,
  IconButton,
  Typography
} from "@mui/material";
import { VisibilityOff } from "@mui/icons-material";
import { Visibility } from "@mui/icons-material";
import React, { useState } from "react";
import { Control, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TextFieldStyled } from "./text-field.styles";

type OutlinedTextFieldPropsEdit = Omit<OutlinedTextFieldProps, "variant">;
export interface TextFieldStyledProps extends OutlinedTextFieldPropsEdit {
  control?: Control;
  name: string;
  icon?: JSX.Element;
  watch?: (value: any) => void;
}
export const TextField: React.FC<TextFieldStyledProps> = ({
  control,
  helperText = "",
  defaultValue,
  label,
  name,
  icon,
  watch,
  onChange,
  value,
  type,
  ...rest
}) => {
  const [viewPassword, setViewPassword] = useState(false);

  const { t } = useTranslation();
  const { field } = useController({
    name: name || "",
    control: control || undefined,
    defaultValue: defaultValue || ""
  });

  return (
    <TextFieldStyled
      fullWidth
      size="small"
      variant={"outlined"}
      name={field.name}
      error={!!helperText}
      value={control ? field.value : value}
      label={!!label && t(label.toString())}
      type={type === "password" && !viewPassword ? "password" : "text"}
      helperText={
        !!helperText && type === "password" ? (
          <PasswordError error={helperText.toString()} />
        ) : !!helperText ? (
          t(helperText.toString())
        ) : (
          ""
        )
      }
      onChange={(event) => {
        if (control) {
          watch && watch(event.target.value);
          return field.onChange(event);
        }
        if (onChange) {
          return onChange(event);
        }
      }}
      InputProps={
        type === "password"
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    onClick={() => setViewPassword((prev) => !prev)}
                    edge="end"
                    sx={{ color: "#B4B4C1" }}
                  >
                    {viewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }
          : !!icon
          ? {
              endAdornment: (
                <InputAdornment position="end">{icon}</InputAdornment>
              )
            }
          : {}
      }
      {...rest}
    />
  );
};

interface PasswordErrorProps {
  error: string;
}
const PasswordError: React.FC<PasswordErrorProps> = ({ error }) => {
  const { t } = useTranslation();
  return (
    <>
      {error.split("\n").map((err) => (
        <Typography
          key={err}
          variant="caption"
          display="block"
          color="error.main"
        >
          {t(err)}
        </Typography>
      ))}
    </>
  );
};

/* eslint-disable no-loop-func */
import { format } from "date-fns";
import i18n from "i18next";

const headers = [
  "Created",
  "Invoice ID",
  "Invoice Code",
  "Customer Email",
  "Invoice Amount",
  "Amount",
  "Valid To",
  "Fee",
  "PC Fee",
  "Netamount",
  "Chekout URL",
  "Document No",
  "Customer Name",
  "Fee Amount",
  "Kahsh Quotation",
  "Item Name",
  "Item Description",
  "Total Item",
  "Qty",
  "Item Amount",
  "Invoice Currency",
  "Status",
  "Payment Method",
  "Payment Method Currency"
];

export const downloadCSV = (data: any) => {
  if (!data) return;
  let csvArr = JSON.parse(JSON.stringify(data));

  csvArr?.forEach((e: any) => {
    e.created = format(
      new Date(e.created),
      i18n.language.includes("pt") ? "dd-MM-yyyy" : "MM-dd-yyyy"
    );
    delete e.createdby;
    delete e.gateway_id;
    e.invoice_currency = e.invoice_currency.iso_code;
    e.status = i18n.language.includes("pt")
      ? e.ispaid
        ? "Pago"
        : !e.isvalid
        ? "Cancelado"
        : "Pendente"
      : e.ispaid
      ? "Paid"
      : !e.isvalid
      ? "Canceled"
      : "Pending";
    delete e.isactive;
    delete e.ispaid;
    delete e.isprocessing;
    delete e.isvalid;
    delete e.isvtex;
    delete e.merchant_id;
    e.payment_method = e.paymentMethod.name;
    e.payment_method_currency = e.currency.sign;
    delete e.currency;
    delete e.paymentMethod;
    delete e.payment_method_deadline_id;
    delete e.payment_method_id;
    delete e.pc_reward;
    delete e.pix_qrcode;
    delete e.postbacks;
    delete e.return_url;
    delete e.reward;
    delete e.reward_amount;
    delete e.txid;
    delete e.wallet;
    e.items.forEach((e) => {
      delete e.created;
      delete e.createdby;
      delete e.isactive;
      delete e.id;
    });
  });
  const makeCSV = async (array: Array<object>, str: String = "") => {
    if (!array) return;
    headers.forEach((e: String) => {
      return (str += `${e};`);
    });
    str += "\n";
    for (let i = 0; i < array.length; i++) {
      Object.values(array[i]).forEach((e, index: number) => {
        if (Array.isArray(e)) {
          if (str.indexOf("REVERSE BREAK LINE") === -1) {
            str += "REVERSE BREAK LINE";
          }
          for (let j = 0; j < e.length; j++) {
            str += "\n";
            str += ";".repeat(index);
            Object.values(e[j]).forEach((e) => {
              str += `${e};`;
            });
          }
          str = str.replace("REVERSE BREAK LINE", `;;;;;REVERSE BREAK LINE`);
        } else if (typeof e != "object") {
          if (str.indexOf("REVERSE BREAK LINE") !== -1) {
            str = str.replace("REVERSE BREAK LINE", `${e};REVERSE BREAK LINE`);
          } else {
            str += `${e};`;
          }
        }
      });
      str = str.replace("REVERSE BREAK LINE", "");
      str += "\n";
    }
    var myBlob = new Blob([(`\uFEFF` + str) as BlobPart], {
      type: "data:text/csv;charset=utf-8;"
    });

    var url = window.URL.createObjectURL(myBlob);
    var anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = `Kahsh_Invoices_${Date.now()}_${new Date()
      .toLocaleString()
      .split(" ")[0]
      .replaceAll("/", "_")}.csv`;

    anchor.click();
  };
  makeCSV(csvArr);
};

import { yupResolver } from "@hookform/resolvers/yup";
import EditIcon from "@mui/icons-material/Edit";
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";
import { CreateMerchantBase } from "../../../components/createMerchantBase/CreateMerchantBase";
import { GooglePlacesAutocomplete } from "../../../components/inputs/google-places-autocomplete/google-places-autocomplete.component";
import { Select } from "../../../components/inputs/select/select.component";
import { TextFieldMask } from "../../../components/inputs/text-field-mask/text-field-mask.component";
import { TextField } from "../../../components/inputs/text-field/text-field.component";
import {
  urlCities,
  urlCountries,
  urlDistrict,
  urlMerchantCreate,
  urlRegions,
  urlSegments
} from "../../../services/global";
import { cnpjValidator } from "../../../utils/cnpjValidator";
import { cpfValidator } from "../../../utils/cpfValidator";
import { normalizeString } from "../../../utils/normalizeString";

import ImageAddress from "../../../images/createMerchantAddress.png";
import ImageFinish from "../../../images/createMerchantFinish.png";
import ImageSecurity from "../../../images/createMerchantSecurity.png";
import ImageSegment from "../../../images/createMerchantSegment.png";
import ImageShop from "../../../images/createMerchantShop.png";

import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { useHistory } from "react-router-dom";
import { Button } from "../../../components/buttons/Button/Button";
import { Checkbox } from "../../../components/inputs/checkbox/checkbox.component";
import { DatePicker } from "../../../components/inputs/date-picker/datePicker";
import { PhoneInput } from "../../../components/inputs/phoneInput/PhoneInput";
import { useAlert } from "../../../hooks/alert";

interface SelectInterface {
  value: any;
  label: string;
  isoCode?: string;
}

const stepShopValidator = yup.object().shape({
  name: yup
    .string()
    .required("nameRequired")
    .test(`test-two-string`, function (value) {
      const { path, createError } = this;
      return (
        (value && value?.toString().split(" ").length > 1) ||
        createError({ path, message: "fullNameInvalid" })
      );
    }),
  phone: yup
    .string()
    .required("phoneRequired")
    .test(`test-phone`, function (value) {
      const { path, createError } = this;
      return (
        isValidPhoneNumber(`${value}`) ||
        createError({ path, message: "phoneInvalid" })
      );
    }),
  taxid: yup
    .string()
    .required("documentRequired")
    .transform((document) => document.replace(/\D+/g, ""))
    .test(`test-valid-document`, function (value) {
      const { path, createError } = this;
      if (value && value?.length <= 12) {
        return (
          cpfValidator(value) || createError({ path, message: "cpfInvalid" })
        );
      }
      return (
        cnpjValidator(value) || createError({ path, message: "cnpjInvalid" })
      );
    })
});

const stepSegmentValidator = yup.object().shape({
  segmentId: yup.string().test(`test-not-default`, function (value) {
    const { path, createError } = this;
    return (
      value !== "default" || createError({ path, message: "segmentRequired" })
    );
  }),
  birthdate: yup
    .date()
    .required("birthDateShopRequired")
    .max(new Date(), "birthDateShopFuture")
    .nullable()
});

const stepAddressValidator = yup.object().shape({
  countryId: yup.string().test(`test-not-default`, function (value) {
    const { path, createError } = this;
    return (
      value !== "default" || createError({ path, message: "countryRequired" })
    );
  }),
  address1: yup.string().required("addressAutoCompleteInvalid"),
  addressNumber: yup.string().required("addressNumberRequired"),
  zipcode: yup
    .string()
    .required("zipCodeRequired")
    .transform((document) => document.replace(/\D+/g, ""))
    .min(8, "zipCodeInvalid"),
  regionId: yup.string().test(`test-not-default`, function (value) {
    const { path, createError } = this;
    return (
      value !== "default" || createError({ path, message: "regionRequired" })
    );
  }),
  cityId: yup.string().test(`test-not-default`, function (value) {
    const { path, createError } = this;
    return (
      value !== "default" || createError({ path, message: "cityRequired" })
    );
  }),
  districtId: yup.string().test(`test-not-default`, function (value) {
    const { path, createError } = this;
    return (
      value !== "default" || createError({ path, message: "DistrictRequired" })
    );
  })
});

const stepSecurityValidator = yup.object().shape({
  email: yup.string().required("emailRequired").email("emailInvalid"),
  password: yup
    .string()
    .required("passwordRequired")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "isNotStrongPassword"
    ),
  passwordConfirmation: yup
    .string()
    .required("confirmPasswordRequired")
    .oneOf([yup.ref("password"), null], "passwordEqualsError")
});

const stepFinishValidator = yup.object().shape({
  agree: yup.boolean().default(false).oneOf([true], "agreeRequired")
});

const steps = [
  "stepShopLabel",
  "stepAddressLabel",
  "stepSegmentLabel",
  "stepSecurityLabel",
  "stepFinishLabel"
];

export function CreateMerchant() {
  const [step, setStep] = useState(0);
  const [data, setData] = useState<any>({});
  const [place, setPlace] = useState<any>(null);
  const [backdrop, setBackdrop] = useState(false);
  const [taxidMask, setTaxidMask] = useState("999.999.999-99");
  const [loading, setLoading] = useState(false);

  const [segments, setSegments] = useState<SelectInterface[]>([]);
  const [countries, setCountries] = useState<SelectInterface[]>([]);
  const [regions, setRegions] = useState<SelectInterface[]>([]);
  const [cities, setCities] = useState<SelectInterface[]>([]);
  const [districts, setDistricts] = useState<SelectInterface[]>([]);

  const history = useHistory();
  const { t } = useTranslation();
  const { AlertMessage } = useAlert();

  const validator = useMemo(() => {
    if (step === 0) {
      return stepShopValidator;
    }
    if (step === 1) {
      return stepAddressValidator;
    }
    if (step === 2) {
      return stepSegmentValidator;
    }
    if (step === 3) {
      return stepSecurityValidator;
    }
    return stepFinishValidator;
  }, [step]);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validator)
  });

  const handleSubmitMerchant = async (stepData: FieldValues) => {
    try {
      setLoading(true);
      const parsedPhone = parsePhoneNumber(data.phone);
      if (parsedPhone) {
        data.phoneDdi = parsedPhone.countryCallingCode;
        data.phone = parsedPhone.nationalNumber;
      }
      data.taxid = data.taxid.replace(/\D+/g, "");
      data.zipcode = data.zipcode.replace(/\D+/g, "");
      data.birthdate = new Date(data.birthdate).toISOString();

      if (!districts.some((district) => district.value === data?.districtId)) {
        data.district = data?.districtId;
        delete data?.districtId;
      }
      await axios.post(urlMerchantCreate, {
        ...data,
        agree: stepData?.agree
      });
      AlertMessage("success", "successfullCreateAccount");
      history.push("/login");
    } catch (err) {
      setLoading(false);
      return AlertMessage("error", "defaultMessage", err);
    }
  };

  const handleSubmitStep = async (stepData: FieldValues) => {
    setData((prev) => ({ ...prev, ...stepData }));
    return setStep((prev) => prev + 1);
  };

  const setCountryData = useCallback(
    async (countryValue: string) => {
      if (countryValue) {
        const selectedCountry = countries.find(
          (country) =>
            country?.isoCode === countryValue || country?.value === countryValue
        );
        if (selectedCountry) {
          try {
            setValue("countryId", selectedCountry?.value);
            const { data: regionsData } = await axios.get(urlRegions, {
              params: { countryId: selectedCountry?.value }
            });
            if (regionsData?.length) {
              setRegions(
                regionsData.map(({ name, id, code }) => ({
                  value: id,
                  label: name,
                  isoCode: code
                }))
              );
            } else {
              setBackdrop(false);
            }
          } catch (err) {
            setBackdrop(false);
            return AlertMessage("error", "defaultMessage", err);
          }
        } else {
          setBackdrop(false);
        }
      }
    },
    [AlertMessage, countries, setValue]
  );

  const setRegionData = useCallback(
    async (regionValue: string) => {
      if (!regions?.length) {
        return;
      }
      const selectedRegion = regions.find(
        (region) =>
          region?.isoCode === regionValue || region?.value === regionValue
      );
      if (selectedRegion) {
        try {
          setValue("regionId", selectedRegion?.value);
          const { data: citiesData } = await axios.get(urlCities, {
            params: { regionId: selectedRegion?.value }
          });
          if (citiesData?.length) {
            setCities(
              citiesData.map(({ name, id }) => ({ value: id, label: name }))
            );
          } else {
            setBackdrop(false);
          }
        } catch (err) {
          setBackdrop(false);
          return AlertMessage("error", "defaultMessage", err);
        }
      } else {
        setBackdrop(false);
      }
    },
    [AlertMessage, regions, setValue]
  );

  const setCityData = useCallback(
    async (cityValue: string) => {
      if (!cities?.length) {
        return;
      }

      const selectedCity = cities.find(
        (city) =>
          normalizeString(city?.label) === normalizeString(cityValue) ||
          city?.value === cityValue
      );
      if (selectedCity) {
        try {
          setValue("cityId", selectedCity?.value);
          const { data: districtData } = await axios.get(urlDistrict, {
            params: { cityId: selectedCity?.value }
          });
          if (districtData.length) {
            setDistricts(
              districtData.map(({ name, id }) => ({ value: id, label: name }))
            );
          } else {
            setBackdrop(false);
          }
        } catch (err) {
          setBackdrop(false);
          return AlertMessage("error", "defaultMessage", err);
        }
      } else {
        setBackdrop(false);
      }
    },
    [AlertMessage, cities, setValue]
  );

  const setDistrictData = useCallback(
    async (districtValue: string) => {
      if (!districts?.length) {
        return;
      }

      const selectedDistrict = districts.find(
        (city) =>
          normalizeString(city?.label) === normalizeString(districtValue) ||
          city?.value === districtValue
      );
      if (selectedDistrict) {
        setValue("districtId", selectedDistrict?.value);
      }
      setBackdrop(false);
    },
    [districts, setValue]
  );

  const getSegments = async () => {
    try {
      let { data } = await axios.get(urlSegments, {
        params: { countryId: 1 }
      });
      setSegments(data.map(({ name, id }) => ({ value: id, label: name })));
    } catch (err) {
      setBackdrop(false);
      return AlertMessage("error", "defaultMessage", err);
    }
  };

  const getCountries = async () => {
    try {
      let { data } = await axios.get(urlCountries);
      setCountries(
        data.map(({ name, id, isoCode }) => ({
          value: id,
          label: name,
          isoCode
        }))
      );
    } catch (err) {
      setBackdrop(false);
      return AlertMessage("error", "defaultMessage", err);
    }
  };

  useEffect(() => {
    if (step === 1 && !countries?.length) {
      getCountries();
    }
    if (step === 2 && !segments?.length) {
      getSegments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries?.length, segments?.length, step]);

  useEffect(() => {
    if (place?.country) {
      setCountryData(place?.country);
    }
    if (place?.address1) {
      setValue("address1", place?.address1);
    }
    if (place?.addressNumber) {
      setValue("addressNumber", place?.addressNumber);
    }
    if (place?.zipcode) {
      setValue("zipcode", place?.zipcode);
    }
    if (place?.lat && place?.lng) {
      setValue("lat", place?.lat);
      setValue("lng", place?.lng);
    }
  }, [setCountryData, place, setValue]);

  useEffect(() => {
    if (place?.region) {
      setRegionData(place?.region);
    }
  }, [place?.region, setRegionData]);

  useEffect(() => {
    if (place?.city) {
      setCityData(place?.city);
    }
  }, [place?.city, setCityData]);

  useEffect(() => {
    if (place?.district) {
      setDistrictData(place?.district);
    }
  }, [place?.district, setDistrictData]);

  return (
    <>
      {step === 0 && (
        <CreateMerchantBase
          steps={steps}
          promoMessage={"startFree"}
          title={"createMerchantTitle"}
          imageText={t("imageLabel")}
          subtitle={
            <>
              <Typography variant="body2">
                {t("createMerchantSubtitle1")}
              </Typography>
              <Typography variant="body2">
                {t("createMerchantSubtitle2")}
              </Typography>
            </>
          }
          stepPosition={step}
          image={ImageShop}
          content={
            <>
              <TextField
                autoFocus
                label={"name"}
                control={control}
                helperText={errors?.name?.message}
                name="name"
                required
              />

              <PhoneInput
                label="phone"
                control={control}
                helperText={errors?.phone?.message}
                name="phone"
                required
              />

              <TextFieldMask
                mask={taxidMask}
                label="document"
                control={control}
                helperText={errors?.taxid?.message}
                watch={(value) => {
                  if (
                    value?.replace(/\D+/g, "")?.length > 11 ||
                    (value?.replace(/\D+/g, "")?.length === 11 &&
                      !cpfValidator(value))
                  ) {
                    return setTaxidMask("99.999.999/9999-99");
                  }
                  return setTaxidMask("999.999.999-99");
                }}
                name="taxid"
                required
              />
            </>
          }
          onClick={handleSubmit(handleSubmitStep)}
          onClickBack={() => history.push("/login")}
        />
      )}

      {step === 1 && (
        <CreateMerchantBase
          steps={steps}
          promoMessage={"startFree"}
          title={"createMerchantTitle"}
          imageText={t("imageLabel")}
          subtitle={
            <>
              <Typography variant="body2">
                {t("createMerchantSubtitle1")}
              </Typography>
              <Typography variant="body2">
                {t("createMerchantSubtitle2")}
              </Typography>
            </>
          }
          stepPosition={step}
          image={ImageAddress}
          content={
            <>
              <GooglePlacesAutocomplete
                autoFocus
                place={place}
                setPlace={(selectedPlace) => {
                  setBackdrop(true);
                  setPlace(selectedPlace);
                }}
                placeholder={t("address")}
                error={errors?.address1?.message}
                required
              />
              {place && (
                <>
                  <Select
                    label={t("country")}
                    control={control}
                    watch={setCountryData}
                    helperText={errors?.countryId?.message}
                    name="countryId"
                    options={countries}
                    required
                  />

                  <Box display="flex" alignItems="center" gap={2}>
                    <TextField
                      label={t("number")}
                      control={control}
                      name="addressNumber"
                      required
                      helperText={errors?.addressNumber?.message}
                    />
                    <TextFieldMask
                      mask={"99.999-999"}
                      label="zipCode"
                      control={control}
                      helperText={errors?.zipcode?.message}
                      name="zipcode"
                      required
                    />
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection={{ xs: "column", sm: "row" }}
                    gap={2}
                  >
                    <Select
                      label={t("region")}
                      control={control}
                      watch={setRegionData}
                      helperText={errors?.regionId?.message}
                      name="regionId"
                      options={regions}
                      required
                    />
                    <Select
                      label={t("city")}
                      control={control}
                      watch={setCityData}
                      helperText={errors?.cityId?.message}
                      name="cityId"
                      options={cities}
                      required
                    />
                  </Box>
                  <Box display="flex" alignItems="center" gap={2}>
                    <Select
                      label={t("district")}
                      control={control}
                      watch={setDistrictData}
                      helperText={errors?.districtId?.message}
                      name="districtId"
                      options={districts}
                      newOptionDefaultValue={
                        districts.some(
                          (district) => district.value === watch("districtId")
                        ) && watch("districtId") !== "default"
                          ? ""
                          : watch("districtId")
                      }
                      addButton
                      required
                    />
                    <TextField
                      label={t("address2")}
                      control={control}
                      helperText={errors?.address2?.message}
                      name="address2"
                    />
                  </Box>
                </>
              )}
            </>
          }
          onClick={handleSubmit(handleSubmitStep)}
          onClickBack={() => setStep((prev) => prev - 1)}
        />
      )}

      {step === 2 && (
        <CreateMerchantBase
          steps={steps}
          promoMessage={"startFree"}
          title={"createMerchantTitle"}
          imageText={t("imageLabel")}
          subtitle={
            <>
              <Typography variant="body2">
                {t("createMerchantSubtitle1")}
              </Typography>
              <Typography variant="body2">
                {t("createMerchantSubtitle2")}
              </Typography>
            </>
          }
          stepPosition={step}
          image={ImageSegment}
          content={
            <>
              <Select
                autoFocus
                label={t("segment")}
                control={control}
                helperText={errors?.segmentId?.message}
                name="segmentId"
                options={segments}
                required
              />

              <TextField
                label={t("corporateName")}
                control={control}
                name="corporateName"
              />

              <DatePicker
                label={t("birthDateShop")}
                control={control}
                helperText={errors?.birthdate?.message}
                name="birthdate"
                maxDate={new Date()}
                required
              />
            </>
          }
          onClick={handleSubmit(handleSubmitStep)}
          onClickBack={() => setStep((prev) => prev - 1)}
        />
      )}

      {step === 3 && (
        <CreateMerchantBase
          steps={steps}
          promoMessage={"startFree"}
          title={"createMerchantTitle"}
          imageText={t("imageLabel")}
          subtitle={
            <>
              <Typography variant="body2">
                {t("createMerchantSubtitle1")}
              </Typography>
              <Typography variant="body2">
                {t("createMerchantSubtitle2")}
              </Typography>
            </>
          }
          stepPosition={step}
          image={ImageSecurity}
          content={
            <>
              <Typography variant="caption">
                {t("alreadyHaveEcosystemAccount1")}{" "}
                <strong>{t("kahshEcosystem")}</strong>{" "}
                {t("alreadyHaveEcosystemAccount2")}
              </Typography>
              <TextField
                autoFocus
                label={t("corporateEmail")}
                control={control}
                helperText={errors?.email?.message}
                name="email"
                required
                type="email"
              />
              <TextField
                label={t("password")}
                control={control}
                helperText={errors?.password?.message}
                name="password"
                required
                type="password"
              />
              <TextField
                label={t("confirmPassword")}
                control={control}
                helperText={errors?.passwordConfirmation?.message}
                name="passwordConfirmation"
                required
                type="password"
              />
            </>
          }
          onClick={handleSubmit(handleSubmitStep)}
          onClickBack={() => setStep((prev) => prev - 1)}
        />
      )}

      {step === 4 && (
        <CreateMerchantBase
          steps={steps}
          promoMessage={"startFree"}
          title={"createMerchantTitle"}
          imageText={t("imageLabel")}
          subtitle={
            <>
              <Typography variant="body2">
                {t("createMerchantSubtitle1")}
              </Typography>
              <Typography variant="body2">
                {t("createMerchantSubtitle2")}
              </Typography>
            </>
          }
          stepPosition={step}
          image={ImageFinish}
          content={
            <Box>
              <Box
                border="1px solid #DDD"
                borderRadius="20px"
                p={4}
                position="relative"
              >
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    onClick={() => {
                      setStep(0);
                    }}
                    variant="outlined"
                    size="small"
                    sx={{ width: 90 }}
                    startIcon={<EditIcon />}
                  >
                    {t("edit")}
                  </Button>
                </Box>
                <Typography variant="body2" fontWeight="bold">
                  {data?.corporateName}
                </Typography>
                <Typography variant="body2">{data?.email}</Typography>
                <Typography variant="body2">{data?.phone}</Typography>
                <br />
                <Typography variant="body2">{data?.taxid}</Typography>
                <Typography variant="body2">{data?.name}</Typography>
                <Typography variant="body2">
                  {format(new Date(data?.birthdate), t("dateFormat"))}
                </Typography>
                <Typography variant="body2" fontWeight="bold">
                  {data?.corporateName}
                </Typography>
                <br />
                <Typography variant="body2">
                  {data?.address1}, {data?.addressNumber}
                </Typography>
                <Typography variant="body2">{data?.address2}</Typography>
                <Typography variant="body2">
                  {place?.district} - {place?.city} / {place?.region}
                </Typography>
                <Typography variant="body2">{data?.zipcode}</Typography>
              </Box>
              <Checkbox
                size="small"
                name="agree"
                control={control}
                error={errors?.agree?.message}
                sx={{ mt: 2 }}
                label={
                  <Typography variant="caption">
                    {t("agreeTerms1")}{" "}
                    <a
                      href="https://exchange.kahsh.com/termosdeuso"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <strong>{t("agreeTerms2")}</strong>
                    </a>{" "}
                    {t("agreeTerms3")}{" "}
                    <a
                      href="https://exchange.kahsh.com/politicadeprivacidade"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <strong>{t("agreeTerms4")}</strong>
                    </a>
                    .
                  </Typography>
                }
              />
            </Box>
          }
          loading={loading}
          onClick={handleSubmit(handleSubmitMerchant)}
          onClickBack={() => setStep((prev) => prev - 1)}
        />
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
export default CreateMerchant;

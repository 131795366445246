import { TaskOutlined, InsertDriveFile } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonInputLabel, Input, FileInputLabel } from "./FileInput.style";

interface FileInputProps {
  onChange: (value: File) => void;
  defaultValue?: File | string | null;
  height?: number;
  width?: number;
  hasButton?: boolean;
  icon: JSX.Element;
  accept?: string;
  error?: boolean;
}
export const FileInput: React.FC<FileInputProps> = ({
  onChange,
  defaultValue,
  height,
  width,
  icon,
  accept,
  error,
  hasButton = false
}) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(
    defaultValue && typeof defaultValue !== "string" ? defaultValue : null
  );
  const [sizeError, setSizeError] = useState(false);

  console.log(file);
  const preview = useMemo(() => {
    if (file) {
      return URL.createObjectURL(file);
    }
    if (typeof defaultValue === "string") {
      return defaultValue;
    }
    return null;
  }, [file, defaultValue]);

  return (
    <>
      <FileInputLabel
        style={{ backgroundImage: `url(${preview})` }}
        height={height}
        width={width}
        haspreview={preview ? 1 : 0}
        error={error || sizeError ? "error" : ""}
      >
        <Input
          type="file"
          onChange={(event) => {
            if (event?.target?.files?.length) {
              const file = event?.target?.files[0];
              if (file?.size / 1024 / 1024 > 5) {
                return setSizeError(true);
              }
              setSizeError(false);
              setFile(file);
              onChange(file);
            }
          }}
        />
        {preview && file && !file.type.includes("image") && (
          <>
            <TaskOutlined
              sx={{
                color: (theme) => `${theme.palette.success.main} !important`
              }}
            />
            <Typography variant="caption" component="p" color="success.main">
              {file?.name}
            </Typography>
          </>
        )}
        {sizeError && (
          <>
            <InsertDriveFile
              sx={{
                color: (theme) => `${theme.palette.error.main} !important`
              }}
            />
            <Typography variant="caption" component="p" color="error.main">
              {t("maximumSize")}
            </Typography>
          </>
        )}
        {!preview && !sizeError && icon}
      </FileInputLabel>
      {hasButton && (
        <ButtonInputLabel>
          <input
            type="file"
            accept={accept ? accept : "image/*"}
            style={{ display: "none" }}
            onChange={(event) => {
              if (event?.target?.files?.length) {
                const file = event?.target?.files[0];
                if (file?.size > 500000) {
                  return setSizeError(true);
                }
                setSizeError(false);
                setFile(file);
                onChange(file);
              }
            }}
          />
          <Button
            variant="contained"
            color="primary"
            component="span"
            sx={{ mx: "auto", maxWidth: "200px" }}
          >
            {preview ? "Alterar imagem" : "Adicionar imagem"}
          </Button>
        </ButtonInputLabel>
      )}
    </>
  );
};

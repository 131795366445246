import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@mui/material";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { TRANSLATIONS_EN } from "./translate/en";
import { TRANSLATIONS_PT } from "./translate/pt";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { deleteCookie, getCookie } from "cookies-next";

import reportWebVitals from "./reportWebVitals";

import axios from "axios";

import App from "./App";

import "./index.css";
import theme from "./theme";
import { AlertProvider } from "./hooks/alert";
import { AuthProvider } from "./contexts/authContext";

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      pt: {
        translation: TRANSLATIONS_PT
      },
      en: {
        translation: TRANSLATIONS_EN
      }
    },
    fallbackLng: "pt"
  });

axios.interceptors.response.use(
  function (success) {
    // debugger;
    if (success) {
      // console.log(success);
    }
    return success;
  },

  function (error: any) {
    if (error) {
      if (error.response.status === 400) {
        /*
         * testar os códigos internos
         */
        if (error.response.data.errorCode === 20) {
          window.location.href = "/emailVerify";
        }
      } else if (error.response.status === 401) {
        deleteCookie("@kahsh-pay:token");
        localStorage.removeItem("@kahsh-pay:user");
        localStorage.removeItem("@kahsh-pay:merchant");
        window.location.href = "/login";
      } else if (error.response.status === 403) {
        window.location.href = "/access-denied";
      }
    }
    throw error;
  }
);

axios.interceptors.request.use((req) => {
  if (req && req.headers) {
    req.headers["Access-Control-Allow-Origin"] = "*";

    const token = getCookie("@kahsh-pay:token");
    if (token) {
      req.headers["authorization"] = token;
    }
  }
  return req;
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18next}>
        <AlertProvider>
          <AuthProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </AuthProvider>
        </AlertProvider>
      </I18nextProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();

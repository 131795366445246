import React from "react";
import { useTranslation } from "react-i18next";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from "@mui/material";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";

import "./DialogCookies.styles.css";
import { Button } from "../buttons/Button/Button";

interface IDialogCookies {
  open?: boolean;
  onClick: () => void;
}

export const DialogCookies = ({ onClick, open = true }: IDialogCookies) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="dialogCookiesStyles"
      sx={{
        top: "unset"
      }}
    >
      <DialogContent
        sx={{ display: "flex", px: 3, alignItems: "center", gap: 1 }}
      >
        <VerifiedUserOutlinedIcon
          sx={{ fontSize: "2.5rem", color: "#FFFFFF" }}
        />
        <DialogContentText
          sx={{
            fontSize: { xs: "0.5rem", sm: "0.8rem", md: "1rem" },
            color: "#ffffff"
          }}
        >
          {t("useCookiesExperience")}
        </DialogContentText>
        <DialogActions>
          <Button
            onClick={onClick}
            size="small"
            variant="outlined"
            sx={{
              width: "5rem"
            }}
          >
            {t("toAccept")}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

import { ChartArea } from "chart.js";
import Chart from "chart.js/auto";

interface IProps {
  data: Array<number>;
  labels: Array<string>;
  stack: string;
}

let width: number, height: number, gradient: any;
function getGradient(ctx: CanvasRenderingContext2D, chartArea: ChartArea) {
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (gradient === null || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, "#306AEE");
    gradient.addColorStop(0.5, "#1B0F39");
  }

  return gradient;
}

const DashboardChart = (props: IProps) => {
  if (window.myChart != null) window.myChart.destroy();
  const ctx = (document.getElementById("myChart") as any)?.getContext(
    "2d"
  )! as HTMLCanvasElement;
  window.myChart = new Chart(ctx, {
    type: "line",
    data: {
      labels: props.labels,
      datasets: [
        {
          label: "Valor da invoice",
          data: props.data,
          pointBackgroundColor: "#4733FF",
          borderColor: function (context) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            if (!chartArea) {
              return null;
            }
            return getGradient(ctx, chartArea);
          },
          borderWidth: 2
        }
      ]
    },
    options: {
      plugins: {
        legend: {
          display: false
        }
      },
      elements: {
        line: {
          tension: 0.5
        }
      },
      scales: {
        y: {
          beginAtZero: true
        }
      }
    }
  });

  return (
    <canvas
      id="myChart"
      width="400px"
      height="100%"
      style={{ maxWidth: "100%" }}
    ></canvas>
  );
};

export default DashboardChart;

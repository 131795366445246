import { Box, Fab, Typography, CircularProgress } from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import { ExpandMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { urlInvoiceList } from "../../services/global";
import { InvoiceDrawerStyled } from "./InvoiceDetailDrawer.style";
import { useAlert } from "../../hooks/alert";
import { ListDetail } from "../listDetail/listDetail";
import { Invoice } from "../../data/models/Invoice";

interface InvoiceDetailDrawerProps {
  open: string;
  setOpen: () => void;
}

export const InvoiceDetailDrawer: React.FC<InvoiceDetailDrawerProps> = ({
  open,
  setOpen
}) => {
  const [invoice, setInvoice] = useState<null | Invoice>(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const { AlertMessage } = useAlert();

  const handleClose = useCallback(() => {
    setOpen();
    setInvoice(null);
  }, [setOpen]);

  const getData = useCallback(async () => {
    try {
      const { data } = await axios.get<Invoice[]>(
        urlInvoiceList + `?invoiceId=${open}`
      );
      setLoading(false);
      if (!data?.length) {
        return AlertMessage("error", "invoiceNotFound");
      }
      setInvoice(data[0]);
    } catch (err) {
      setLoading(false);
      handleClose();
      AlertMessage("error", "defaultMessage", err);
    }
  }, [AlertMessage, handleClose, open]);

  useEffect(() => {
    if (!!open) {
      getData();
    }
  }, [getData, open]);

  return (
    <Box>
      <InvoiceDrawerStyled anchor="bottom" open={!!open} onClose={handleClose}>
        <Fab
          color="primary"
          onClick={handleClose}
          sx={{
            position: "fixed",
            top: "10%",
            borderRadius: "0 0 20px 20px",
            right: "4%",
            width: 60
          }}
        >
          <ExpandMore fontSize="large" />
        </Fab>
        <Box p={4}>
          <Box
            border="1px solid #DDDDDD"
            display="flex"
            flexDirection="column"
            gap={4}
            borderRadius="15px"
            p={2}
          >
            {loading || !invoice ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt={4}
                gap={2}
              >
                <Typography variant="body1" color="#000000">
                  {t("loading")} {t("invoices")}
                </Typography>
                <CircularProgress />
              </Box>
            ) : (
              <ListDetail invoice={invoice} />
            )}
          </Box>
        </Box>
      </InvoiceDrawerStyled>
    </Box>
  );
};

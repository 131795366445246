import axios from "axios";
import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect
} from "react";
import { FieldValues } from "react-hook-form";
import { deleteCookie, setCookie } from "cookies-next";

import { User } from "../data/models/User";
import { Merchant } from "../data/models/Merchant";
import { urlLogin } from "../services/global";

import { fromUnixTime, differenceInSeconds } from "date-fns";
import { logout } from "../firebase";

interface SignInResponse {
  user: User;
  merchant: Merchant;
  auth: {
    authorizationToken: string;
    refreshToken: string;
    expiresIn: number;
  };
}

interface AuthContextData {
  signIn: (data: FieldValues) => Promise<void>;
  signOut: () => void;
  updateUser: (updatedUser: User) => void;
  updateMerchant: (updatedMerchant: Merchant) => void;
  user: User | null;
  merchant: Merchant | null;
}

type AuthContextProps = {
  children: ReactNode;
};

const AuthContext = createContext({} as AuthContextData);

const AuthProvider: React.FC<AuthContextProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [merchant, setMerchant] = useState<Merchant | null>(null);

  const storeData = useCallback((data: SignInResponse) => {
    const expiresIn = differenceInSeconds(
      fromUnixTime(data.auth.expiresIn),
      new Date()
    );
    localStorage.setItem("@kahsh-pay:user", btoa(JSON.stringify(data.user)));

    localStorage.setItem(
      "@kahsh-pay:merchant",
      btoa(JSON.stringify(data.merchant))
    );

    setCookie("@kahsh-pay:token", data.auth.authorizationToken, {
      maxAge: expiresIn,
      path: "/"
    });

    if (data.auth.refreshToken) {
      setCookie("@kahsh-pay:refreshToken", data.auth.refreshToken, {
        maxAge: 60 * 60 * 24, //expira em 24horas
        path: "/"
      });
    }

    setUser(data.user);
    setMerchant(data.merchant);
  }, []);

  const updateUser = useCallback((updatedUser: User) => {
    localStorage.setItem(
      "@kahsh-pay:user",
      Buffer.from(JSON.stringify(updatedUser)).toString("base64")
    );
    setUser(updatedUser);
  }, []);

  const updateMerchant = useCallback((updatedMerchant: any) => {
    localStorage.setItem(
      "@kahsh-pay:user",
      Buffer.from(JSON.stringify(updatedMerchant)).toString("base64")
    );
    setUser(updatedMerchant);
  }, []);

  const signIn = useCallback(
    async ({ username, password }: FieldValues) => {
      try {
        const { data } = await axios.post(urlLogin, {
          username,
          password
        });
        storeData(data);
      } catch (err) {
        throw err;
      }
    },
    [storeData]
  );

  const signOut = useCallback(async () => {
    deleteCookie("@kahsh-pay:token");
    localStorage.removeItem("@kahsh-pay:user");
    localStorage.removeItem("@kahsh-pay:merchant");
    window.location.href = "/login";
    logout();
  }, []);

  useEffect(() => {
    const storedUser = localStorage.getItem("@kahsh-pay:user");
    const storedMerchant = localStorage.getItem("@kahsh-pay:merchant");
    if (storedUser && storedMerchant) {
      const decodedUser = atob(storedUser.toString());
      const user: User = JSON.parse(decodedUser);
      const decodedMerchant = atob(storedMerchant.toString());
      const merchant: Merchant = JSON.parse(decodedMerchant);
      setUser(user);
      setMerchant(merchant);
    }
  }, []);

  const providerValue = useMemo(
    () => ({
      signIn,
      signOut,
      updateUser,
      updateMerchant,
      merchant,
      user
    }),
    [signIn, signOut, updateUser, updateMerchant, merchant, user]
  );
  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};

export { useAuth, AuthProvider };

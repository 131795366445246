import { styled, Typography } from "@mui/material";

export const ImageText = styled(Typography)`
  position: absolute;
  width: 180px;
  top: 28%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2rem;
  text-align: center;
  color: #fff;
  text-shadow: #000 4px 4px 4px;
  z-index: 1;
`;

import {
  Box,
  Typography,
  List,
  ListItemButton,
  ListItem,
  IconButton,
  ListItemAvatar,
  Avatar,
  Collapse,
  ButtonGroup,
  Button,
  ClickAwayListener,
  Pagination,
  Tooltip
} from "@mui/material";
import MoreIcon from "@mui/icons-material/MoreHoriz";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { useTranslation } from "react-i18next";
import { FieldValues } from "react-hook-form";
import { useEffect, useState } from "react";

type Order = {
  orderBy: string;
  direction: "asc" | "desc";
};

type AvatarProps = {
  url: string;
  path: string;
};

export type Cell = {
  id: string;
  label: string;
  align?: "left" | "center" | "right";
  button?: boolean;
  size: number;
  format?: (value: any) => any;
};

interface ListComponentProps {
  cells: Cell[];
  rows: FieldValues[];
  initialOrder: Order;
  itemsPerPage?: number;
  hasDetails?: boolean;
  dynamicId?: string;
  noDataText?: string;
  moreButtons?: {
    label: string;
    action: (value: any) => void;
    viewKey?: string;
  }[];
  button?: {
    label: string;
    action: (value: any) => void;
    icon?: JSX.Element;
    viewKey?: string;
  };
  avatar?: AvatarProps;
  onClick?: (valeu: any) => void;
  onDoubleClick?: (valeu: any) => void;
}

export const ListComponent: React.FC<ListComponentProps> = ({
  cells,
  rows,
  initialOrder,
  hasDetails,
  moreButtons,
  button,
  dynamicId,
  noDataText,
  itemsPerPage = 7,
  avatar,
  onClick,
  onDoubleClick
}) => {
  const { t } = useTranslation();
  const [order, setOrder] = useState<Order>(initialOrder);
  const [page, setPage] = useState(1);
  const [openMoreButton, setOpenMoreButton] = useState("");

  const handleSortByClick = (cellId: string) => {
    let newOrder: Order;
    if (cellId === order.orderBy) {
      newOrder = {
        ...order,
        direction: order.direction === "asc" ? "desc" : "asc"
      };
    } else {
      newOrder = { ...order, orderBy: cellId };
    }
    setOrder(newOrder);
  };

  const sortedRows = rows.sort((rowA, rowB) => {
    if (order.direction === "desc") {
      return rowA[order.orderBy]?.toUpperCase() >
        rowB[order.orderBy]?.toUpperCase()
        ? 1
        : rowB[order.orderBy]?.toUpperCase() >
          rowA[order.orderBy]?.toUpperCase()
        ? -1
        : 0;
    }
    return rowB[order.orderBy]?.toUpperCase() >
      rowA[order.orderBy]?.toUpperCase()
      ? 1
      : rowA[order.orderBy]?.toUpperCase() > rowB[order.orderBy]?.toUpperCase()
      ? -1
      : 0;
  });

  useEffect(() => {
    setPage(1);
  }, [order, rows]);

  return (
    <Box>
      <Box display={{ xs: "none", md: "block" }}>
        <List disablePadding>
          <ListItem disablePadding divider sx={{ px: 2, pb: 1 }}>
            <Typography
              display="flex"
              flex={0.1}
              alignItems="center"
              variant="subtitle1"
              color="rgba(0, 0, 0, 0.5);"
            >
              ID
              {!!dynamicId && (
                <IconButton
                  sx={{
                    p: 0,
                    color:
                      order?.orderBy === dynamicId
                        ? "primary.main"
                        : "text.secondary"
                  }}
                  onClick={() => handleSortByClick(dynamicId || "")}
                >
                  <UnfoldMoreIcon />
                </IconButton>
              )}
            </Typography>
            <Box
              display="flex"
              flex={cells.reduce((acc, cur) => acc + cur.size, 0)}
            >
              {cells?.map((cell) => (
                <Box
                  key={cell.id}
                  display="flex"
                  flex={cell.size}
                  textAlign={cell?.align || "left"}
                  alignItems="center"
                >
                  <Typography variant="subtitle1" color="rgba(0, 0, 0, 0.5);">
                    {t(cell.label)}
                  </Typography>

                  {cell?.button && (
                    <Box display="flex" flexDirection="column">
                      <IconButton
                        sx={{
                          p: 0,
                          color:
                            order?.orderBy === cell.id
                              ? "primary.main"
                              : "text.secondary"
                        }}
                        onClick={() => handleSortByClick(cell.id)}
                      >
                        <UnfoldMoreIcon />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
            {moreButtons?.length && <Box width={40} />}
          </ListItem>
        </List>
      </Box>

      <Box>
        {sortedRows?.length ? (
          <List sx={{ width: "100%" }} disablePadding>
            {sortedRows
              ?.filter(
                (_row, index) =>
                  index >= (page - 1) * itemsPerPage &&
                  index < page * itemsPerPage
              )
              .map((row, index) => (
                <Box key={row.id}>
                  {!!moreButtons?.length && (
                    <Box
                      display={{ md: "none", xs: "flex" }}
                      justifyContent="flex-end"
                      mt={0.5}
                      mb={-1.5}
                      mr={-1}
                    >
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenMoreButton((prev) =>
                            prev === row.id ? "" : row.id
                          );
                        }}
                      >
                        <MoreIcon />
                      </IconButton>
                      <Collapse
                        in={openMoreButton === row.id}
                        sx={{
                          position: "absolute",
                          right: "0px",
                          width: "max-content"
                        }}
                      >
                        <ClickAwayListener
                          onClickAway={() => setOpenMoreButton("")}
                        >
                          <ButtonGroup
                            orientation="vertical"
                            variant="contained"
                            sx={{
                              zIndex: 10,
                              button: {
                                zIndex: "inherit",
                                height: 40
                              }
                            }}
                          >
                            {moreButtons.map(
                              (button, index) =>
                                (!button.viewKey || row[button.viewKey]) && (
                                  <Button
                                    key={index}
                                    onClick={() => button.action(row)}
                                  >
                                    {t(button.label)}
                                  </Button>
                                )
                            )}
                          </ButtonGroup>
                        </ClickAwayListener>
                      </Collapse>
                    </Box>
                  )}
                  {!!button && (!button.viewKey || row[button.viewKey]) && (
                    <Box
                      display={{ md: "none", xs: "flex" }}
                      justifyContent="flex-end"
                      mt={0.5}
                      mb={-1.5}
                      mr={-1}
                    >
                      <Tooltip title={button.label}>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            button.action(row);
                          }}
                        >
                          {!!button?.icon ? button.icon : <MoreIcon />}
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}

                  <ListItem disablePadding divider sx={{ py: 1 }}>
                    <ListItemButton
                      sx={{ borderRadius: "20px", px: { xs: 0, md: 2 } }}
                      onDoubleClick={() => onDoubleClick && onDoubleClick(row)}
                      onClick={() => onClick && onClick(row)}
                    >
                      <ListItemAvatar
                        sx={{ display: { xs: "none", md: "flex" }, flex: 0.1 }}
                      >
                        <Typography
                          my="auto"
                          fontWeight="500"
                          color="primary.main"
                          variant="body2"
                        >
                          {dynamicId ? row[dynamicId] : index + 1}
                        </Typography>
                        {(avatar?.path || avatar?.url) && (
                          <Avatar
                            alt={avatar?.url}
                            sx={{
                              mx: "auto",
                              display: { xs: "none", md: "flex" }
                            }}
                          >
                            {row[avatar?.path][0]?.toUpperCase()}
                          </Avatar>
                        )}
                      </ListItemAvatar>
                      <Box
                        display="flex"
                        flex={cells.reduce((acc, cur) => acc + cur.size, 0)}
                        flexDirection={{ xs: "column", md: "row" }}
                        sx={{ overflowWrap: "anywhere" }}
                      >
                        <Box
                          display={{ xs: "flex", md: "none" }}
                          alignItems="center"
                          justifyContent="space-between"
                          fontSize={12}
                          fontWeight="600"
                        >
                          <Typography
                            my="auto"
                            color="primary.main"
                            variant="caption"
                            fontSize={10}
                          >
                            ID
                          </Typography>

                          {dynamicId ? row[dynamicId] : index + 1}
                        </Box>
                        {cells.map((cell) => (
                          <Typography
                            key={cell.id}
                            display="flex"
                            variant="body2"
                            flex={cell.size}
                            textAlign={cell?.align || "left"}
                            component="div"
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              width="100%"
                              textAlign={{ md: "left", xs: "right" }}
                              fontSize={{
                                xs: "12px !important",
                                md: "inherit !important"
                              }}
                              sx={{
                                "*": {
                                  fontSize: {
                                    xs: "12px !important",
                                    md: "inherit !important"
                                  }
                                }
                              }}
                            >
                              <Typography
                                my="auto"
                                color="primary.main"
                                variant="caption"
                                fontSize={"10px !important"}
                                display={{ xs: "flex", md: "none" }}
                                textAlign="left"
                              >
                                {t(cell?.label)}
                              </Typography>

                              {cell?.format ? cell.format(row) : row[cell.id]}
                            </Box>
                          </Typography>
                        ))}
                      </Box>
                      {!!moreButtons?.length && (
                        <Box display={{ md: "unset", xs: "none" }}>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setOpenMoreButton((prev) =>
                                prev === row.id ? "" : row.id
                              );
                            }}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Collapse
                            in={openMoreButton === row.id}
                            sx={{
                              position: "absolute",
                              right: "0px",
                              width: "max-content"
                            }}
                          >
                            <ClickAwayListener
                              onClickAway={() => setOpenMoreButton("")}
                            >
                              <ButtonGroup
                                orientation="vertical"
                                variant="contained"
                                sx={{
                                  zIndex: 10,
                                  button: {
                                    zIndex: "inherit",
                                    height: 40
                                  }
                                }}
                              >
                                {moreButtons.map(
                                  (button, index) =>
                                    (!button.viewKey ||
                                      row[button.viewKey]) && (
                                      <Button
                                        key={index}
                                        onClick={() => button.action(row)}
                                      >
                                        {t(button.label)}
                                      </Button>
                                    )
                                )}
                              </ButtonGroup>
                            </ClickAwayListener>
                          </Collapse>
                        </Box>
                      )}
                      {!!button && (!button.viewKey || row[button.viewKey]) && (
                        <Box display={{ md: "unset", xs: "none" }}>
                          <Tooltip title={button.label}>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                button.action(row);
                              }}
                            >
                              {!!button?.icon ? button.icon : <MoreIcon />}
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                    </ListItemButton>
                  </ListItem>
                </Box>
              ))}
          </List>
        ) : (
          <Box display="flex" justifyContent="center" mt={4}>
            <Typography>{noDataText ? t(noDataText) : t("noData")}</Typography>
          </Box>
        )}
      </Box>
      {!!sortedRows?.length && (
        <Box display="flex" justifyContent="center" mt={4}>
          <Pagination
            page={page}
            onChange={(_e, value) => {
              setPage(value);
            }}
            siblingCount={0}
            count={Math.ceil(rows?.length / itemsPerPage)}
            color="primary"
          />
        </Box>
      )}
    </Box>
  );
};

import {
  FormControlLabel,
  Checkbox as CheckboxBase,
  SxProps,
  Theme,
  FormHelperText,
  FormControl
} from "@mui/material";
import { Control, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface CheckboxProps {
  sx?: SxProps<Theme>;
  label?: string | JSX.Element;
  size?: "medium" | "small";
  control: Control;
  name: string;
  defaultChecked?: boolean;
  error?: any;
  disabled?: boolean;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  sx,
  label,
  control,
  name,
  size,
  error,
  defaultChecked,
  disabled
}) => {
  const { field } = useController({
    name,
    control,
    defaultValue: defaultChecked || false
  });
  const { t } = useTranslation();

  return (
    <FormControl error={!!error}>
      <FormControlLabel
        control={
          <CheckboxBase
            size={size || "medium"}
            checked={!!field.value}
            onChange={field.onChange}
            sx={{
              color: (theme) =>
                !!error ? theme.palette.error.main : theme.palette.grey[600]
            }}
            disabled={disabled}
          />
        }
        label={label || ""}
        sx={sx}
      />
      {!!error && <FormHelperText>{t(error)}</FormHelperText>}
    </FormControl>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";

import { useEffect, useState } from "react";

import { AlertColor, CircularProgress, Grid, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import axios from "axios";

import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";

import AlertComponent from "../../components/alertComponent/AlertComponent";

import ImgPlaceholder from "../../images/imgplaceholder.png";
import cloudPerspective from "../../images/settings/cloudPerspective.png";
import userPerspective from "../../images/settings/userPerspective.png";
import walletPerspective from "../../images/settings/walletPerspective.png";

import { PhoneInput } from "../../components/inputs/phoneInput/PhoneInput";
import { urlAccountChangePassword } from "../../services/global";

import { FieldValues, useForm } from "react-hook-form";
import { Button } from "../../components/buttons/Button/Button";
import { TextFieldStyled } from "../../components/inputs/text-field/text-field.styles";

import { TextField } from "../../components/inputs/text-field/text-field.component";
import { useAuth } from "../../contexts/authContext";

import "./Dashboard-settings.css";

const validator = yup.object().shape({
  passwordActual: yup.string().required("passwordRequired"),
  password: yup
    .string()
    .required("passwordRequired")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "isNotStrongPassword"
    ),
  passwordConfirmation: yup
    .string()
    .required("confirmPasswordRequired")
    .oneOf([yup.ref("password"), null], "passwordEqualsError")
});

const DashboardSettings = () => {
  const { t } = useTranslation();
  const { user, merchant } = useAuth();

  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validator)
  });

  const [password, setPassword] = useState<String>("");
  const [confirmPassword, setConfirmPassword] = useState<String>("");
  const [disabledButton, setDisabledButton] = useState(true);
  const [mesageErrorConfirmPassword, setMesageErrorConfirmPassword] =
    useState("");
  const [mesageErrorPassword, setMesageErrorPassword] = useState<Array<String>>(
    []
  );

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>("error");
  const alertTimer = () => {
    setTimeout(() => {
      setAlertError(false);
      setIsLoading(false);
    }, 5000);
  };

  useEffect(() => {
    samePassword();
  }, [password, confirmPassword]);

  useEffect(() => {
    enableButton();
  }, [mesageErrorPassword, mesageErrorConfirmPassword]);

  const samePassword = () => {
    if (password !== confirmPassword) {
      setMesageErrorConfirmPassword(t("passwordsMatch"));
    } else {
      setMesageErrorConfirmPassword("");
    }
  };

  /* Habilitando botão baseado em senha e erros */
  const enableButton = () => {
    setDisabledButton(false);
    if (
      password !== "" &&
      password === confirmPassword &&
      !mesageErrorPassword.length &&
      !mesageErrorConfirmPassword
    ) {
      setDisabledButton(true);
    }
  };

  const handleNewPassword = async (values: FieldValues) => {
    setIsLoading(true);

    try {
      if (values.password === "") {
        setMessage(t("fillAllFields"));
        setSeverity("error");
        setAlertError(true);
        setIsLoading(false);
        alertTimer();
        return;
      }
      await axios.post(
        urlAccountChangePassword,
        {
          email: user?.email,
          currentPassword: values.passwordActual,
          newPassword: values.password
        },
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            "Access-Control-Allow-Origin": "*"
          }
        }
      );

      setSeverity("success");
      setMessage(t("changePasswordSuccess"));
      setAlertError(true);
      setIsLoading(false);
      alertTimer();
    } catch (error: any) {
      setSeverity("error");
      let responseError: any = error.response.data.message;

      switch (responseError) {
        case "User not found":
        case "BAD_PASSWORD":
        case "INVALID_PASSWORD":
          setMessage(t("invalidActualPassword"));
          break;
        default:
          setMessage(t("unkownError"));
      }
      setAlertError(true);
      setIsLoading(false);
      alertTimer();

      setIsLoading(false);
    }
  };

  const handleKeyboardEvent = (e: KeyboardEventHandler) => {
    if (e.key === "Enter") {
      handleSubmit(handleNewPassword)();
    }
  };

  return (
    <Grid container justifyContent="center" alignContent="center">
      <Grid
        container
        mx={{ xs: 3, lg: 2 }}
        className="main-content"
        justifyContent="flex-start"
      >
        {alertError && (
          <AlertComponent
            open={true}
            severity={severity}
            message={message}
            onClick={() => setAlertError(false)}
          />
        )}
        <Grid
          container
          item
          md={3}
          pb={3}
          mx={{ xs: 0.5, md: 0 }}
          alignItems="stretch"
          justifyContent="center"
        >
          <Grid
            mb={2}
            ml={{ xs: 2, md: 0 }}
            display="flex"
            className="box-container"
            justifyContent="flex-start"
            alignItems="center"
            maxWidth={260}
          >
            <Grid item justifyContent="center">
              <img
                src={ImgPlaceholder}
                alt="ImagemLoja"
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "40%",
                  pointerEvents: "none"
                }}
              />
              <Typography variant="body1" color="#373737">
                {merchant?.name}
              </Typography>

              <Typography variant="caption" color="#787A7B">
                {merchant?.address.cityName + ", "}
              </Typography>

              <Typography variant="caption" color="#787A7B">
                {merchant?.address.countryName}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            mb={2}
            ml={{ xs: 2, md: 0 }}
            display="flex"
            maxWidth={260}
            alignItems="center"
            className="box-container"
            justifyContent={{ xs: "center", lg: "flex-start" }}
          >
            <Link to={"/user"} style={{ textDecoration: "none" }}>
              <Grid
                mb={2}
                width="100%"
                display="flex"
                maxWidth={260}
                borderRadius="35px"
                alignItems="center"
                className="cardsContainer"
                justifyContent="flex-start"
                flexDirection={{ xs: "column", lg: "row" }}
              >
                <Grid item className="box-image" mr={2} ml={1}>
                  <img
                    src={userPerspective}
                    className="boxImage"
                    alt="Create user"
                  />
                </Grid>
                <Typography variant="h6">{t("manageUser")}</Typography>
              </Grid>
            </Link>
          </Grid>

          <Grid
            mb={2}
            ml={{ xs: 2, md: 0 }}
            display="flex"
            maxWidth={260}
            alignItems="center"
            className="box-container"
            justifyContent={{ xs: "center", lg: "flex-start" }}
          >
            <Link to={"/api"} style={{ textDecoration: "none" }}>
              <Grid
                mb={2}
                width="100%"
                display="flex"
                maxWidth={260}
                borderRadius="35px"
                alignItems="center"
                className="cardsContainer"
                justifyContent="flex-start"
                flexDirection={{ xs: "column", lg: "row" }}
              >
                <Grid item className="box-image" mr={2} ml={1}>
                  <img
                    src={cloudPerspective}
                    className="boxImage"
                    alt="Create API"
                  />
                </Grid>
                <Typography variant="h6">{t("manageApi")}</Typography>
              </Grid>
            </Link>
          </Grid>

          <Grid
            mb={2}
            ml={{ xs: 2, md: 0 }}
            display="flex"
            maxWidth={260}
            alignItems="center"
            className="box-container"
            justifyContent={{ xs: "center", lg: "flex-start" }}
          >
            <Link to={"/settings"} style={{ textDecoration: "none" }}>
              <Grid
                mb={2}
                width="100%"
                display="flex"
                maxWidth={260}
                borderRadius="35px"
                alignItems="center"
                className="cardsContainer"
                justifyContent="flex-start"
                flexDirection={{ xs: "column", lg: "row" }}
              >
                <Grid item className="box-image" mr={2} ml={1}>
                  <img
                    src={walletPerspective}
                    className="boxImage"
                    alt="Wallet"
                  />
                </Grid>
                <Typography variant="h6">{t("wallet")}</Typography>
              </Grid>
            </Link>
          </Grid>
        </Grid>

        <Grid
          container
          item
          md={9}
          pl={{ xs: 1, md: 3 }}
          mr={{ xs: 0.5, md: 0 }}
          mb={{ xs: 0, md: 2 }}
          alignItems="stretch"
        >
          <Grid
            item
            alignItems="center"
            justifyContent="center"
            className="box-container"
          >
            <Typography mt={2} mb={4} variant="h5">
              {t("merchantInfo")}
            </Typography>

            <Grid container pb={2} justifyContent="space-between" spacing={4}>
              <Grid item sm={6} xs={12}>
                <TextFieldStyled
                  disabled
                  fullWidth
                  size="small"
                  label={t("businessName")}
                  value={merchant?.corporateName}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <TextFieldStyled
                  disabled
                  fullWidth
                  size="small"
                  label={t("emailAddress")}
                  value={merchant?.email}
                />
              </Grid>
            </Grid>

            <Grid container pb={2} justifyContent="space-between" spacing={4}>
              <Grid item sm={6} xs={12}>
                <PhoneInput
                  disabled
                  size="small"
                  label="phone"
                  name="phone"
                  control={control}
                  defaultValue={merchant?.phone}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <TextFieldStyled
                  disabled
                  fullWidth
                  size="small"
                  label={t("country")}
                  value={merchant?.address.countryName}
                />
              </Grid>
            </Grid>

            <Grid container>
              <TextField
                disabled
                control={control}
                label={t("address")}
                name="address"
                defaultValue={
                  (merchant?.address.address1 === null
                    ? ""
                    : merchant?.address.address1 + ", ") +
                  (merchant?.address.address2 === null
                    ? ""
                    : merchant?.address.address2 + ", ") +
                  (merchant?.address.addressNumber === null
                    ? ""
                    : merchant?.address.addressNumber + " - ") +
                  (merchant?.address.cityName === null
                    ? ""
                    : merchant?.address.cityName + ", ") +
                  (merchant?.address.districtName === null
                    ? ""
                    : merchant?.address.districtName + " - ") +
                  (merchant?.address.zipcode === null
                    ? ""
                    : merchant?.address.zipcode)
                }
              />
            </Grid>
          </Grid>

          <Grid mt={2} pb={2} className="box-container">
            <Grid container>
              <Typography mt={2} mb={4} variant="h5">
                {t("changePassword")}
              </Typography>
            </Grid>

            <Grid container item direction={{ xs: "column", md: "row" }}>
              <Grid item flex={1} pr={{ md: 3 }} pb={{ xs: 3, md: 0 }}>
                <TextField
                  required
                  type="password"
                  name="passwordActual"
                  control={control}
                  label={t("passwordActual")}
                  onKeyDown={(e) => handleKeyboardEvent(e)}
                  helperText={errors?.passwordActual?.message}
                />
              </Grid>

              <Grid item flex={1} pr={{ md: 3 }} pb={{ xs: 3, md: 0 }}>
                <TextField
                  required
                  type="password"
                  name="password"
                  control={control}
                  label={t("password")}
                  onKeyDown={(e) => handleKeyboardEvent(e)}
                  helperText={errors?.password?.message}
                />
              </Grid>

              <Grid item flex={1}>
                <TextField
                  required
                  type="password"
                  control={control}
                  name="passwordConfirmation"
                  label={t("confirmPassword")}
                  onKeyDown={(e) => handleKeyboardEvent(e)}
                  helperText={errors?.passwordConfirmation?.message}
                />
              </Grid>
            </Grid>
            <Grid item>
              {mesageErrorPassword.map((item: String, index: number) => {
                return (
                  <span key={index} style={{ color: "red" }}>
                    {item}
                  </span>
                );
              })}
              <span style={{ color: "red", padding: "0.2rem 0.5rem" }}>
                {mesageErrorConfirmPassword}
              </span>
            </Grid>

            <Grid container justifyContent="flex-end">
              {isLoading ? (
                <Grid display="flex" justifyContent="center">
                  <CircularProgress />
                </Grid>
              ) : (
                <Grid width="30%">
                  <Button
                    onClick={handleSubmit(handleNewPassword)}
                    loading={loading}
                  >
                    {t("updatePassword")}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DashboardSettings;

import React from "react";
import { Tooltip, SxProps, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CheckCircleOutline, HighlightOff } from "@mui/icons-material";

interface StatusActiveProps {
  value: boolean;
  sx?: SxProps<Theme>;
}

export const StatusActive: React.FC<StatusActiveProps> = ({ value, sx }) => {
  const { t } = useTranslation();

  if (value) {
    return (
      <Tooltip title={t("active")}>
        <>
          <CheckCircleOutline
            sx={{ ...sx, display: { md: "unset", xs: "none" } }}
            color="success"
          />
          <Typography
            variant="caption"
            color="success.main"
            display={{ md: "none", xs: "unset" }}
          >
            {t("active")}
          </Typography>
        </>
      </Tooltip>
    );
  }
  return (
    <Tooltip title={t("inactive")}>
      <>
        <HighlightOff
          sx={{ ...sx, display: { md: "unset", xs: "none" } }}
          color="error"
        />
        <Typography
          variant="caption"
          color="error.main"
          display={{ md: "none", xs: "unset" }}
        >
          {t("inactive")}
        </Typography>
      </>
    </Tooltip>
  );
};

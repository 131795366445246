import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Skeleton,
  Tooltip,
  Typography
} from "@mui/material";

import { ButtonUnstyled } from "@mui/base";
import { Add, DeleteForever } from "@mui/icons-material";
import deletePerspective from "../../images/deletePerspective.png";

import axios from "axios";
import { urlCurrencyGet, urlInvoiceCreate } from "../../services/global";

import {
  formatCurrency,
  formatCurrencySubmit
} from "../../utils/formatCurrency";

import { useAlert } from "../../hooks/alert";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Button } from "../../components/buttons/Button/Button";
import { TextFieldStyled } from "../../components/inputs/text-field/text-field.styles";
import "./Dashboard-invoice.css";

import { useAuth } from "../../contexts/authContext";
import { TextField } from "../../components/inputs/text-field/text-field.component";
import { getCookie } from "cookies-next";
interface ICustomer {
  name: string;
  email: string;
  documentno: string;
  totalValue: string;
  coin: string;
  precision: number;
  currency_simbol: string;
  currency: Array<any>;
  errors?: string[];
}

interface IItems {
  name: string;
  value: string;
  qty: number | string;
  amount: number;
  errors: string[];
}

interface IInfo {
  address: {
    address1: string;
    address2: string;
    address_id: number;
    address_number: number;
    city_name: string;
    country_name: string;
    district_name?: string;
    lat?: string;
    lng?: string;
    region_name: string;
    zipcode: string;
  };
  merchant: {
    address_id: number;
    corporate_name: string;
    email: string;
    merchant_id: number;
    name: string;
    phone: string;
    taxid: string;
  };
  token: string;
}
const validator = yup.object().shape({
  name: yup
    .string()
    .required("nameRequired")
    .matches(/^[a-zA-Z\s]/, "invalidName")
    .matches(/^[a-zA-Z\s]+\s+[a-zA-Z]/, "fullNameInvalid"),
  email: yup.string().required("emailRequired").email("emailInvalid")
});

const DashboardInvoice = () => {
  const { merchant } = useAuth();
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validator)
  });

  const { AlertMessage } = useAlert();

  const [items, setItems] = useState<Array<IItems>>([]);
  const [customer, setCustomer] = useState<ICustomer>({
    name: "",
    email: "",
    documentno: "",
    totalValue: "",
    coin: "",
    currency_simbol: "R$",
    precision: 2,
    currency: []
  });

  const [info, setInfo] = useState<IInfo>();

  const [openDelete, setOpenDelete] = useState(false);
  const [indexDelete, setIndexDelete] = useState(-1);

  const [loadingData, setLoadingData] = useState(false);

  const [loading, setLoading] = useState(false);

  const [totalValueError, setTotalValueError] = useState(false);

  const getData = useCallback(async () => {
    try {
      setLoadingData(true);
      const { data } = await axios.get(urlCurrencyGet);
      setCustomer((prev) => ({ ...prev, currency: data, coin: "BRL" }));
      setLoadingData(false);
    } catch (err) {
      return AlertMessage("error", "defaultMessage", err);
    }
  }, [AlertMessage]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    for (let i = 0; i < customer.currency.length; i++) {
      if (customer.coin === customer.currency[i].iso_code)
        setCustomer({
          ...customer,
          currency_simbol: customer.currency[i].sign,
          precision: customer.currency[i].precision
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer.coin]);

  useEffect(() => {
    let storage: any = {};
    storage.merchant = merchant;
    storage.address = merchant?.address;
    storage.token = getCookie("@kahsh-pay:token");
    setInfo(storage);
  }, [merchant]);

  useEffect(() => {
    let sum: string | number = 0;
    for (let i = 0; i < items.length; i++) {
      sum += items[i].amount;
    }
    sum = sum.toString();
    setCustomer({ ...customer, totalValue: sum });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, items.length]);

  const validateCustomer = async () => {
    const errors: string[] = [];
    if (!(await trigger("name"))) {
      errors.push("name");
    }
    if (!(await trigger("email"))) {
      errors.push("email");
    }
    if (!(await trigger("documentno"))) {
      errors.push("documentno");
    }
    switch (errors[0]) {
      case "name":
        scrollToId("nameCustomer");
        break;
      case "email":
        scrollToId("emailCustomer");
        break;
      case "documentno":
        scrollToId("documentnoCustomer");
        break;
      default:
        return true;
    }

    return false;
  };

  const validateItems = (): boolean => {
    const errors: string[] = [];
    const tempItems = [...items];
    tempItems.forEach((item, index) => {
      item.errors = [];
      if (!item?.name) {
        item.errors.push("name");
      }
      if (!item?.value || item?.value === "0") {
        item.errors.push("value");
      }
      if (!item?.qty || item?.qty === "0") {
        item.errors.push("qty");
      }
      if (!item?.amount) {
        item.errors.push("amount");
      }
      if (item.errors.length !== 0) {
        scrollToId(`item-container-${index}`);
      }
      errors.push(...item.errors);
    });
    if (errors?.length !== 0) {
      setItems(tempItems);
      return false;
    }
    return true;
  };

  const handleCreateInvoice = async () => {
    if (!items?.length && !Number(customer?.totalValue)) {
      return setTotalValueError(true);
    } else {
      setTotalValueError(false);
    }
    if (!info) return;
    if (!validateItems()) return;
    setLoading(true);
    let invoiceItensValue = 0;
    for (let i = 0; i < items.length; i++) {
      items[i].qty = Number(items[i].qty);
      invoiceItensValue += Number(items[i].amount);
    }
    for (let i = 0; i < items.length; i++) {
      items[i].amount = Number(items[i].value);
    }
    const data: any = {
      customer_email: customer.email,
      invoice_amount:
        invoiceItensValue > 0 ? invoiceItensValue : Number(customer.totalValue),
      invoice_currency: customer.coin,
      payment_method_id: 1,
      documentno: customer.documentno,
      customer_name: customer.name,
      postback_url: "",
      return_url: ""
    };
    if (items?.length) {
      data.items = items;
    }
    try {
      let invoice = await axios.post(urlInvoiceCreate, data, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "Access-Control-Allow-Origin": "*"
        }
      });
      window.location.href = `/invoicelist/${invoice.data.invoice.id}`;
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return AlertMessage("error", "defaultMessage", err);
    }
  };

  const handleChange = (e: string, index: number, parameter: string) => {
    let arr = items.slice();
    arr[index][parameter] = e;
    arr[index]["amount"] =
      Number(arr[index]["value"]) * Number(arr[index]["qty"]);
    setItems(arr);
  };

  const scrollToId = (id: string) => {
    setTimeout(() => {
      window.scrollTo({
        top: document.getElementById(id)?.offsetTop,
        behavior: "smooth"
      });
    }, 100);
  };

  const scrollToLastItem = () => {
    var scrollDiv = document.getElementById(
      `item-container-${items.length - 1}`
    )?.offsetTop;
    setTimeout(() => {
      window.scrollTo({
        top: scrollDiv,
        behavior: "smooth"
      });
    }, 100);
  };

  const handleDeleteOpen = (val: number) => {
    setIndexDelete(val);
    setOpenDelete(true);
  };
  const handleDeleteClose = () => {
    setIndexDelete(-1);
    setOpenDelete(false);
  };
  return (
    <Grid container justifyContent="center" alignContent="center">
      <Grid
        container
        className="main-content"
        justifyContent="flex-start"
        mb={{ xs: 4, sm: 0 }}
      >
        <Modal
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal-delete"
          disableScrollLock
        >
          <Box className="modalBoxDelete" id="deleteModal">
            <Grid item>
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <img src={deletePerspective} alt="Delete" />

                <Typography
                  mb={4}
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    fontSize: "24px",
                    textAlign: "center"
                  }}
                >
                  {t("removeItem")}
                </Typography>

                <Typography
                  mb={4}
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 300,
                    fontSize: "20px",
                    textAlign: "center"
                  }}
                >
                  {t("removeItemCertain")}
                </Typography>

                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-evenly"
                  spacing={1}
                >
                  <ButtonUnstyled
                    className="invoice-button deleteButton"
                    onClick={() => {
                      items.splice(indexDelete, 1);
                      setItems(items);
                      handleDeleteClose();
                    }}
                  >
                    {t("confirm")}
                  </ButtonUnstyled>
                  <ButtonUnstyled
                    className="invoice-button cancelButton"
                    onClick={() => {
                      handleDeleteClose();
                    }}
                  >
                    {t("cancel")}
                  </ButtonUnstyled>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Grid
          item
          p={4}
          xs={12}
          borderRadius={10}
          mx={{ xs: 3, lg: 2 }}
          border="1px solid #ddd"
        >
          {loadingData ? (
            <Box display="flex" width={"100%"} flexDirection="column" gap={2}>
              <Skeleton
                variant="text"
                width={"100%"}
                sx={{ fontSize: "3rem", maxWidth: 300 }}
              />
              <Box display="flex" gap={2}>
                <Box width={"100%"}>
                  <Skeleton
                    variant="text"
                    width={"80%"}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                </Box>

                <Box width={"100%"}>
                  <Skeleton
                    variant="text"
                    width={"80%"}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                </Box>
              </Box>

              <Box display="flex" width={"100%"} gap={2}>
                <Box width={"100%"}>
                  <Skeleton
                    variant="text"
                    width={"80%"}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                </Box>

                <Box width={"100%"}>
                  <Skeleton
                    variant="text"
                    width={"80%"}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                </Box>

                <Box
                  width={"100%"}
                  justifyContent={{ xs: "center", sm: "flex-end" }}
                  pr={{ sm: 7, md: 4 }}
                >
                  <Skeleton
                    variant="text"
                    width={"80%"}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                </Box>
              </Box>
            </Box>
          ) : (
            <>
              <Grid container className="create-invoice-text">
                <Typography mb={4} variant="h5">
                  {t("createCostumer")}
                </Typography>
              </Grid>

              <Grid container justifyContent="space-between" spacing={4}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    required
                    name="name"
                    size="small"
                    id="nameCustomer"
                    control={control}
                    label={t("lowerCaseCustomerName")}
                    helperText={errors?.name?.message}
                    watch={(v) => setCustomer({ ...customer, name: v })}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <TextField
                    required
                    name="email"
                    size="small"
                    id="emailCustomer"
                    control={control}
                    label={t("lowerCaseEmail")}
                    helperText={errors?.email?.message}
                    watch={(v) => setCustomer({ ...customer, email: v })}
                  />
                </Grid>

                <Grid item sm={4} xs={12}>
                  <TextFieldStyled
                    fullWidth
                    size="small"
                    id="documentnoCustomer"
                    value={customer?.documentno}
                    label={t("lowerCaseCustomerDocument")}
                    onChange={(e) => {
                      setCustomer((prev) => ({
                        ...prev,
                        documentno: e.target.value
                      }));
                    }}
                  />
                </Grid>

                <Grid item sm={4} xs={12} position="relative">
                  <TextFieldStyled
                    required
                    fullWidth
                    disabled={items.length > 0}
                    size="small"
                    error={!!totalValueError}
                    label={t("lowerCaseCustomerValue")}
                    helperText={!!totalValueError && t("totalValueRequired")}
                    onChange={(e) => {
                      setCustomer((prev) => ({
                        ...prev,
                        totalValue: formatCurrencySubmit(e.target.value)
                      }));
                    }}
                    value={
                      customer?.totalValue
                        ? `${customer?.currency_simbol} ${formatCurrency(
                            customer?.totalValue,
                            t("language")
                          )}`
                        : ""
                    }
                  />
                </Grid>

                <Grid item sm={4} xs={12}>
                  <TextFieldStyled
                    select
                    required
                    fullWidth
                    size="small"
                    value={customer?.coin}
                    label={t("lowerCaseCustomerCoin")}
                    onChange={(e) => {
                      setCustomer({ ...customer, coin: e.target.value });
                    }}
                  >
                    {customer?.currency.map((currency: any, index: number) => {
                      return (
                        <MenuItem
                          key={index}
                          value={currency?.iso_code}
                        >{`${currency.iso_code} - ${currency.name}`}</MenuItem>
                      );
                    })}
                  </TextFieldStyled>
                </Grid>
              </Grid>
            </>
          )}
          <Grid
            container
            justifyContent="flex-end"
            className="add-item-container"
            mt={4}
          >
            {loadingData ? (
              <Skeleton
                variant="text"
                width={"100%"}
                sx={{ fontSize: "3rem", maxWidth: 300 }}
              />
            ) : (
              <Button
                sx={{ ml: "auto", mr: { xs: "auto", sm: 0 }, maxWidth: 300 }}
                onClick={async () => {
                  if (validateItems() && (await validateCustomer())) {
                    setItems((items: any) => [
                      ...items,
                      {
                        name: "",
                        value: "0",
                        qty: 1,
                        amount: ""
                      }
                    ]);
                    setCustomer({ ...customer, totalValue: "" });
                    scrollToLastItem();
                  }
                }}
              >
                {t("addItem")}
              </Button>
            )}
          </Grid>
        </Grid>
        {items?.map((item: any, index: number) => (
          <Grid
            key={index}
            id={`item-container-${index}`}
            mt={3}
            container
            item
            p={4}
            mx={{ xs: 3, lg: 2 }}
            xs={12}
            border="1px solid #ddd"
            borderRadius={10}
          >
            <Grid container item justifyContent="space-between" mb={4}>
              <Typography
                sx={{
                  fontWeight: 300,
                  fontSize: "30px"
                }}
              >
                {t("items")}
              </Typography>
              <Grid container justifyContent="flex-end" item xs={8}>
                {index === items.length - 1 ? (
                  <Tooltip title={`${t("addItem")}`}>
                    <IconButton
                      color="primary"
                      sx={{
                        width: 42,
                        height: 32,
                        borderRadius: "5px",
                        color: "#fff",
                        mx: 2,
                        backgroundColor: (theme) =>
                          theme.palette.primary.main + " !important"
                      }}
                      size="small"
                      onClick={async () => {
                        if (validateItems() && (await validateCustomer())) {
                          setItems((items: any) => [
                            ...items,
                            {
                              name: "",
                              value: "0",
                              qty: 1,
                              amount: ""
                            }
                          ]);
                          scrollToLastItem();
                        }
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Tooltip>
                ) : null}
                <Tooltip title={`${t("removeItem")}`}>
                  <IconButton
                    size="small"
                    sx={{
                      width: 42,
                      height: 32,
                      borderRadius: "5px",
                      color: "#fff",
                      backgroundColor: (theme) =>
                        theme.palette.error.main + " !important"
                    }}
                    className="buttonsAddAndDelete"
                    onClick={() => {
                      handleDeleteOpen(index);
                    }}
                  >
                    <DeleteForever />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent="space-between" spacing={4}>
                <Grid item xs={12} position="relative">
                  <TextFieldStyled
                    fullWidth
                    autoFocus
                    size="small"
                    value={item.name}
                    label={t("lowerCaseItemDescription")}
                    error={!!item?.errors?.includes("name")}
                    helperText={
                      !!item?.errors?.includes("name") &&
                      t("descriptionRequired")
                    }
                    onChange={(e) => {
                      let parameter = "name";
                      handleChange(e.target.value, index, parameter);
                    }}
                  />
                </Grid>

                <Grid item sm={4.5} xs={12} position="relative">
                  <TextFieldStyled
                    fullWidth
                    size="small"
                    label={t("unitaryValueLowerCase")}
                    error={!!item?.errors?.includes("value")}
                    value={`${customer?.currency_simbol} ${formatCurrency(
                      item?.value ? item?.value : 0,
                      t("language")
                    )}`}
                    onChange={(e) => {
                      let parameter = "value";
                      handleChange(
                        formatCurrencySubmit(e.target.value),
                        index,
                        parameter
                      );
                    }}
                    helperText={
                      !!item?.errors?.includes("value") && t("valueRequired")
                    }
                  />
                </Grid>

                <Grid item sm={3} xs={12} position="relative">
                  <TextFieldStyled
                    fullWidth
                    size="small"
                    value={item?.qty}
                    label={t("qtyLowerCase")}
                    error={!!item?.errors?.includes("qty")}
                    onChange={(e) => {
                      let parameter = "qty";
                      const value = e.target.value.replace(/\D+/g, "");
                      if (Number(value) < 0) return;
                      handleChange(value, index, parameter);
                    }}
                    helperText={
                      !!item?.errors?.includes("qty") && t("amountRequired")
                    }
                  />
                </Grid>

                <Grid item sm={4.5} xs={12} position="relative">
                  <TextFieldStyled
                    fullWidth
                    size="small"
                    label={t("totalItems")}
                    value={
                      item?.amount
                        ? `${customer?.currency_simbol} ${formatCurrency(
                            item?.amount?.toString(),
                            t("language")
                          )}`
                        : "0,00"
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}

        <Grid
          container
          item
          xs={12}
          mt={3}
          sx={
            items.length > 0
              ? {
                  position: "fixed",
                  bottom: "3%",
                  right: { sm: "4%" },
                  zIndex: 90,
                  marginLeft: { xs: -0.5 }
                }
              : null
          }
        >
          <Grid
            width="100%"
            display="flex"
            sx={items.length > 0 ? null : { flex: 1 }}
            justifyContent={{ xs: "center", sm: "flex-end" }}
            pr={{ sm: 7, md: 4 }}
          >
            {loadingData ? (
              <Skeleton
                variant="text"
                width={"100%"}
                sx={{ fontSize: "3rem", maxWidth: 300 }}
              />
            ) : (
              <Button
                sx={{ maxWidth: 300 }}
                onClick={handleSubmit(handleCreateInvoice, validateCustomer)}
                loading={loading}
              >
                {t("createInvoice")}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DashboardInvoice;

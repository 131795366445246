import { Drawer, styled } from "@mui/material";

export const InvoiceDrawerStyled = styled(Drawer)`
  position: relative;
  .MuiDrawer-paper {
    width: 100%;
    height: 90vh;
    border-radius: 15px 15px 0 0;
  }
`;

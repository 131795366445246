export const TRANSLATIONS_EN = {
  // ============= Global =============
  hourFormat: "hh:mma",
  dateFormat: "MM/dd/yyyy",
  currentCountry: "US",
  language: "en-US",
  knowMore: "Know more",
  bePartOfIt: "Be part of it",
  register: "Register",
  fastRegister: "Fast register",
  incompleteRegister: "Incomplete registration. Please register and try again.",
  enter: "Login",
  createAnAccount: "Create an account",
  notHaveAccount: "Don't have an account yet?",
  forgotPassword: "Forgot password?",
  siginUp: "Sign up!",
  user: "USER",
  merchantLogin: "Merchant Login",
  verifyYourEmail: "Verify Your Email",
  resetPassword: "Reset Password",
  forgotPassord: "Forgot password?",
  messageCopy: "Code successfully copied to clipboard",
  copy: "Copy",
  resendEmail: "RESEND E-MAIL",
  spamVerify: "*Check your spam box",
  fullName: "Full name",
  removeItemCertain: "Are you sure you want to remove this item?",
  addOption: "New Option",
  registerUser: "Register User",
  fetchInvoice: "Fetch invoice",
  paid: "Paid",
  pending: "Pending",
  canceled: "Canceled",
  applyFilter: "Apply filter",
  useCookiesExperience:
    "We use cookies to provide a better experience for our users.",
  toAccept: "Accept",
  backToStart: "Back to start",
  noDocumentNo: "No document number.",

  // ============= LABELS =============
  email: "Email",
  corporateEmail: "Corporate Email",
  cancel: "Cancel",
  next: "Next",
  confirm: "Confirm",
  cadastra: "Register",
  confirmPassword: "Confirm password",
  confirmEmail: "Confirme e-mail",
  password: "Password",
  name: "Name",
  corporateName: "Corporate Name",
  segment: "Segment",
  phone: "Phone number",
  birthDateShop: "Founding Date",
  document: "CPF/CNPJ",
  country: "Country",
  address: "Address",
  number: "Address Number",
  zipCode: "Zip Code",
  city: "City",
  district: "District",
  region: "State",
  address2: "Address 2",
  edit: "Edit",
  birthDate: "Birth Date",
  permissions: "Permissions",
  selectAll: "Select All",
  searchUser: "Search User",
  loading: "Loading",
  data: "Data",
  users: "Users",
  role: "Role",
  none: "None",
  inactivate: "Inactivate",
  activate: "Activate",
  gender: "Gender",
  owner: "Owner",
  searchInvoice: "Search Invoice",
  noInvoiceData: "No invoice found",
  invoices: "Invoices",
  Date: "DATE",
  search: "Search",
  active: "Active",
  inactive: "Inactive",
  balance: "Balance",
  general: "General",
  periodReceive: "Period to receive",
  rewards: "Rewards",

  // ============= Email Verify =============
  subtitleEmailVerify: `Your account has been successfully created. To complete the process check your email for a validation request.`,
  homeScreen: "Home Screen",

  // ====== verifyEmail translate
  verifyEmailSuccess: "Email successfully verified",
  successCreated: "Your account has been successfully created.",
  autoLogin: "Logging in on ",
  verifiedEmail: "Email verified!",

  // ============= Password Reset =============
  passwordReset: "Password Reset",
  enterEmail: "Please enter your email",
  emailSent:
    "If email exists, a reset password link will be sent, please check your email.",

  //============= DashBoard =============
  dashboard: "Dashboard",
  createInvoice: "Create Invoice",
  invoiceList: "Invoice List",
  settings: "Settings",
  logout: "Logout",
  accountSettings: "Account Settings",
  extract: "Extract",
  support: "Support",
  historic: "Historic",
  editFinancial: "Edit Financial",
  editPassword: "Edit Password",

  // ============= DashBoard Metrics=============
  editProfile: "EDIT PROFILE",
  invoicesPaid: "Paid Invoices",
  invoicePaid: "Paid Invoice",
  statusDefaulter: "Defaulter",
  statusPaid: "Paid",
  statusCanceled: "Canceled",
  statusPending: "Pending",
  invoicePaidFor: "AMOUNT PAID FOR",
  invoicesPending: "Invoices pending",
  invoiceProcessing: "Processing payment",
  totalInvoices: "Total invoices",
  recentTransactions: "Recent Transactions",
  recentlyPaid: "Recently paid",
  yourPasswordHasBeenReset: "your password has been reset successfully",
  filterByDate: "Filter by date",
  chooseDate: "Choose date",
  chooseAuthenticationMethod: "Choose your authentication method:",
  initDate: "Initial date",
  finalDate: "Final date",
  dateError: "Final date cannot be lesser than initial date!",

  //============= DashBoard Settings=============
  createUser: "Create User",
  logoUpdate: "UPDATE YOUR LOGO",
  editAccount: "Edit Account",
  reTypePassword: "Re-Type Password",
  updatePassword: "Update Password",
  merchantInfo: "Merchant Information",
  businessName: "Business Name",
  emailAddress: "Email Address",
  phoneNum: "Phone Number",
  walletInfo: "Wallet Information",
  walletAddress: "Wallet Address",
  twoFaAuth: "TWO-PHASE AUTHENTICATION",
  twoFaErrorMessage: "Invalid 2FA code",
  twoFAauthmessage: "Enter the 6-digit code from your code generator.",
  twoFaMessage:
    "2Fa code is required and in case of not activation please access your exchange account at",
  twoFaMessage2: "in 2FA Authentication tab and activate it. ",
  twoFaMessageCheckout:
    "For your security, enter the authenticator numbers to complete the payment",
  auth: "AUTHENTICATION",
  updateInfo: "Update Information",
  apiInfo: "API Information",
  apiKey: "API Key",
  apiSecret: "API Secret",
  nullFields: "Please check if username and password are filled correctly.",
  fillAllFields: "Please fill all fields.",

  changePassword: "Change Password",
  passwordActual: "Actual password",

  createAPI: "Create API",
  wallet: "Wallet",

  //============= DashBoard Invoice =============

  createCostumer: "Create Costumer Invoice",
  invoiceSearch: "Invoice search",
  csvExport: "CSV Exports",
  transactionDate: "TRANSACTION DATE",
  transactionDateLowerCase: "Transaction date",
  customerName: "CUSTOMER NAME",
  lowerCaseCustomerName: "Customer Name",
  customerEmail: "CUSTOMER E-MAIL",
  lowerCaseEmail: "Customer E-mail",
  customerDocument: "DOCUMENT NUMBER",
  lowerCaseCustomerDocument: "Document number",
  invoiceValidTo: "Invoice validity",
  customerValue: "TOTAL VALUE",
  lowerCaseCustomerValue: "Total Value",
  customerCoin: "CURRENCIES",
  lowerCaseCustomerCoin: "Currencies",
  invoiceCode: "Invoice Code",
  coin: "Coin",
  checkoutUrl: "Checkout URL",
  addItem: "Add itens",
  removeItem: "Remove item",
  items: "Item",
  itemDescription: "ITEM DESCRIPTION",
  lowerCaseItemDescription: "Item description",
  itemsDescription: "Items description",
  noInvoiceItems: "No invoice items to list",
  unitaryValue: "UNITARY VALUE",
  value: "Value",
  unitaryValueLowerCase: "Unitary Value",
  invoiceData: "Invoice Data",
  qty: "QUANTITY",
  qtyLowerCase: "Quantity",
  totalItem: "ITEM TOTAL",
  totalItems: "Items Total",
  zeroInvoiceItems: "No items added to the invoice.",
  toastError: "Error creating invoice, please check all fields and try again.",
  invoiceID: "INVOICE ID",
  amount: "AMOUNT",
  description: "DESCRIPTION",
  descriptionLowerCase: "Description",

  seeAll: "See All",

  //============= Payment Page =============
  nameError: "Invalid full name",
  documentError: "Invalid document",
  documentEqualsError: "There is already a registered user with this document",
  or: "or",
  exchangeRate: "Exchange quotation",
  successfullCreateAccount: "Account successfully created",
  successfullCreateAccountBody:
    "You will receive an email to confirm your registration.",
  howDoYou: "How would you like to pay",
  finish: "Checkout",
  rewardAmount: "Rewards",
  feesAmount: "Fess",
  netAmount: "Net amount",
  fastRegisterBody: "Buy, sell and manage your funds anytime, anywhere.",
  paymentFor: "PAYMENT FOR:",
  stillNotKahsh: "Still don't have Kahsh?",
  buyHereNow: "Buy here now!",
  hasKahsh: "I have Kahsh in Metamask",
  notEnoughCoins: "Not enough coins for payment.",
  connectMetaMask: "Connect Metamask",
  haveYouBoughtFromExchange: "have you bought from Kahsh Exchange?",
  seller: "Seller",
  timeLeft: "Time left",
  requestPayment: "PAYMENT REQUEST FOR KAHSH",
  infoPayment1: `ALREADY HAVE KAHSH IN YOUR WALLET?`,
  infoPayment2: `Scan the QRCode with your wallet`,
  infoPayment3: `NO KAHSH YET?`,
  infoPayment4: `Click buy Kahsh below`,
  clickToCopy: "Click here to copy",
  payWithKahsh: "Pay with Wallet",
  buyKahsh: "Buy Kahsh",
  paymentMethods: "Payment methods",
  payWithMetamask: "Pay directly from Metamask wallet",
  payWithWallet: "Pay Exchange balance",
  buyYourKahsh: "Buy your Kahsh \n now on  Exchange",
  paymentAvailableUtil: "PAYMENT AVAILABLE UNTIL ",
  copyAddress: "Copy Kahsh Wallet",
  copyAddressCheckout: "Copy address:",

  invoiceDatils: "INVOICE DETAILS",
  invoiceDetailsLowerCase: "Invoice details",
  totalItens: "TOTAL ITEMS",
  feesPayment: "FEES",
  feesPaymentLowerCase: "Fees",
  amountLowerCase: "Amount",
  subTotalPayment: "SUBTOTAL",
  subTotalPaymentLowerCase: "Subtotal",
  makePayment: "Pay",
  paymentSuccess: "Successfully paid!",
  paymentCanceled: "Canceled payment!",
  ordered: "Order made in:",
  back: "Back",
  backToStore: "Back to store",

  //============= Merchant Account Settings =============
  localShop: "Local Shop",
  updateYourLogo: "Update your Logo",
  updateInformation: "Update Information",
  retypePAssword: "Re-type Password",

  //============= Register =============
  merchantName: "Merchant Name",
  kshWalletAddress: "KSH Wallet Address",
  bySigningUp: "By signing up you agree to the KahshPay ",
  termsOfService: "Terms of Service & Privacy Policy.",
  alreadyHaveAnAccount: "Already have an account? ",
  signIn: "Sign In",
  dontHaveAccountSiginUp: "Don't have an account yet? ",
  signUp: "Sign up!",

  //============= AccountCreatedEmailSend =============
  checkYourSpamBox: "*Check your spam box",
  accountSuccessfullyCreated: "Your account was successfully created",
  enterSytem: "ENTRAR NO SISTEMA",

  LoggingIntoSystem: "Entrando no sistema em 5s",

  //============= EmailVerifySuccess =============

  emailSuccessfullyVerified: "Email successfully verified",

  //============= Compre com Kahsh =============

  valueInReais: "Value in Reais (BRL)",
  valueInUppercase: "VALUE IN REAIS (BRL)",
  valueInKsh: "KSH VALUE",
  makeYourQuickAccount: "Make your quick account here",
  here: "here",
  generatePix: "Generate pix",
  generatePixError: "Pix generate error, please try again later",
  generateQrCode: "Generate QRCode",
  payWithOtherWallet: "Pay with other wallet",
  PrivacyTerms1: "I have read and agree to the ",
  PrivacyTerms2: "Terms of Use ",
  and: "and ",
  PrivacyTerms3: "Privacy Policy.",
  senhaDoesNotMatch: "Password does not match!",
  emailDoesNotMatch: "Email does not match!",
  invalidCPF: "Invalid CPF",

  pixCopyPaste: "Copy Pix",
  KSHAddress: "Kahsh Wallet",
  PIXAddress: "PIX Address",

  // =========== CREATE MERCHANT =============
  startFree: "Start for Free",
  createMerchantTitle: "Tell us about your store",
  createMerchantSubtitle1: "This is the initial information about your store.",
  createMerchantSubtitle2: "You can change it at any time.",
  imageLabel: "It's time to grow your business online",
  stepShopLabel: "Your Store",
  stepAddressLabel: "Address",
  stepSegmentLabel: "Segment",
  stepSecurityLabel: "Security",
  stepFinishLabel: "Finish",
  step: "Step",
  of: "of",
  kahshEcosystem: "Kahsh Ecosystem",
  alreadyHaveEcosystemAccount1: "If you already have an account with",
  alreadyHaveEcosystemAccount2: "use the same email and password!",
  agreeTerms1: "I have read and agree to the",
  agreeTerms2: "Terms of Use",
  agreeTerms3: "and",
  agreeTerms4: "Privacy Policy",

  // =========== COMPLIANCE =============

  stepMerchantData: "Merchant Data",
  stepDocumentFile: "Documents",
  stepSelfieFile: "Selfie",
  stepAddressFile: "Address",
  stepSocialContract: "Social Contract",
  successfullyRegisterTitle: "Registration done",
  successfullyRegister: "Registration was successful",
  waitAnalysis: "Wait for the analysis and approval of your documents.",
  viewStatus: "View status",
  useValidDocument: "Use a valid ID document",
  onlyDocumentList:
    "Only the following documents listed below will be accepted.",
  anyOtherReject: "All others will be rejected.",
  selectDocument:
    "Choose the file from your computer and make sure all the information is clear.",
  validDocument:
    "Make sure it is a valid document and that all information is clear.",
  selfieWithDoc: "Selfie with ID document",
  selfieInfos:
    "To validate your account, take a photo of your face and the side that holds the document open!",
  doNotUseGlasses: "Don't wear glasses or accessories",
  doNotUseCap: "Don't wear cap",
  illuminatedPlace: "Be in a bright place",
  useSameDoc: "The document must be the same sent in the previous step",
  doNotCoverYourFace: "Documento não deve cobrir o rosto",
  proofAddress: "Proof of residence",
  yourNameDoc: "The document must be in your name.",
  addProofAddress: "Attach proof of address",
  socialContract: "Social Contract",
  addSocialContract: "Attach social contract",

  approvedTitle: "Approved",
  approvedSubtitle: "Your registration has been successfully approved",
  approvedDate: "Approval date",

  pendingTitle: "Pending",
  pendingSubtitle: "Your registration is awaiting review, please wait!",
  pendingDate: "Registration Date",

  rejectedTitle: "Rejected",
  rejectedSubtitle: "Your registration was not approved!",
  rejectedDate: "Rejection date",
  rejectedReason: "Reason for rejection",

  analysisTitle: "Under analysis",
  analysisSubtitle: "Your registration is being analyzed... Please wait!",
  analysisDate: "Registration Date",

  // =========== NOT FOUND =============
  notFoundTitle: "INVOICE NOT \n FOUND",
  invoiceNotFound: "Invoice not found",
  notFoundSubtitle:
    "We couldn't find the invoice you  \n  searched for. Try again.",

  // =========== NOT FOUND 2 =============
  pageNotFound: "Page not found!",
  pageNotFoundSubtitle: "The page you are looking for was not found",
  notFoundTitle2: "Image page not found",

  // ============= VALIDATORS REQUIRED=============
  required: "Required",
  descriptionRequired: "Description is required",
  valueRequired: "Value is required",
  amountRequired: "Amount is required",
  nameRequired: "Name is required",
  corporateNameRequired: "Fantasy name is required",
  phoneRequired: "Phone number is required",
  emailRequired: "Email is required",
  documentRequired: "CPF/CNPJ is required",
  addressRequired: "Address is required",
  countryRequired: "Country is required",
  segmentRequired: "Segment is required",
  addressNumberRequired: "Address number is required",
  zipCodeRequired: "Zip code is required",
  regionRequired: "State is required",
  cityRequired: "City is required",
  DistrictRequired: "District is required",
  passwordRequired: "Password is required",
  confirmPasswordRequired: "Password confirmation is required",
  birthDateShopRequired: "Founding date is required",
  birthDateRequired: "Birth Date is required",
  agreeRequired: "You must accept the terms and privacy policy to continue.",

  // ============= VALIDATORS =============
  addressAutoCompleteInvalid: "The selected address has no street name",
  cpfInvalid: "Invalid CPF",
  cnpjInvalid: "Invalid CNPJ",
  zipCodeInvalid: "Invalid zip code",
  phoneInvalid: "Invalid phone number",
  invalidName: "Name must have only letters",
  invalidCorporateName: "Fantasy name must contain only letters",
  fullNameInvalid: "Incomplete name",
  emailInvalid: "Invalid email",
  birthDateShopFuture: "Founding date cannot be in the future",
  birthDateFuture: "Birth Date cannot be in the future",
  passwordEqualsError: "Password do not match",
  totalValueRequired: "Total Value is required",
  isNotStrongPassword:
    "Your password must have at least: \n - 8 digits \n- 1 lowercase letter \n- 1 uppercase letter \n- 1 number \n- 1 symbol.",
  invalidActualPassword: "Current password is incorrect.",
  invalidPassword: "Password is incorrect.",
  invalidDate: "Invalid date",
  maximumSize: "The file size is larger than the maximum allowed of 5mb",
  unkownError: "Unknown error. Contact an administrator or try again later.",

  // ====== changePassword translate
  enterNewPassword: "Please enter new password ",
  newPassword: "New password ",
  save: "Save ",
  oneUpperCase: "Must contain at least one upper case! ",
  oneLowerCase: "Must contain at least one lower case! ",
  oneNumber: "Must contain a number! ",
  eightDigits: "Must contain at least 8 digits. ",
  passwordsMatch: "Passwords does not match. ",
  changePasswordSuccess: "You changed your password successfully! ",
  invalidToken: "Invalid token.",

  //============= ERROR =============
  error400:
    "Incorrect email or password, please check your data and try again.",
  error10caracteres:
    "It is necessary to contain first and last name and at least 10 characters",
  noPermissionsFound: "Must check permissions or select a role for the user",

  //============= ERROR =============
  defaultMessage: "Oops! Something went wrong, try again later",
  naohafaturaspendentes: "There are no outstanding invoices",
  naoexistemtransacoesrecentes: "There are no recent transactions",
  "Passwordandemaildonotmatch.": "Email and Password does not match!",
  "Useralreadyexistsinthedatabase.": "User already exists",
  Accounthasbeentemporarilydisabledduetomanyfailedloginattempts:
    "Account has been temporarily disabled due to many failed login attempts. Update your password.",
  "TaxIDviolatesuniqueconstraint.": "CPF/CNPJ is already in use",
  Youremailneedstobeverified: "Your email needs to be verified.",
  FAcodenotprovided: "2FA code not provided",
  Incompleteregister: "Incomplete register.",

  RoleIDnotfound: "Role ID not found.",
  Rolenotfound: "Role not found.",
  Invoicenotfound: "Invoice not found.",
  Userpasswordincorrect: "User password incorrect.",
  Invalidactiontoken: "Invalid action token.",
  TaxIDviolatesuniqueconstraint: "Tax ID violates unique constraint.",
  Itisnecessarytoputinvoice_amountoritems:
    " It is necessary to put invoice_amount or items.",
  Currenciescannotbyconverted: "Currencies cannot by converted.",
  Errorgetingthetransaction: "Error geting the transaction.",
  Walletdonotmatch: "Wallet do not match.",

  //=============== METAMASK =============

  metaMaskDeny: "Please authorize Metamask to continue payment",
  metaMaskNotInstalled:
    "Please install metamask or activate extension, for more information access https://metamask.io",
  gasError:
    "Apparently you are out of gas to run this transaction, please check the values ​​in your wallet and try again.",
  metaMaskError:
    "Error when opening Metamask, please review the values and try again.",

  //============= APIs =============
  apiList: "APIs list",
  newApi: "New API",
  manageApi: "Manage API",
  manageUser: "Manage Users",
  userName: "User name",
  type: "Type",
  people: "People",
  searchSustomers: "Search customers",
  addNewCustomers: "Add new customers",
  editNewCustomers: "Edit new customers",
  deleteCustomers: "Delete customers",
  customerContract: "Customer contract",
  customerAgreementAlert: "Customer Agreement - Alert",
  bank: "Bank",
  searchBanks: "search banks",
  addNewBanks: "Add new banks",
  editNewBanks: "Edit new banks",
  excludeBanks: "Exclude banks",
  account: "Account",
  searchAccounts: "Search accounts",
  addNewAccounts: "Add new accounts",
  editNewAccounts: "Edit new accounts",
  deleteAccounts: "Delete accounts",
  cities: "Cities",
  searchCities: "Search cities",
  addNewCities: "Add new cities",
  EditNewCities: "Edit new cities",
  excludeCities: "Exclude cities",
  applayToken: "Apply token",
  applaykey: "Apply key",

  //============= MErchant List =============
  userList: "Users List",
  newUser: "New User",
  editUser: "Edit User",
  registrationSccessful: "Registration successful!",
  successfullyDeleteUser: "User successfully inactivated",

  //============= Edit User =============
  editUserScreen: "User",
  companyName: "Company Name",
  buttonUpdateYourData: "Update your data",
  updateMerchant: "Store successfully updated",
  selectCountry: "Select a country to proceed",

  //============= CAMPO DE BUSCA LATERAL =============
  invoicesCode: "Invoice code",
  invoiceId: "Invoice id",
  documentNumber: "Document number",
  cleanFilter: "Clean filter",
  quickPaymentLink: "Quick payment link",

  //============= ROLE =============
  successfullyCreateRole: "Role successfully created",
  successfullyInactiveRole: "Role successfully inactivated",
  createRole: "Create Role",
  editRole: "Edit Role",
  searchRole: "Search Role",
  roleList: "Role List",
  noUserData: "No user found",
  noRoleData: "No role found",
  noData: "No data found",
  passwordRecoverSubtitle: "Enter your email to recover your password",
  wannaLogin: "Do you want to enter?",
  enterSub: "Login!",
  //============= ERROR =============
  EMAIL_NOT_FOUND: "Email not found",
  passwordSuccessReset: "Success in submitting password recovery!",
  passwordResetSuccessfully: "Password reset successfully",
  Usernotfound: "E-mail and/or password incorrect.",
  //============= API =============
  createApi: "Create API",
  editApi: "Edit API",

  //============= access denied =============
  accessDeniedTitle: "SOMETHING WENT WRONG",
  accessDeniedSubtitle1:
    "Unable to decipher the route of the page you are looking for ",
  accessDeniedSubtitle2: "or you are not allowed to take off.",
  accessDeniedSubtitle3: "Recalculate the route!"
};

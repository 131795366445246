import { InfoOutlined } from "@mui/icons-material";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { FieldValues } from "react-hook-form";
import { BackAndSubmitButton } from "../../components/buttons/BackAndSubmit/BackAndSubmit";
import { DocumentCard } from "../../components/documentCard/DocumentCard";
import { FileInput } from "../../components/inputs/fileInput/FileInput";
import { TooltipCompliance } from "../../styles/TooltipCompliance";

import SubtitlesIcon from "@mui/icons-material/Subtitles";
import BadgeIcon from "@mui/icons-material/Badge";
import { useState } from "react";

import RG from "../../images/compliance/rg.png";
import Passport from "../../images/compliance/passport.png";
import CNH from "../../images/compliance/cnh.png";
import OtherDocument from "../../images/compliance/otherDocument.png";
import { useTranslation } from "react-i18next";

interface ComplianceStep {
  onSubmit: (stepData: FieldValues) => void;
  onBack: () => void;
  compliance: FieldValues | null;
}

export const DocumentFile: React.FC<ComplianceStep> = ({
  onSubmit,
  onBack,
  compliance
}) => {
  const { t } = useTranslation();

  const [selectedType, setSelectedType] = useState("RG");
  const [documentFront, setDocumentFront] = useState<File | null>(
    compliance?.documentFront || null
  );
  const [documentBack, setDocumentBack] = useState<File | null>(
    compliance?.documentBack || null
  );

  const [documentFrontError, setDocumentFrontError] = useState(false);
  const [documentBackError, setDocumentBackError] = useState(false);

  const handleSubmit = () => {
    if (
      (selectedType === "RG" || selectedType === "PASSPORT") &&
      !documentBack
    ) {
      return setDocumentBackError(true);
    }

    if (!documentFront) {
      return setDocumentFrontError(true);
    }

    onSubmit(
      selectedType === "RG" || selectedType === "PASSPORT"
        ? { documentFront, documentBack }
        : { documentFront }
    );
  };

  const onChangeType = (type: string) => {
    setSelectedType(type);
  };

  return (
    <Grid container spacing={4} height="calc(100vh - 160px)">
      <Grid
        item
        container
        xs={12}
        md={6}
        direction="column"
        justifyContent="space-between"
        gap={1}
      >
        <Box>
          <Box display="flex" alignItems="center" gap={2}>
            <Avatar sx={{ background: (theme) => theme.palette.primary.main }}>
              2
            </Avatar>
            <Typography fontWeight="bold">
              {t("useValidDocument")}
              <Typography variant="body2" component="span" display={"block"}>
                {t("anyOtherReject")}
              </Typography>
            </Typography>
          </Box>
        </Box>
        <DocumentCard
          picture={RG}
          onClick={() => onChangeType("RG")}
          title="Documento de identidade - RG"
          selected={selectedType === "RG"}
        />
        <DocumentCard
          picture={Passport}
          onClick={() => onChangeType("PASSPORT")}
          title="Passaporte"
          selected={selectedType === "PASSPORT"}
        />
        <DocumentCard
          picture={CNH}
          onClick={() => onChangeType("CNH")}
          title="Carteira de Habilitação - CNH"
          selected={selectedType === "CNH"}
        />
        <DocumentCard
          picture={OtherDocument}
          onClick={() => onChangeType("OTHER")}
          title="Outro documento de identificação válido"
          selected={selectedType === "OTHER"}
        />

        <BackAndSubmitButton onSubmit={handleSubmit} onBack={onBack} />
      </Grid>
      <Grid item container xs={12} md={6} direction="column">
        <Box display="flex" flexDirection="column" gap={2} height="100%">
          <Box display="flex" gap={1} alignItems="center">
            <TooltipCompliance
              placement="top"
              title={t("validDocument")}
              style={{ backgroundColor: "#fff" }}
            >
              <InfoOutlined />
            </TooltipCompliance>
            <Typography variant="body2">{t("selectDocument")}</Typography>
          </Box>
          <FileInput
            onChange={(file) => {
              setDocumentFrontError(false);
              setDocumentFront(file);
            }}
            error={documentFrontError}
            defaultValue={documentFront}
            icon={<BadgeIcon />}
            accept="image/*, .pdf"
          />
          {(selectedType === "RG" || selectedType === "PASSPORT") && (
            <FileInput
              error={documentBackError}
              onChange={(file) => {
                setDocumentBackError(false);
                setDocumentBack(file);
              }}
              defaultValue={documentBack}
              icon={<SubtitlesIcon />}
              accept="image/*, .pdf"
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

import React from "react";

import { useHistory } from "react-router-dom";

import { Grid, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/buttons/Button/Button";

import "./Accessdenied.styles.css";

export const Accessdenied: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Grid
      container
      direction="column"
      minHeight="100vh"
      spacing={2}
      alignItems="center"
      justifyContent="center"
      px={4}
    >
      <Grid
        item
        xs={6}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography className="rocketLeft">Oo</Typography>
        <Box className="rocketAnimation" />
        <Typography className="rocketRight">ps!</Typography>
      </Grid>

      <Grid
        item
        xs={6}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        textAlign="center"
        gap={4}
      >
        <Typography variant="h4" color="primary.main" fontWeight="bold">
          {t("accessDeniedTitle")}
        </Typography>

        <Typography variant="body1" color="primary.main">
          {t("accessDeniedSubtitle1")} <br />
          {t("accessDeniedSubtitle2")} <br />
          {t("accessDeniedSubtitle3")}
        </Typography>

        <Button
          onClick={() => history.push("/")}
          sx={{
            maxWidth: "350px"
          }}
        >
          {t("back")}
        </Button>
      </Grid>
    </Grid>
  );
};

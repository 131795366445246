import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4733FF"
    },
    secondary: {
      main: "#001C57"
    },
    error: {
      main: "#CD0808"
    }
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "1rem"
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          textOverflow: "ellipsis",
          overflow: "hidden"
        }
      },
      variants: [
        {
          props: { variant: "body1" },
          style: {
            fontSize: "1.25rem"
          }
        },
        {
          props: { variant: "body2" },
          style: {
            fontSize: "1rem"
          }
        },
        {
          props: { variant: "h6" },
          style: {
            fontSize: "1rem"
          }
        },
        {
          props: { variant: "caption" },
          style: {
            fontSize: "0.9rem"
          }
        },
        {
          props: { variant: "subtitle1" },
          style: {
            fontSize: "13px"
          }
        }
      ]
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "#B4B4C1",
          backgroundColor: "#FDFDFF",
          borderRadius: "15px",
          zIndex: "-1"
        },
        input: {
          borderRadius: "15px"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none"
        }
      },
      variants: [
        {
          props: { variant: "outlined", color: "primary" },
          style: {
            color: "#000",
            borderColor: "#000",
            "&:hover": {
              borderColor: "#000",
              backgroundColor: "#FFF",
              opacity: 0.9
            },
            backgroundColor: "#FFF"
          }
        }
      ]
    }
  }
});

export default theme;

import { useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import { DialogCookies } from "./components/diaologCookies/DialogCookies";

import { ForgotPassword } from "./components/forgotPassword/ForgotPassword";

import { LanguageSelectorComponent } from "../src/components/languageSelector/LanguageSelectorComponent";
import { BackgroundBar } from "./styles/BackgroundBar";
import { useAlert } from "./hooks/alert";
import { Login } from "./pages/authentication/Login";

import DashboardList from "./pages/dashboard/Dashboard";

import CreateMerchant from "./pages/merchant/createMerchant/CreateMerchant";

import CheckoutKahshPay from "./pages/checkout/checkoutKahshPay/CheckoutKahshPay";

import { ChangePassword } from "./components/changePassword/ChangePassword";
import { VerifyEmail } from "./pages/authentication/VerifyEmail";

import { InvoiceNotFound } from "./pages/IvoiceNotFound";
import { Accessdenied } from "./components/accessdenied/Accessdenied";
import { NotFound } from "./pages/NotFound";

import "./App.css";

export default function App() {
  const { Alert } = useAlert();
  const location = useLocation();
  const [acceptCookie, setAcceptCookie] = useState(false);

  const hasBar = () => {
    const hasBarRoutes = [
      "/login",
      "/verify/",
      "/forgot-password",
      "/change-password",
      "/register"
    ];

    if (location.pathname) {
      return hasBarRoutes.some((route) => location.pathname.includes(route));
    }
    return false;
  };

  const handleClose = () => {
    localStorage.setItem("@kahsh-pay:cookie", true);
    setAcceptCookie(true);
  };

  return (
    <>
      <LanguageSelectorComponent
        sx={{
          display:
            hasBar() ||
            location.pathname.includes("/checkout") ||
            location.pathname.includes("notfound") ||
            location.pathname.includes("access-denied")
              ? "flex"
              : "none",
          position: "absolute",
          top: "15px",
          left: "30px"
        }}
      />
      <Alert />

      {!localStorage.getItem("@kahsh-pay:cookie") && !acceptCookie && (
        <DialogCookies onClick={() => handleClose()} />
      )}
      {hasBar() && <BackgroundBar />}
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/verify/:token" component={VerifyEmail} />
        <Route path="/checkout" exact component={CheckoutKahshPay} />
        <Route path="/forgot-password" exact component={ForgotPassword} />
        <Route
          path="/change-password/:token"
          exact
          component={ChangePassword}
        />

        <Route path="/register" exact component={CreateMerchant} />
        <Route path="/access-denied" exact component={Accessdenied} />
        <Route path="/invoicenotfound" exact component={InvoiceNotFound} />
        <Route path="/notfound" exact component={NotFound} />
        <Route component={DashboardList} />
      </Switch>
    </>
  );
}

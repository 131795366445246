import { Search } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useDebounce from "../../hooks/useDebounce";
import { TextFieldStyled } from "../inputs/text-field/text-field.styles";
import { Writing } from "./SearchInput.style";

interface SearchInputProps {
  onChange: (value: string) => void;
}
export const SearchInput: React.FC<SearchInputProps> = ({ onChange }) => {
  const [value, setValue] = useState("");

  const { t } = useTranslation();
  const debounced = useDebounce(value);

  useEffect(() => {
    onChange(debounced);
  }, [debounced, onChange]);

  return (
    <TextFieldStyled
      sx={{ maxWidth: 400 }}
      fullWidth
      size="small"
      value={value}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {value === debounced ? <Search color="primary" /> : <Writing />}
          </InputAdornment>
        )
      }}
      label={t("search")}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

import {
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Box,
  Container
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Button } from "../buttons/Button/Button";
import { ImageText } from "./CreateMerchantBase.styles";

interface CreateMerchantBaseProps {
  steps: string[];
  promoMessage: string;
  title: string;
  subtitle: string | JSX.Element;
  stepPosition: number;
  image: string;
  imageText: string;
  content: JSX.Element;
  onClick: () => void;
  onClickBack?: () => void;
  loading?: boolean;
}

export const CreateMerchantBase: React.FC<CreateMerchantBaseProps> = ({
  steps,
  promoMessage,
  title,
  subtitle,
  stepPosition,
  image,
  content,
  onClick,
  onClickBack,
  loading,
  imageText
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Grid container minHeight="100vh">
        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            maxWidth={450}
            px={2}
            mx="auto"
            height="100%"
          >
            <Typography variant="body2">{t(promoMessage)} </Typography>

            <Typography py={2} variant="h5" fontWeight="bold">
              {t(title)}
            </Typography>

            <Box>{subtitle}</Box>

            <Stepper
              activeStep={stepPosition}
              connector={<Typography mx={1}>|</Typography>}
              sx={{ my: 4, mx: { xs: "auto", sm: 0 } }}
            >
              {steps.map(
                (label, index) =>
                  index !== steps.length - 1 && (
                    <Step
                      key={label}
                      completed={index < stepPosition}
                      sx={{
                        p: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: { xs: "column", sm: "row" }
                      }}
                    >
                      <StepLabel
                        sx={{
                          ".Mui-active": {
                            color: (theme) => theme.palette.primary.main
                          }
                        }}
                      />

                      <Typography
                        variant="caption"
                        fontSize={{ xs: "0.65rem", sm: "0.75rem" }}
                        mt={0.5}
                      >
                        {t(label)}
                      </Typography>
                    </Step>
                  )
              )}
            </Stepper>

            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              gap={2}
              width="100%"
            >
              {content}
            </Box>

            <Box
              pt={4}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              gap={2}
            >
              {!!onClickBack && (
                <Button onClick={onClickBack} variant="outlined">
                  {t("back")}
                </Button>
              )}
              <Button onClick={onClick} loading={loading}>
                {stepPosition === steps.length - 1 ? t("confirm") : t("next")}
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} display={{ xs: "none", md: "flex" }}>
          <Box position="relative" my="auto">
            <ImageText>{t(imageText)}</ImageText>

            <img
              src={image}
              alt="Recovery Password"
              width="100%"
              style={{
                position: "relative",
                maxHeight: "80vh",
                objectFit: "contain"
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

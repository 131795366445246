export const countriesLabels = {
  DZ: 'DZA',
  AO: 'AGO',
  BJ: 'BEN',
  BW: 'BWA',
  BF: 'BFA',
  BI: 'BDI',
  CM: 'CMR',
  CV: 'CPV',
  CF: 'CAF',
  TD: 'TCD',
  KM: 'COM',
  CG: 'COG',
  CD: 'COD',
  CI: 'CIV',
  DJ: 'DJI',
  EG: 'EGY',
  GQ: 'GNQ',
  ER: 'ERI',
  ET: 'ETH',
  GA: 'GAB',
  GM: 'GMB',
  GH: 'GHA',
  GN: 'GIN',
  GW: 'GNB',
  KE: 'KEN',
  LS: 'LSO',
  LR: 'LBR',
  LY: 'LBY',
  MG: 'MDG',
  MW: 'MWI',
  ML: 'MLI',
  MR: 'MRT',
  MU: 'MUS',
  YT: 'MYT',
  MA: 'MAR',
  MZ: 'MOZ',
  NA: 'NAM',
  NE: 'NER',
  NG: 'NGA',
  RE: 'REU',
  RW: 'RWA',
  SH: 'SHN',
  ST: 'STP',
  SN: 'SEN',
  SC: 'SYC',
  SL: 'SLE',
  SO: 'SOM',
  ZA: 'ZAF',
  SS: 'SSD',
  SD: 'SDN',
  SZ: 'SWZ',
  TZ: 'TZA',
  TG: 'TGO',
  TN: 'TUN',
  UG: 'UGA',
  EH: 'ESH',
  ZM: 'ZMB',
  ZW: 'ZWE',
  AF: 'AFG',
  AM: 'ARM',
  AZ: 'AZE',
  BH: 'BHR',
  BD: 'BGD',
  BT: 'BTN',
  IO: 'IOT',
  BN: 'BRN',
  KH: 'KHM',
  CN: 'CHN',
  CX: 'CXR',
  CC: 'CCK',
  GE: 'GEO',
  HK: 'HKG',
  IN: 'IND',
  ID: 'IDN',
  IR: 'IRN',
  IQ: 'IRQ',
  JP: 'JPN',
  JO: 'JOR',
  KZ: 'KAZ',
  KP: 'PRK',
  KR: 'KOR',
  KW: 'KWT',
  KG: 'KGZ',
  LB: 'LBN',
  MO: 'MAC',
  MY: 'MYS',
  MV: 'MDV',
  MN: 'MNG',
  MM: 'MMR',
  NP: 'NPL',
  OM: 'OMN',
  PK: 'PAK',
  PS: 'PSE',
  PH: 'PHL',
  QA: 'QAT',
  SA: 'SAU',
  SG: 'SGP',
  LK: 'LKA',
  SY: 'SYR',
  TW: 'TWN',
  TJ: 'TJK',
  TH: 'THA',
  TM: 'TKM',
  AE: 'ARE',
  UZ: 'UZB',
  VN: 'VNM',
  YE: 'YEM',
  AX: 'ALA',
  AL: 'ALB',
  AD: 'AND',
  AT: 'AUT',
  BY: 'BLR',
  BE: 'BEL',
  BA: 'BIH',
  BG: 'BGR',
  HR: 'HRV',
  CY: 'CYP',
  CZ: 'CZE',
  DK: 'DNK',
  EE: 'EST',
  FO: 'FRO',
  FI: 'FIN',
  FR: 'FRA',
  DE: 'DEU',
  GI: 'GIB',
  GR: 'GRC',
  VA: 'VAT',
  HU: 'HUN',
  IS: 'ISL',
  IE: 'IRL',
  IL: 'ISR',
  IT: 'ITA',
  LV: 'LVA',
  LI: 'LIE',
  LT: 'LTU',
  LU: 'LUX',
  MK: 'MKD',
  MT: 'MLT',
  MD: 'MDA',
  MC: 'MCO',
  ME: 'MNE',
  NL: 'NLD',
  NO: 'NOR',
  PL: 'POL',
  PT: 'PRT',
  RO: 'ROU',
  RU: 'RUS',
  SM: 'SMR',
  RS: 'SRB',
  SK: 'SVK',
  SI: 'SVN',
  ES: 'ESP',
  SE: 'SWE',
  CH: 'CHE',
  TR: 'TUR',
  UA: 'UKR',
  GB: 'GBR',
  AI: 'AIA',
  AG: 'ATG',
  AW: 'ABW',
  BS: 'BHS',
  BB: 'BRB',
  BZ: 'BLZ',
  BM: 'BMU',
  VG: 'VGB',
  CA: 'CAN',
  KY: 'CYM',
  CR: 'CRI',
  CU: 'CUB',
  DM: 'DMA',
  DO: 'DOM',
  SV: 'SLV',
  GL: 'GRL',
  GD: 'GRD',
  GP: 'GLP',
  GT: 'GTM',
  HT: 'HTI',
  HN: 'HND',
  JM: 'JAM',
  MQ: 'MTQ',
  MX: 'MEX',
  NI: 'NIC',
  PA: 'PAN',
  PR: 'PRI',
  KN: 'KNA',
  LC: 'LCA',
  PM: 'SPM',
  VC: 'VCT',
  BL: 'BLM',
  TT: 'TTO',
  TC: 'TCA',
  US: 'USA',
  VI: 'VIR',
  AS: 'ASM',
  AU: 'AUS',
  CK: 'COK',
  FJ: 'FJI',
  PF: 'PYF',
  GU: 'GUM',
  KI: 'KIR',
  MH: 'MHL',
  FM: 'FSM',
  NR: 'NRU',
  NC: 'NCL',
  NZ: 'NZL',
  NU: 'NIU',
  NF: 'NFK',
  MP: 'MNP',
  PW: 'PLW',
  PG: 'PNG',
  PN: 'PCN',
  WS: 'WSM',
  SB: 'SLB',
  TL: 'TLS',
  TO: 'TON',
  VU: 'VUT',
  AR: 'ARG',
  BO: 'BOL',
  BR: 'BRA',
  CL: 'CHL',
  CO: 'COL',
  EC: 'ECU',
  FK: 'FLK',
  GF: 'GUF',
  GY: 'GUY',
  PY: 'PRY',
  PE: 'PER',
  UY: 'URY',
  VE: 'VEN'
};

import { CircularProgress, Grid } from "@mui/material";
import { useState } from "react";

export default function Support() {
  const [loading, setLoading] = useState<boolean>(true);

  return (
    <Grid container position="relative">
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          position="absolute"
          height={450}
        >
          <CircularProgress />
        </Grid>
      ) : null}
      <iframe
        src="https://tawk.to/chat/6328759854f06e12d895974d/1gdb0mn8u"
        title="Chat"
        width="100%"
        onLoad={() => setLoading(false)}
        height="450px"
        frameBorder="none"
      />
    </Grid>
  );
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { AlertColor, CircularProgress, Grid, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import axios from "axios";

import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";

import { urlAccountChangePassword } from "../../services/global";

import { FieldValues, useForm } from "react-hook-form";
import { Button } from "../buttons/Button/Button";

import { TextField } from "../inputs/text-field/text-field.component";
import { useAuth } from "../../contexts/authContext";

import AlertComponent from "../alertComponent/AlertComponent";

const validator = yup.object().shape({
  passwordActual: yup.string().required("passwordRequired"),
  password: yup
    .string()
    .required("passwordRequired")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "isNotStrongPassword"
    ),
  passwordConfirmation: yup
    .string()
    .required("confirmPasswordRequired")
    .oneOf([yup.ref("password"), null], "passwordEqualsError")
});

export const EditPassword = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [loading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validator)
  });

  const [password, setPassword] = useState<String>("");
  const [confirmPassword, setConfirmPassword] = useState<String>("");

  const [disabledButton, setDisabledButton] = useState(true);

  const [message, setMessage] = useState("");
  const [alertError, setAlertError] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>("error");
  const [mesageErrorConfirmPassword, setMesageErrorConfirmPassword] =
    useState("");
  const [mesageErrorPassword, setMesageErrorPassword] = useState<Array<String>>(
    []
  );

  const alertTimer = () => {
    setTimeout(() => {
      setAlertError(false);
      setIsLoading(false);
    }, 5000);
  };

  useEffect(() => {
    samePassword();
  }, [password, confirmPassword]);

  useEffect(() => {
    enableButton();
  }, [mesageErrorPassword, mesageErrorConfirmPassword]);

  const samePassword = () => {
    if (password !== confirmPassword) {
      setMesageErrorConfirmPassword(t("passwordsMatch"));
    } else {
      setMesageErrorConfirmPassword("");
    }
  };

  const enableButton = () => {
    setDisabledButton(false);
    if (
      password !== "" &&
      password === confirmPassword &&
      !mesageErrorPassword.length &&
      !mesageErrorConfirmPassword
    ) {
      setDisabledButton(true);
    }
  };

  const handleNewPassword = async (values: FieldValues) => {
    setIsLoading(true);

    try {
      if (values.password === "") {
        setMessage(t("fillAllFields"));
        setSeverity("error");
        setAlertError(true);
        setIsLoading(false);
        alertTimer();
        return;
      }
      await axios.post(
        urlAccountChangePassword,
        {
          email: user?.email,
          currentPassword: values.passwordActual,
          newPassword: values.password
        },
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            "Access-Control-Allow-Origin": "*"
          }
        }
      );

      setSeverity("success");
      setMessage(t("changePasswordSuccess"));
      setAlertError(true);
      setIsLoading(false);
      alertTimer();
    } catch (error: any) {
      setSeverity("error");
      let responseError: any = error.response.data.message;

      switch (responseError) {
        case "User not found":
        case "BAD_PASSWORD":
        case "INVALID_PASSWORD":
          setMessage(t("invalidActualPassword"));
          break;
        default:
          setMessage(t("unkownError"));
      }
      setAlertError(true);
      setIsLoading(false);
      alertTimer();

      setIsLoading(false);
    }
  };

  const handleKeyboardEvent = (e: KeyboardEventHandler) => {
    if (e.key === "Enter") {
      handleSubmit(handleNewPassword)();
    }
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      minHeight="calc(100vh - 62px - 16px - 16px)"
    >
      {alertError && (
        <AlertComponent
          open={true}
          severity={severity}
          message={message}
          onClick={() => setAlertError(false)}
        />
      )}
      <Grid
        container
        item
        md={9}
        pl={{ xs: 1, md: 3 }}
        mr={{ xs: 0.5, md: 0 }}
        mb={{ xs: 0, md: 2 }}
        alignItems="stretch"
      >
        <Grid mt={2} pb={2} className="box-container">
          <Grid container>
            <Typography mt={2} mb={4} variant="h5">
              {t("password")}
            </Typography>
          </Grid>

          <Grid container item direction={{ xs: "column", md: "row" }}>
            <Grid item flex={1} pr={{ md: 3 }} pb={{ xs: 3, md: 0 }}>
              <TextField
                required
                type="password"
                name="passwordActual"
                control={control}
                label={t("passwordActual")}
                onKeyDown={(e) => handleKeyboardEvent(e)}
                helperText={errors?.passwordActual?.message}
              />
            </Grid>

            <Grid item flex={1} pr={{ md: 3 }} pb={{ xs: 3, md: 0 }}>
              <TextField
                required
                type="password"
                name="password"
                control={control}
                label={t("password")}
                onKeyDown={(e) => handleKeyboardEvent(e)}
                helperText={errors?.password?.message}
              />
            </Grid>

            <Grid item flex={1}>
              <TextField
                required
                type="password"
                control={control}
                name="passwordConfirmation"
                label={t("confirmPassword")}
                onKeyDown={(e) => handleKeyboardEvent(e)}
                helperText={errors?.passwordConfirmation?.message}
              />
            </Grid>
          </Grid>
          <Grid item>
            {mesageErrorPassword.map((item: String, index: number) => {
              return (
                <span key={index} style={{ color: "red" }}>
                  {item}
                </span>
              );
            })}
            <span style={{ color: "red", padding: "0.2rem 0.5rem" }}>
              {mesageErrorConfirmPassword}
            </span>
          </Grid>

          <Grid container justifyContent="flex-end">
            {isLoading ? (
              <Grid display="flex" justifyContent="center">
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                container
                minWidth={196}
                width={{ md: "30%" }}
                pt={{ xs: 10, md: 0 }}
              >
                <Button
                  onClick={handleSubmit(handleNewPassword)}
                  loading={loading}
                >
                  {t("updatePassword")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

import { ContentCopy, Person } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Collapse,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Divider
} from "@mui/material";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Invoice } from "../../data/models/Invoice";
import { useAlert } from "../../hooks/alert";
import { currencyFormatter } from "../../hooks/currencyFormatter";
import { Cell, ListComponent } from "../listComponent/ListComponent";
import { StatusLabel } from "../statusLabelExtract/StatusLabel";

const cells: Cell[] = [
  {
    id: "name",
    label: "descriptionLowerCase",
    size: 0.3,
    button: true
  },
  {
    id: "amount",
    label: "unitaryValueLowerCase",
    size: 0.25,
    button: true,
    format: (value) => (
      <Typography variant="body2">
        {value?.currencyIsoCode} {currencyFormatter(value?.amount)}
      </Typography>
    )
  },
  {
    id: "qty",
    label: "qtyLowerCase",
    size: 0.2,
    button: true
  },
  {
    id: "total_item",
    label: "totalItems",
    size: 0.25,
    button: true,
    format: (value) => (
      <Typography variant="body2">
        {value?.currencyIsoCode} {currencyFormatter(value?.total_item)}
      </Typography>
    )
  }
];

interface ListDetailProps {
  invoice: Invoice;
}

export const ListDetail: React.FC<ListDetailProps> = ({ invoice }) => {
  const { t } = useTranslation();
  const { AlertMessage } = useAlert();

  const statusLabel = {
    PE: t("statusPending"),
    PA: t("statusPaid"),
    CA: t("statusCanceled")
  };

  return (
    <Collapse in={true} timeout="auto" unmountOnExit sx={{ p: 2 }}>
      <Typography variant="body1" mb={4}>
        {t("invoiceData")}
      </Typography>
      <Divider />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection={{ md: "row", xs: "column" }}
        gap={1}
        py={2}
        px={2}
      >
        <Box
          display={{ xs: "flex", md: "none" }}
          gap={1}
          width="100%"
          alignItems="center"
        >
          <Avatar sx={{ height: 32, width: 32 }}>
            {invoice?.customer_name ? (
              invoice?.customer_name[0]?.toUpperCase()
            ) : (
              <Person />
            )}
          </Avatar>
          <Typography variant="body2">{invoice?.customer_name}</Typography>
        </Box>

        <Box
          display="flex"
          justifyContent={{ xs: "space-between", md: "flex-start" }}
          width={{ md: "auto", xs: "100%" }}
          flexDirection={{ md: "column", xs: "row" }}
        >
          <Typography
            variant="body2"
            fontWeight="bold"
            fontSize={{ xs: 10, md: 16 }}
          >
            ID
          </Typography>
          <Typography variant="body2" color="primary" fontWeight={600}>
            {invoice?.id}
          </Typography>
        </Box>

        <Box display={{ md: "flex", xs: "none" }} gap={1}>
          <Avatar>
            {invoice?.customer_name ? (
              invoice?.customer_name[0]?.toUpperCase()
            ) : (
              <Person />
            )}
          </Avatar>
          <Box>
            <Typography
              variant="body2"
              fontWeight="bold"
              fontSize={{ xs: 10, md: 16 }}
            >
              {t("customerName")}
            </Typography>
            <Typography
              variant="body2"
              textAlign={{ md: "left", xs: "right" }}
              fontSize={{ xs: 12, md: 16 }}
            >
              {invoice?.customer_name}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent={{ xs: "space-between", md: "flex-start" }}
          width={{ md: "auto", xs: "100%" }}
          flexDirection={{ md: "column", xs: "row" }}
        >
          <Typography
            variant="body2"
            fontWeight="bold"
            fontSize={{ xs: 10, md: 16 }}
          >
            {t("amount")}
          </Typography>
          <Typography
            variant="body2"
            textAlign={{ md: "left", xs: "right" }}
            fontSize={{ xs: 12, md: 16 }}
          >
            {invoice?.currency?.iso_code}{" "}
            {currencyFormatter(invoice?.netamount)}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent={{ xs: "space-between", md: "flex-start" }}
          width={{ md: "auto", xs: "100%" }}
          flexDirection={{ md: "column", xs: "row" }}
        >
          <Typography
            variant="body2"
            fontWeight="bold"
            fontSize={{ xs: 10, md: 16 }}
          >
            {t("customerValue")}
          </Typography>
          <Typography
            variant="body2"
            textAlign={{ md: "left", xs: "right" }}
            fontSize={{ xs: 12, md: 16 }}
          >
            {invoice?.invoice_currency?.iso_code}{" "}
            {currencyFormatter(invoice?.invoice_amount)}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent={{ xs: "space-between", md: "center" }}
          width={{ md: "auto", xs: "100%" }}
          flexDirection={{ md: "column", xs: "row" }}
        >
          <Typography
            variant="body2"
            fontWeight="bold"
            fontSize={{ xs: 10, md: 16 }}
          >
            {t("STATUS")}
          </Typography>
          <StatusLabel
            sx={{ mx: { xs: "0", md: "auto" } }}
            value={invoice?.status || ""}
          />
        </Box>
      </Box>
      <Divider />
      <Grid container my={2}>
        <Grid
          container
          item
          xs={12}
          bgcolor="#FAFAFA"
          border="1px solid #DDDDDD"
          borderRadius="15px"
          p={2}
        >
          <Grid item xs={12} sm={6} md={3}>
            <Typography fontWeight={600} fontSize={15}>
              {t("lowerCaseCustomerName")}
            </Typography>
            <Typography mt={1} fontSize={13}>
              {invoice.customer_name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography fontWeight={600} fontSize={15}>
              {t("lowerCaseEmail")}
            </Typography>
            <Typography mt={1} fontSize={13}>
              {invoice.customer_email}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography fontWeight={600} fontSize={15}>
              {t("coin")}
            </Typography>
            <Typography mt={1} fontSize={13}>
              {invoice.currency ? invoice.currency.name : "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography fontWeight={600} fontSize={15}>
              Status
            </Typography>
            <Typography mt={1} fontSize={13}>
              {invoice.status
                ? statusLabel[invoice.status]
                : invoice.ispaid
                ? t("statusPaid")
                : !invoice.isvalid
                ? t("statusCanceled")
                : t("statusPending")}
            </Typography>
          </Grid>

          <Grid item xs={12} my={2}>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography fontWeight={600} fontSize={15}>
              {t("invoiceCode")}
            </Typography>
            <Tooltip title={invoice.merchant_invoice_id} placement="bottom">
              <Grid container width={{ xs: "40", md: "90%" }}>
                <Typography noWrap={true} mt={1} fontSize={13}>
                  {invoice.merchant_invoice_id}
                </Typography>
              </Grid>
            </Tooltip>{" "}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography fontWeight={600} fontSize={15}>
              {t("lowerCaseCustomerDocument")}
            </Typography>
            <Typography mt={1} fontSize={15}>
              {invoice.documentno ? invoice.documentno : t("noDocumentNo")}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography fontWeight={600} fontSize={15}>
              {t("invoiceValidTo")}
            </Typography>
            <Typography mt={1} fontSize={15}>
              {format(
                new Date(invoice.validto),
                `${t("dateFormat")} ${t("hourFormat")}`
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography fontWeight={600} fontSize={15}>
              {t("checkoutUrl")}
            </Typography>
            <Grid container alignItems="center" justifyContent="space-between">
              <Typography
                noWrap={true}
                mt={1}
                fontSize={13}
                width={{ xs: "40%", md: "80%" }}
                maxWidth={400}
              >
                {invoice.checkout_url}
              </Typography>
              <Tooltip title={t("copy")} placement="bottom">
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(invoice.checkout_url);
                    AlertMessage("success", "messageCopy");
                  }}
                >
                  <ContentCopy />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={12}>
        <Grid container py={2}>
          {invoice?.items?.length ? (
            <Typography fontSize={15} fontWeight={600}>
              {t("itemsDescription")}
            </Typography>
          ) : (
            <Typography fontSize={15} fontWeight={600}>
              {t("noInvoiceItems")}
            </Typography>
          )}
        </Grid>
        {!!invoice?.items?.length ? (
          <ListComponent
            cells={cells}
            rows={invoice?.items.map((i) => ({
              ...i,
              currencyIsoCode: invoice.invoice_currency?.iso_code
            }))}
            dynamicId="id"
            initialOrder={{
              orderBy: "id",
              direction: "asc"
            }}
            noDataText="noInvoiceData"
          />
        ) : (
          <Grid pl={2} mt={2}>
            <Typography variant="body2">{t("zeroInvoiceItems")}</Typography>
          </Grid>
        )}
      </Grid>
    </Collapse>
  );
};

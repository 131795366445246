/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  Modal,
  Typography,
  OutlinedInput
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { BigNumber } from "@0x/utils";
import axios from "axios";
import { format } from "date-fns";
import Web3 from "web3";
import { AbiItem } from "web3-utils";

import {
  abi,
  coinContract,
  kahshPayContract,
  urlFastbyLogin,
  urlFastbyPix,
  urlFastbyRegister,
  urlInvoicePay,
  urlPay
} from "../../../services/global";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import FoxIcon from "../../../images/FoxIcon.png";
import ExchangeIcon from "../../../images/KahshExchange.png";
import KahshLogo from "../../../images/KahshLogo.png";
import PixLogo from "../../../images/pix.png";
import KahshLogoGroup from "../../../images/KahshLogoGroup.png";
import SuccessfullRegister from "../../../images/quick-register-account-created.png";
import ShareImg from "../../../images/shareLink.png";
import QuickRegisterCheckout from "../../../images/quick-register-checkout.png";

import LogoKahshPay from "../../../images/logos/LogoKahshPay.svg";
import logoAccounts from "../../../images/logos/logoAccounts.png";
import LogoExchange from "../../../images/logos/LogoExchange.png";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CircularProgress from "@mui/material/CircularProgress";
import QrCode from "qrcode.react";
import CheckoutLocker from "../../../images/checkout-locker.png";
import WalletIcon from "../../../images/WalletIcon.png";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AlertComponent from "../../../components/alertComponent/AlertComponent";
import checkoutServices from "../../../services/checkout.services";
import { cpfValidator } from "../../../utils/cpfValidator";

import "./CheckoutKahshPay.styles.css";

interface IPaymentMethods {
  id: number;
  img: any;
  title: string;
  body: string;
  alt: string;
  onClick: Function;
}

interface IAccordionItems {
  name: string;
  qty: number;
  total_item: string;
}

interface IInvoiceDetails {
  id: number;
  content: string;
  value: string;
  accordion?: boolean;
}

interface IPaymentMethodActivator {
  buyKahsh: boolean;
  metamask: boolean;
  exchange: boolean;
  qrCode: boolean;
}

interface IUser {
  email: string;
  password: string;
  twofa: string;
}

interface IRegisterInfo {
  fullName: string;
  document: string;
  email: string;
  password: string;
  confirmPassword: string;
}

interface IModal {
  account: boolean;
  twofa: boolean;
}
type AlertColor = "success" | "info" | "warning" | "error";

interface IAlert {
  severity: AlertColor;
  message: string;
}

interface IInvoice {
  id: string;
  created: Date;
  customer_email: string;
  customer_name: string;
  wallet_address: string;
  validto: string;
  return_url: string;
  amount: string;
  netamount: string;
  invoice_amount: string;
  merchant_id: string;
  merchant_invoice_id: string;
  currency_code: string;
  precision: number;
  fee?: string;
  isprocessing: boolean;
  quotation: string;
  items?: Array<any>;
  items_total_value: number;
  status: string;
}

interface IFastbuy {
  open: boolean;
  qrCodeAddress: string;
}

interface IError {
  twofa: boolean;
  fullname: boolean;
  document: boolean;
  email: boolean;
}

interface ITwoFaInputs {
  input1: string;
  input2: string;
  input3: string;
  input4: string;
  input5: string;
  input6: string;
}

interface IAuthMethod {
  exchange: boolean;
  accounts: boolean;
}

const CheckoutKahshPay = () => {
  const initialRef: any = useRef();
  const inputRef: any = useRef();
  const inputRef2: any = useRef();
  const inputRef3: any = useRef();
  const inputRef4: any = useRef();
  const inputRef5: any = useRef();

  const [twoFaInputs, setTwoFaInputs] = useState<ITwoFaInputs>({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
    input5: "",
    input6: ""
  });
  const [authMethod, setAuthMethod] = useState<IAuthMethod>({
    exchange: true,
    accounts: false
  });

  const { t, i18n } = useTranslation();
  const [invoice, setInvoice] = useState<IInvoice>({
    id: "",
    created: new Date(),
    customer_email: "",
    customer_name: "",
    wallet_address: "",
    validto: "",
    return_url: "",
    amount: "",
    netamount: "",
    invoice_amount: "",
    merchant_id: "",
    merchant_invoice_id: "",
    currency_code: "",
    precision: 0,
    fee: "",
    isprocessing: false,
    quotation: "",
    items: [],
    items_total_value: 0,
    status: ""
  });
  const [paymentMethod, setPaymentMethod] = useState<IPaymentMethodActivator>({
    buyKahsh: false,
    metamask: false,
    exchange: false,
    qrCode: false
  });
  const [userInfo, setUserInfo] = useState<IUser>({
    email: "",
    password: "",
    twofa: ""
  });
  const [isAccordion, setIsAccordion] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");
  const [register, setRegister] = useState<IRegisterInfo>({
    fullName: "",
    document: "",
    email: "",
    password: "",
    confirmPassword: ""
  });
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleConfirmPassword, setVisibleConfirmPassword] =
    useState<boolean>(false);
  const [fastBuyRegister, setFastBuyRegister] = useState<boolean>(false);
  const [modal, setModal] = useState<IModal>({
    account: false,
    twofa: false
  });
  const [open, setOpen] = useState<boolean>(false);
  const [alert, setAlert] = useState<IAlert>({
    message: "",
    severity: "success"
  });

  const [error, setError] = useState<IError>({
    twofa: false,
    fullname: true,
    document: true,
    email: true
  });

  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [passwordMatchError, setPasswordMatchError] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const [urlInvoice] = useState<string | null>(
    new URLSearchParams(window.location.search).get("invoice")
  );

  const [fastbuy, setFastBuy] = useState<IFastbuy>({
    open: false,
    qrCodeAddress: ""
  });

  const [show, setShow] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);

  const currencyFormatter = (value: any) => {
    if (typeof value !== "number") value = 0.0;

    const options = {
      thousandsSeparator: i18n.language.substring(0, 2) === "pt" ? "." : ",",
      decimalSeparator: i18n.language.substring(0, 2) === "pt" ? "," : "."
    };
    value = value.toFixed(2);

    const [currency, decimal] = value.split(".");
    return `${currency.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      options.thousandsSeparator
    )}${options.decimalSeparator}${decimal}`;
  };

  const callPaymentMethod: Array<IPaymentMethods> = [
    {
      id: 0,
      img: PixLogo, 
      // img: KahshLogoGroup,
      title: "stillNotKahsh",
      body: "buyHereNow",
      alt: "Kahsh Logo",
      onClick: () => {
        setPaymentMethod({
          buyKahsh: true,
          metamask: false,
          exchange: false,
          qrCode: false
        });
      }
    },
    // {
    //   id: 1,
    //   img: FoxIcon,
    //   title: "hasKahsh",
    //   body: "payWithMetamask",
    //   alt: "Metamask Fox icon",
    //   onClick: () => {
    //     setPaymentMethod({
    //       buyKahsh: false,
    //       metamask: true,
    //       exchange: false,
    //       qrCode: false
    //     });
    //   }
    // },
    {
      id: 2,
      img: ExchangeIcon,
      title: "payWithWallet",
      body: "haveYouBoughtFromExchange",
      alt: "Kahsh Exchange Logo",
      onClick: () => {
        setPaymentMethod({
          buyKahsh: false,
          metamask: false,
          exchange: true,
          qrCode: false
        });
      }
    },
    {
      id: 3,
      img: ShareImg,
      title: "quickPaymentLink",
      body: t("clickToCopy"),
      alt: "Copy image",
      onClick: () => {
        navigator.clipboard.writeText(window.location.href);
        setOpen(true);
        setAlert({
          message: "messageCopy",
          severity: "success"
        });
      }
    }
  ];

  const handleCopyEvent = async (e: KeyboardEvent) => {
    let ctrlDown = e.ctrlKey || e.metaKey;
    if (ctrlDown && e.key === "v") {
      let copy = await navigator.clipboard.readText();
      let obj: ITwoFaInputs = {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: ""
      };
      for (let i = 0; i <= 5; i++) {
        obj[`input${i + 1}`] = copy[i];
      }
      setTwoFaInputs(obj);
    }
  };

  const consultInvoice = async () => {
    let response: any = await checkoutServices.getMyCheckout(urlInvoice);
    setInvoice({
      id: response.invoice.id,
      created: response.invoice.created,
      customer_email: response.invoice.customer_email,
      customer_name: response.invoice.customer_name,
      wallet_address: response.invoice.wallet_address,
      validto: response.invoice.validto,
      return_url: response.invoice.return_url,
      amount: response.invoice.amount,
      netamount: response.invoice.netamount,
      invoice_amount: response.invoice.invoice_amount,
      merchant_id: response.invoice.merchant_id,
      merchant_invoice_id: response.invoice.merchant_invoice_id,
      currency_code: response.invoice.currency.iso_code,
      precision: response.invoice.currency.precision,
      fee: response.invoice.fee_amount,
      isprocessing: response.invoice.isprocessing,
      quotation: response.invoice.quotation,
      items: response.invoice?.items,
      items_total_value: 10,
      status: response.invoice.status
    });
    setIsAccordion(response.invoice?.items?.length > 0 ? true : false);
    setShow(true);
  };

  const InvoiceDetails: Array<IInvoiceDetails> = [
    {
      id: 0,
      content: "totalItems",
      value: `R$ ${currencyFormatter(Number(invoice.invoice_amount))}`,
      accordion: isAccordion
    },
    {
      id: 1,
      content: "Total",
      value: `${currencyFormatter(Number(invoice.amount))} KSH`
    },
    {
      id: 2,
      content: "feesPaymentLowerCase",
      value: `${currencyFormatter(Number(invoice.fee))} KSH`
    },
    {
      id: 3,
      content: "subTotalPaymentLowerCase",
      value: `${currencyFormatter(Number(invoice.netamount))} KSH`
    },
    {
      id: 4,
      content: "exchangeRate",
      value: `1 KSH = ${currencyFormatter(Number(invoice.quotation))} BRL`
    }
  ];

  const clipboardCopy = (pix: boolean | null) => {
    let copy = invoice.wallet_address;
    if (pix) copy = fastbuy.qrCodeAddress;
    setOpen(true);
    setAlert({
      message: "messageCopy",
      severity: "success"
    });
    navigator.clipboard.writeText(copy);
  };

  const handleLoginFastBuy = async (token?: string | null) => {
    if (modal.twofa) {
      if (
        loading ||
        Object.values(twoFaInputs).join().replaceAll(",", "").length < 6
          ? true
          : false
      )
        return;
    }
    setLoading(true);
    try {
      const loginFastBuy: any = await axios.post(urlFastbyLogin, {
        email: userInfo.email,
        password: userInfo.password,
        code2fa: Object.values(twoFaInputs).join().replaceAll(",", ""),
        isAccounts: authMethod.accounts ? true : false
      });
      if (loginFastBuy?.data?.token) {
        setToken(loginFastBuy.data.token);
        const loginExchangeWithToken: any = await axios.post(urlFastbyPix, {
          invoiceCode: invoice.merchant_invoice_id,
          tokenExchange: loginFastBuy.data.token || token
        });
        if (loginExchangeWithToken) {
          if (loginExchangeWithToken?.data?.pix[0]?.qrcode == null) {
            setOpen(true);
            setAlert({ severity: "error", message: t("generatePixError") });

            setLoading(false);
            return;
          }
          setFastBuy({
            open: true,
            qrCodeAddress: loginExchangeWithToken?.data?.pix[0]?.qrcode
              ? loginExchangeWithToken?.data?.pix[0]?.qrcode
              : ""
          });
        }

        setModal({ ...modal, twofa: false });
        setLoading(false);
        setTwoFaInputs({
          input1: "",
          input2: "",
          input3: "",
          input4: "",
          input5: "",
          input6: ""
        });
        

        return;
      }

      setLoading(false);
      setModal({ ...modal, twofa: false });
      setTwoFaInputs({
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: ""
      });
      setPaymentMethod({ ...paymentMethod, qrCode: true });
    } catch (error: any) {
      if (error?.response?.data?.errorCode == 25) {
        setModal({ ...modal, twofa: true });
        setLoading(false);

        return;
      }
      if (error?.response?.data?.errorCode == 26) {
        setModal({ ...modal, twofa: false });
        setLoading(false);
        setPaymentMethod({ ...paymentMethod, buyKahsh: false });
        setFastBuyRegister(true);
        setAlert({ message: t("incompleteRegister"), severity: "error" });

        return;
      }
      setAlert({
        message: t(
          `${
            error?.response?.data?.message == "User not found."
              ? "error400"
              : error?.response?.data?.message
          }`
        ),
        severity: "error"
      });

      if (error?.response?.data?.solicitar2fa) {
        setModal({ ...modal, twofa: true });
        setLoading(false);

        return;
      }
    }
    setLoading(false);
  };

  const handleRegisterFastBuy = async () => {
    setLoading(true);
    try {
      const registerCallBack: any = await axios.post(urlFastbyRegister, {
        fullName: register.fullName,
        cpfcnpj: register.document.replaceAll(".", "").replaceAll("-", ""),
        email: register.email,
        password: register.password
      });

      if (registerCallBack?.data?.token) {
        setUserInfo({
          ...userInfo,
          email: register.email,
          password: register.password
        });
        setLoading(false);
        setModal({ ...modal, account: true });
        setToken(registerCallBack?.data?.token);
        return;
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message ==
        "Já existe um usuário usando este CPF/CNPJ."
      ) {
        setLoading(false);
        setOpen(true);
        setAlert({
          message: t("documentEqualsError"),
          severity: "error"
        });
        return;
      }
      setLoading(false);
      setOpen(true);
      setAlert({
        message: t(`${error?.response?.data?.message}`),
        severity: "error"
      });
      return;
    }

    setLoading(false);
    setOpen(true);
    setAlert({
      message: t("registrationSccessful"),
      severity: "success"
    });
  };

  const handlePixRedirect = async () => {
    setModal({ account: false, twofa: false });
    setFastBuyRegister(false);
    setLoading(false);
    setTwoFaInputs({
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      input5: "",
      input6: ""
    });

    await handleLoginFastBuy(token);
    return;
  };

  async function handleMetamask() {
    if (window.ethereum) {
      window.ethereum
        .enable()
        .then((accounts: any) => {
          var d = new Date(invoice.validto);
          var millisecondssince1970 = d.getTime();
          const hourNow = millisecondssince1970;

          var userAccount = Web3.utils.toChecksumAddress(accounts[0]);
          var finalAmount: any = new BigNumber(
            Number(invoice.netamount) * 10 ** 18
          ).toString();

          const web3 = new Web3(window.ethereum);
          const FW = new web3.eth.Contract(abi as AbiItem[], coinContract); //Open Contract with coinContract

          FW.methods
            .permitHash(userAccount, kahshPayContract, finalAmount, hourNow)
            .call(function (error: any, resPermitHash: any) {
              if (!error) {
                web3.eth
                  .sign(resPermitHash, userAccount)
                  .then((resSign: any) => {
                    //Sign Contract
                    axios
                      .post(urlPay, {
                        customer: userAccount,
                        recipient: Web3.utils.toChecksumAddress(
                          invoice.wallet_address
                        ),
                        merchant_id: invoice.merchant_id,
                        invoice_id: invoice.id,
                        amount: finalAmount,
                        deadline: hourNow,
                        signature: resSign
                      })
                      .then((response: any) => {
                        // console.log(response)
                      });
                  })
                  .catch((error: any) => {
                    if (
                      error.message ===
                      "MetaMask Message Signature: User denied message signature."
                    ) {
                      setOpen(true);
                      setAlert({ severity: "error", message: "metaMaskDeny" });
                    }
                  });
              } else {
                if (
                  error.message ===
                  "Returned values aren't valid, did it run Out of Gas? You might also see this error if you are not using the correct ABI for the contract you are retrieving data from, requesting data from a block number that does not exist, or querying a node which is not fully synced."
                ) {
                  setOpen(true);
                  setAlert({ severity: "error", message: "gasError" });
                }
              }
            });
        })
        .catch((error: any) => {
          if (
            error.message ===
            `invalid BigNumber string (argument="value", value="Infinity", code=INVALID_ARGUMENT, version=bignumber/5.6.0)`
          ) {
            setOpen(true);
            setAlert({ severity: "error", message: "metaMaskError" });
          }
        });
    } else {
      // METAMASK NOT INSTALED
      setOpen(true);
      setAlert({ severity: "error", message: "metaMaskNotInstalled" });
    }
  }

  const handleExchangeWallet = async () => {
    if (modal.twofa) {
      if (
        loading ||
        Object.values(twoFaInputs).join().replaceAll(",", "").length < 6
          ? true
          : false
      )
        return;
    }
    setLoading(true);
    try {
      await axios.post(urlInvoicePay, {
        username: userInfo.email,
        password: userInfo.password,
        code2FA: Object.values(twoFaInputs).join().replaceAll(",", ""),
        invoiceCode: urlInvoice,
        isAccounts: authMethod.accounts ? true : false
      });
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      let response =
        error?.response?.data?.msg ||
        error?.response?.data?.message ||
        error?.response?.data?.mensagem;

      if (error?.response?.data.errorCode == 25) {
        setModal({ ...modal, twofa: true });
        setLoading(false);

        return;
      }
      if (response) {
        setOpen(true);
        setAlert({ severity: "error", message: response });

        setLoading(false);

        return;
      }

      if (error?.response?.data?.msg === "Saldo insuficiente  na exchange.") {
        setOpen(true);
        setAlert({ severity: "error", message: "notEnoughCoins" });

        setLoading(false);

        return;
      }
      if (error?.response?.data?.message === "User not found.") {
        setOpen(true);
        setAlert({ severity: "error", message: "error400" });

        setLoading(false);

        return;
      }

      if (error?.response?.data?.msg == "Saldo não encontrado.") {
        setOpen(true);
        setAlert({ severity: "error", message: t("notEnoughCoins") });

        setLoading(false);

        return;
      }
      if (error?.response?.data?.message == "2fa informado é inválido") {
        setError({ ...error, twofa: true });
        setLoading(false);

        return;
      }
    }
  };

  useEffect(() => {
    if (authMethod.exchange) {
      document.getElementById("auth1")?.classList.add("authentication");
      document.getElementById("auth2")?.classList.remove("authentication");
    }
    if (authMethod.accounts) {
      document.getElementById("auth1")?.classList.remove("authentication");
      document.getElementById("auth2")?.classList.add("authentication");
    }
  }, [authMethod, paymentMethod]);

  useEffect(() => {
    consultInvoice();
    const invoiceInterval = setInterval(() => {
      consultInvoice();
    }, 15000);
    return () => clearInterval(invoiceInterval);
  }, []);

  useEffect(() => {
    setError({ ...error, twofa: false });
  }, [twoFaInputs]);

  useEffect(() => {
    setTimeout(() => {
      setOpen(false);
    }, 5000);
  }, [alert]);

  useEffect(() => {
    if (register.fullName?.split(" ").length < 2)
      return setError({ ...error, fullname: true });
    setError({ ...error, fullname: false });
  }, [register.fullName]);

  useEffect(() => {
    if (!cpfValidator(register.document))
      return setError({ ...error, document: true });
    setError({ ...error, document: false });
  }, [register.document]);

  useEffect(() => {
    if (
      register.email?.split("@")[1]?.split(".").length < 2 ||
      !register.email.includes("@")
    )
      return setError({ ...error, email: true });
    setError({ ...error, email: false });
  }, [register.email]);

  useEffect(() => {
    if (
      !register.password.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#]{8,}$/
      )
    ) {
      setPasswordError(true);
      return;
    }
    setPasswordError(false);
  }, [register.password]);

  useEffect(() => {
    if (register.password != register.confirmPassword) {
      setPasswordMatchError(true);
      return;
    }
    setPasswordMatchError(false);
  }, [register]);

  function handleOnKeyDown(e) {
    if (!checked || userInfo.email.length < 0 || userInfo.password.length < 0) {
      return;
    }
    if (e.key === "Enter" && !loading) return handleLoginFastBuy();
  }

  function handleOnKeyDownRegister(e) {
    if (
      !checked ||
      register.document.length < 0 ||
      register.fullName.length < 0 ||
      register.email.length < 0 ||
      register.confirmPassword.length < 0 ||
      register.password.length < 0
    )
      return;

    if (e.key === "Enter" && !loading) return handleRegisterFastBuy();
  }

  const createData = (item: string, qty: number, value: string) => {
    return { item, qty, value };
  };

  const rows = invoice?.items?.map((item: IAccordionItems) => {
    return createData(item.name, item.qty, item.total_item);
  });

  return (
    <Grid container>
      {show ? (
        <>
          {invoice.status == "CA" || invoice.status == "PA" ? (
            <>
              <Grid container justifyContent="center" pt={15} pb={3}>
                <Grid container xs={10}>
                  <Typography
                    display="flex"
                    alignItems="center"
                    fontWeight={600}
                    color={invoice.status == "PA" ? "#4733FF" : "#FF0000"}
                  >
                    {invoice.status == "PA" ? (
                      <>
                        <CheckCircleOutlineIcon
                          sx={{ fontSize: 40, marginRight: 2 }}
                        />
                        {t("paymentSuccess")}
                      </>
                    ) : (
                      <>
                        <HighlightOffIcon
                          sx={{ fontSize: 40, marginRight: 2 }}
                        />
                        {t("paymentCanceled")}
                      </>
                    )}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item md={12} justifyContent="center">
                <Grid
                  item
                  xs={11}
                  md={10}
                  container
                  borderRadius={10}
                  bgcolor="#FAFAFA"
                  border="1px solid #DDDDDD"
                  p={{ xs: 2, md: 4 }}
                >
                  <Grid
                    container
                    item
                    md={7}
                    py={5}
                    border="1px solid #DDDDDD"
                    borderRadius={10}
                    bgcolor="#ffffff"
                    mb={{ xs: 4, md: 0 }}
                  >
                    <Grid container px={{ xs: 2, md: 8 }}>
                      <Grid container>
                        <Typography
                          ml={2}
                          mb={1}
                          variant="body2"
                          fontWeight={600}
                        >
                          {t("invoiceID")} *
                        </Typography>

                        <Grid
                          p={2}
                          container
                          borderRadius={4}
                          border="1px solid #B4B4C1"
                        >
                          <Typography
                            variant="body2"
                            fontWeight={600}
                            color={
                              invoice.status == "PA" ? "#4733FF" : "#FF0000"
                            }
                          >
                            {invoice.id}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container px={{ xs: 2, md: 8 }} mt={3}>
                      <Grid container>
                        <Typography
                          ml={2}
                          mb={1}
                          variant="body2"
                          fontWeight={600}
                        >
                          {t("amount")} *
                        </Typography>
                        <Grid
                          p={2}
                          container
                          borderRadius={4}
                          border="1px solid #B4B4C1"
                        >
                          <Typography
                            variant="body2"
                            fontWeight={600}
                            color={
                              invoice.status == "PA" ? "#4733FF" : "#FF0000"
                            }
                          >
                            {invoice.invoice_amount}
                          </Typography>
                        </Grid>

                        {invoice.return_url ? (
                          <Grid
                            container
                            item
                            justifyContent={{ xs: "center", md: "flex-end" }}
                            mt={3}
                          >
                            <Button
                              className="checkout-button-filled"
                              fullWidth
                              sx={{ maxWidth: "300px" }}
                              onClick={() =>
                                (window.location.href = `${invoice.return_url}`)
                              }
                            >
                              {t("backToStore")}
                            </Button>
                          </Grid>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    md={5}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid width="100%" textAlign="center">
                      <Typography variant="body2">
                        {t("invoicePaidFor")}
                      </Typography>

                      <Typography mt={2} variant="body2" fontWeight={600}>
                        {invoice.customer_email}
                      </Typography>

                      <Grid
                        py={1}
                        display="flex"
                        alignItems="baseline"
                        justifyContent="center"
                      >
                        <Typography
                          fontWeight={600}
                          fontSize="45px"
                          color="#1A0E38"
                        >
                          {currencyFormatter(Number(invoice.netamount))}
                        </Typography>

                        <Typography
                          fontSize="30px"
                          fontWeight={600}
                          color="#ABA9B0"
                        >
                          KSH
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography variant="body2">
                          {t("ordered").toUpperCase()}
                        </Typography>

                        <Typography>
                          {format(
                            new Date(invoice.created),
                            `${t("dateFormat")} ${t("hourFormat")}`
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : invoice.isprocessing ? (
            <>
              <Grid
                container
                item
                md={12}
                justifyContent="center"
                alignItems="center"
                direction="column"
                pt={15}
              >
                <CircularProgress size={80} sx={{ color: "gray" }} />
                <Typography
                  mt={3}
                  variant="body1"
                  color="#928D8D"
                  textAlign="center"
                >
                  {t("invoiceProcessing")}...
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <AlertComponent
                open={open}
                severity={alert.severity}
                message={t(alert.message)}
                onClick={() => {
                  setOpen(false);
                }}
              />

              {fastBuyRegister ? (
                <Grid container item md={12} justifyContent="space-around">
                  <Modal
                    open={modal.account}
                    onClose={() => setModal({ account: false, twofa: false })}
                  >
                    <Grid className="checkout-modal" p={4}>
                      <img
                        width={80}
                        src={SuccessfullRegister}
                        alt="Green circle with a successfull sign in it"
                      />
                      <Typography
                        mt={2}
                        color="#1A0E38"
                        fontSize={18}
                        fontWeight={700}
                      >
                        {t("successfullCreateAccount").toUpperCase()}
                      </Typography>
                      <Typography mb={2} textAlign="center">
                        {t("successfullCreateAccountBody")}
                      </Typography>
                      <Button
                        sx={{ width: "60%" }}
                        className="checkout-button-filled"
                        onClick={() => handlePixRedirect()}
                      >
                        OK
                      </Button>
                    </Grid>
                  </Modal>

                  <Grid
                    container
                    item
                    md={6}
                    lg={5}
                    px={{ xs: 4, md: 10 }}
                    py={{ xs: 5, md: 10 }}
                  >
                    <Grid container mb={6}>
                      <img
                        src={LogoExchange}
                        alt="Logo Exchange"
                        width={120}
                        height={40}
                      />
                    </Grid>

                    <Grid container gap={0.8}>
                      <OutlinedInput
                        size="small"
                        autoFocus
                        className="input-none-border"
                        fullWidth
                        placeholder={t("fullName")}
                        onKeyDown={(e) => {
                          handleOnKeyDownRegister(e);
                        }}
                        onChange={(e) => {
                          setRegister({
                            ...register,
                            fullName: e.target.value
                          });
                        }}
                        value={register.fullName}
                      />
                      {error.fullname && register.fullName.length > 0 ? (
                        <Typography color="red" fontSize={12}>
                          {t("nameError")}
                        </Typography>
                      ) : null}

                      <OutlinedInput
                        size="small"
                        className="input-none-border"
                        fullWidth
                        placeholder={"CPF"}
                        onKeyDown={(e) => {
                          handleOnKeyDownRegister(e);
                        }}
                        onChange={(e) => {
                          setRegister({
                            ...register,
                            document: e.target.value
                          });
                        }}
                        value={register.document}
                      />
                      {error.document && register.document.length > 0 ? (
                        <Typography color="red" fontSize={12}>
                          {t("documentError")}
                        </Typography>
                      ) : null}

                      <OutlinedInput
                        size="small"
                        className="input-none-border"
                        fullWidth
                        placeholder={t("Email")}
                        onKeyDown={(e) => {
                          handleOnKeyDownRegister(e);
                        }}
                        onChange={(e) => {
                          setRegister({
                            ...register,
                            email: e.target.value
                          });
                        }}
                        value={register.email}
                      />
                      {error.email && register.email.length > 0 ? (
                        <Typography color="red" fontSize={12}>
                          {t("emailInvalid")}
                        </Typography>
                      ) : null}

                      <OutlinedInput
                        size="small"
                        className="input-none-border"
                        fullWidth
                        placeholder={t("password")}
                        type={visible ? "text" : "password"}
                        onKeyDown={(e) => {
                          handleOnKeyDownRegister(e);
                        }}
                        onChange={(e) => {
                          setRegister({
                            ...register,
                            password: e.target.value
                          });
                        }}
                        value={register.password}
                        endAdornment={
                          <InputAdornment
                            position="end"
                            onClick={() => setVisible(!visible)}
                          >
                            {visible ? (
                              <VisibilityIcon cursor="pointer" />
                            ) : (
                              <VisibilityOffIcon cursor="pointer" />
                            )}
                          </InputAdornment>
                        }
                      />
                      {passwordError && register.password.length > 0 ? (
                        <Typography color="red" fontSize={12}>
                          {t("isNotStrongPassword")}
                        </Typography>
                      ) : null}

                      <OutlinedInput
                        size="small"
                        className="input-none-border"
                        fullWidth
                        placeholder={t("confirmPassword")}
                        type={visibleConfirmPassword ? "text" : "password"}
                        onKeyDown={(e) => {
                          handleOnKeyDownRegister(e);
                        }}
                        onChange={(e) => {
                          setRegister({
                            ...register,
                            confirmPassword: e.target.value
                          });
                        }}
                        value={register.confirmPassword}
                        endAdornment={
                          <InputAdornment
                            position="end"
                            onClick={() =>
                              setVisibleConfirmPassword(!visibleConfirmPassword)
                            }
                          >
                            {visibleConfirmPassword ? (
                              <VisibilityIcon cursor="pointer" />
                            ) : (
                              <VisibilityOffIcon cursor="pointer" />
                            )}
                          </InputAdornment>
                        }
                      />
                      {passwordMatchError ? (
                        <Typography color="red" fontSize={12}>
                          {t("passwordEqualsError")}
                        </Typography>
                      ) : null}
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="flex-start"
                        mb={2}
                      >
                        <Grid display="flex" alignItems="center">
                          <Checkbox
                            size="small"
                            checked={checked}
                            onChange={() => setChecked(!checked)}
                          />
                          <Typography variant="caption">
                            {t("PrivacyTerms1")}
                            <a
                              href="https://exchange.kahsh.com/termosdeuso"
                              target="_blank"
                              rel="noreferrer"
                              tabIndex={-1}
                            >
                              {t("PrivacyTerms2")}
                            </a>
                            {t("and")}
                            <a
                              href="https://exchange.kahsh.com/politicadeprivacidade"
                              target="_blank"
                              rel="noreferrer"
                              tabIndex={-1}
                            >
                              {t("PrivacyTerms3")}
                            </a>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Button
                        className={`checkout-button-filled ${
                          Object.values(error).includes(true) ||
                          passwordError ||
                          passwordMatchError ||
                          !checked ||
                          loading
                            ? "loading"
                            : ""
                        }`}
                        fullWidth
                        disabled={
                          Object.values(error).includes(true) ||
                          passwordError ||
                          passwordMatchError ||
                          !checked ||
                          loading
                        }
                        onClick={() => handleRegisterFastBuy()}
                      >
                        {loading ? (
                          <CircularProgress size={24} />
                        ) : (
                          t("register")
                        )}
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid item md={6} px={4} py={{ xs: 2, md: 10 }}>
                    <Grid mb={4}>
                      <Typography variant="h3" fontWeight={600}>
                        {t("fastRegister")}
                      </Typography>
                    </Grid>

                    <Grid>
                      <Typography variant="h5">
                        {t("fastRegisterBody")}
                      </Typography>
                    </Grid>

                    <Grid
                      mt={3}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <img
                        src={QuickRegisterCheckout}
                        style={{ width: "60%", maxWidth: "500px" }}
                        alt="imagem"
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    md={10}
                    mt={{ xs: 20, sm: 12, md: -3 }}
                    justifyContent={{ xs: "center", lg: "flex-start" }}
                  >
                    <Button
                      className="checkout-button-filled"
                      fullWidth
                      sx={{ maxWidth: { xs: "50%", md: "300px" } }}
                      onClick={() => {
                        setFastBuyRegister(false);
                      }}
                    >
                      {t("back")}
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Grid container justifyContent="center" pt={8}>
                    <img src={LogoKahshPay} alt="Logo KahshPay" />
                  </Grid>

                  <Grid container justifyContent="center" mt={2}>
                    <Typography textAlign="center" variant="h5">
                      {t("requestPayment")}
                    </Typography>
                  </Grid>

                  <Grid container justifyContent="center">
                    {!paymentMethod.buyKahsh &&
                    !paymentMethod.exchange &&
                    !paymentMethod.metamask ? (
                      <>
                        <Grid container item lg={4.5} md={4.5} p={2}>
                          <Grid container item md={12} mt={3} mb={2}>
                            <Typography
                              className="rounded-numbers inter"
                              variant="body1"
                            >
                              1
                            </Typography>
                            <Typography ml={3} variant="body1">
                              {t("howDoYou")}
                            </Typography>
                          </Grid>
                          {callPaymentMethod.map((pm) => {
                            return (
                              <Grid
                                key={pm.id}
                                container
                                p={2}
                                my={0.5}
                                className="payment-methods-container"
                                wrap="nowrap"
                                alignItems="center"
                                onClick={() => pm.onClick()}
                              >
                                <img src={pm.img} alt={pm.alt} width="55px" />
                                <Grid pl={3}>
                                  <Typography variant="body1">
                                    {t(`${pm.title}`)}
                                  </Typography>
                                  <Typography variant="body1" fontWeight={600}>
                                    {t(`${pm.body}`)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                          })}
                        </Grid>

                        <Grid container item lg={4.5} md={4.5} p={2}>
                          <Grid container mt={3} mb={2}>
                            <Typography
                              className="rounded-numbers inter"
                              variant="body1"
                            >
                              2
                            </Typography>
                            <Typography ml={3} variant="body1">
                              {t("invoiceDetailsLowerCase")}
                            </Typography>
                          </Grid>

                          {InvoiceDetails.map((invoice) => {
                            return invoice.accordion ? (
                              <Grid
                                container
                                key={invoice.id}
                                alignItems="center"
                                className="invoice-details-accordion-container"
                              >
                                <Accordion elevation={0}>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <Grid
                                      container
                                      justifyContent={"space-between"}
                                    >
                                      <Typography variant="body2">
                                        {t(`${invoice.content}`)}
                                      </Typography>
                                      <Typography variant="body2">
                                        {invoice.value}
                                      </Typography>
                                    </Grid>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <TableContainer>
                                      <Table size="small">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell
                                              sx={{ fontSize: "13px" }}
                                            >
                                              Item
                                            </TableCell>
                                            <TableCell
                                              align="right"
                                              sx={{ fontSize: "13px" }}
                                            >
                                              {t("qtyLowerCase")}
                                            </TableCell>
                                            <TableCell
                                              align="right"
                                              sx={{ fontSize: "13px" }}
                                            >
                                              {t("value")}
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {rows?.map((row, index) => (
                                            <TableRow
                                              key={index}
                                              sx={{
                                                "&:last-child td, &:last-child th":
                                                  { border: 0 }
                                              }}
                                            >
                                              <TableCell
                                                component="th"
                                                scope="row"
                                                sx={{ fontSize: "13px" }}
                                              >
                                                {row.item}
                                              </TableCell>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                                align="right"
                                                sx={{ fontSize: "13px" }}
                                              >
                                                {row.qty}
                                              </TableCell>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                                align="right"
                                                sx={{ fontSize: "13px" }}
                                              >
                                                {`R$ ${row.value}`}
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </AccordionDetails>
                                </Accordion>
                              </Grid>
                            ) : (
                              <Grid
                                key={invoice.id}
                                container
                                p={2}
                                px={3}
                                my={0.5}
                                justifyContent="space-between"
                                className="invoice-details-container"
                              >
                                <Typography variant="body2">
                                  {t(`${invoice.content}`)}
                                </Typography>
                                <Typography variant="body2">
                                  {invoice.value}
                                </Typography>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </>
                    ) : null}

                    <Grid
                      container
                      item
                      md={
                        paymentMethod.buyKahsh ||
                        paymentMethod.metamask ||
                        paymentMethod.exchange
                          ? 8
                          : 2
                      }
                      p={2}
                    >
                      <Grid
                        container
                        wrap="nowrap"
                        mt={3}
                        mb={2}
                        item
                        display={{ xs: "none", md: "flex" }}
                      >
                        <Typography
                          variant="body1"
                          className={`rounded-numbers inter ${
                            paymentMethod.buyKahsh ||
                            paymentMethod.metamask ||
                            paymentMethod.exchange
                              ? ""
                              : "background-gray"
                          }`}
                        >
                          3
                        </Typography>
                        <Typography ml={3} variant="body1">
                          {t(
                            paymentMethod.buyKahsh ? "stillNotKahsh" : "finish"
                          )}
                        </Typography>
                      </Grid>

                      {paymentMethod.buyKahsh ? (
                        <>
                          <Grid
                            container
                            bgcolor="#FAFAFA"
                            border="1px solid #DDDDDD"
                            direction="row-reverse"
                            borderRadius={5}
                            p={{ xs: 0, md: 1.5 }}
                            justifyContent="space-between"
                          >
                            <Modal
                              open={modal.twofa}
                              onClose={() => {
                                setModal({ account: false, twofa: false });
                                setTwoFaInputs({
                                  input1: "",
                                  input2: "",
                                  input3: "",
                                  input4: "",
                                  input5: "",
                                  input6: ""
                                });
                              }}
                            >
                              <Grid
                                p={4}
                                alignItems="center"
                                justifyContent="center"
                                className="checkout-modal"
                              >
                                <img
                                  width={80}
                                  src={CheckoutLocker}
                                  style={{ cursor: "text" }}
                                  alt="blue locker with a green message baloon"
                                />

                                <Typography
                                  pt={3}
                                  variant="body1"
                                  fontWeight={600}
                                >
                                  {t("twoFaAuth")}
                                </Typography>

                                <Typography
                                  mb={2}
                                  textAlign="center"
                                  variant="body1"
                                  fontWeight={300}
                                >
                                  {t("twoFaMessageCheckout")}
                                </Typography>

                                <Grid container wrap="nowrap">
                                  <OutlinedInput
                                    size="small"
                                    className="checkout-input-2fa"
                                    onKeyDown={(e: KeyboardEventHandler) => {
                                      if (
                                        e.key === "Enter" &&
                                        (!loading ||
                                          twoFaInputs.input1.length > 0)
                                      ) {
                                        handleLoginFastBuy();
                                        return;
                                      }
                                      handleCopyEvent(e);
                                    }}
                                    autoFocus
                                    value={twoFaInputs.input1}
                                    onChange={(e) => {
                                      if (twoFaInputs.input1.length === 0)
                                        inputRef.current.focus();
                                      setTwoFaInputs({
                                        ...twoFaInputs,
                                        input1: e.target.value
                                      });
                                    }}
                                    inputProps={{ maxLength: 1 }}
                                  />

                                  <OutlinedInput
                                    size="small"
                                    inputRef={inputRef}
                                    className="checkout-input-2fa"
                                    onKeyDown={(e: KeyboardEventHandler) => {
                                      if (e.key === "Enter" && !loading)
                                        return handleLoginFastBuy();

                                      handleCopyEvent(e);
                                    }}
                                    value={twoFaInputs.input2}
                                    onChange={(e) => {
                                      if (twoFaInputs.input2.length === 0)
                                        inputRef2.current.focus();
                                      setTwoFaInputs({
                                        ...twoFaInputs,
                                        input2: e.target.value
                                      });
                                    }}
                                    inputProps={{ maxLength: 1 }}
                                  />

                                  <OutlinedInput
                                    size="small"
                                    className="checkout-input-2fa"
                                    onKeyDown={(e: KeyboardEventHandler) => {
                                      if (e.key === "Enter" && !loading)
                                        return handleLoginFastBuy();

                                      handleCopyEvent(e);
                                    }}
                                    inputRef={inputRef2}
                                    value={twoFaInputs.input3}
                                    onChange={(e) => {
                                      if (twoFaInputs.input3.length === 0)
                                        inputRef3.current.focus();
                                      setTwoFaInputs({
                                        ...twoFaInputs,
                                        input3: e.target.value
                                      });
                                    }}
                                    inputProps={{ maxLength: 1 }}
                                  />

                                  <OutlinedInput
                                    size="small"
                                    className="checkout-input-2fa"
                                    onKeyDown={(e: KeyboardEventHandler) => {
                                      if (e.key === "Enter" && !loading)
                                        return handleLoginFastBuy();

                                      handleCopyEvent(e);
                                    }}
                                    inputRef={inputRef3}
                                    value={twoFaInputs.input4}
                                    onChange={(e) => {
                                      if (twoFaInputs.input4.length === 0)
                                        inputRef4.current.focus();
                                      setTwoFaInputs({
                                        ...twoFaInputs,
                                        input4: e.target.value
                                      });
                                    }}
                                    inputProps={{ maxLength: 1 }}
                                  />

                                  <OutlinedInput
                                    size="small"
                                    className="checkout-input-2fa"
                                    onKeyDown={(e: KeyboardEventHandler) => {
                                      if (e.key === "Enter" && !loading)
                                        return handleLoginFastBuy();

                                      handleCopyEvent(e);
                                    }}
                                    inputRef={inputRef4}
                                    value={twoFaInputs.input5}
                                    onChange={(e) => {
                                      if (twoFaInputs.input5.length === 0)
                                        inputRef5.current.focus();
                                      setTwoFaInputs({
                                        ...twoFaInputs,
                                        input5: e.target.value
                                      });
                                    }}
                                    inputProps={{ maxLength: 1 }}
                                  />

                                  <OutlinedInput
                                    size="small"
                                    className="checkout-input-2fa"
                                    onKeyDown={(e: KeyboardEventHandler) => {
                                      if (e.key === "Enter" && !loading)
                                        return handleLoginFastBuy();

                                      handleCopyEvent(e);
                                    }}
                                    inputRef={inputRef5}
                                    value={twoFaInputs.input6}
                                    onChange={(e) => {
                                      setTwoFaInputs({
                                        ...twoFaInputs,
                                        input6: e.target.value
                                      });
                                    }}
                                    inputProps={{ maxLength: 1 }}
                                  />
                                </Grid>

                                {error.twofa ? (
                                  <Grid
                                    container
                                    mt={2}
                                    justifyContent="center"
                                  >
                                    <Typography color="red">
                                      {t("twoFaErrorMessage")}
                                    </Typography>
                                  </Grid>
                                ) : null}

                                <Grid mt={2} container justifyContent="center">
                                  <Button
                                    onClick={() => handleLoginFastBuy()}
                                    sx={{ width: "60%" }}
                                    className={`checkout-button-filled ${
                                      loading ||
                                      Object.values(twoFaInputs)
                                        .join()
                                        .replaceAll(",", "").length < 6
                                        ? "loading"
                                        : ""
                                    }`}
                                    disabled={
                                      loading ||
                                      Object.values(twoFaInputs)
                                        .join()
                                        .replaceAll(",", "").length < 6
                                        ? true
                                        : false
                                    }
                                  >
                                    {loading ? (
                                      <CircularProgress size={25} />
                                    ) : (
                                      t("generatePix")
                                    )}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Modal>

                            <Grid
                              container
                              item
                              md={5}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid
                                height={"80%"}
                                width="90%"
                                textAlign={"center"}
                              >
                                <Typography variant="body1" fontWeight={600}>
                                  {t("valueInReais")}
                                </Typography>

                                <Grid
                                  my={2}
                                  container
                                  height="60%"
                                  bgcolor="#FFFFFF"
                                  border="1px solid #DDDDDD"
                                  borderRadius={5}
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <Typography
                                    fontWeight={300}
                                    fontSize="45px"
                                    color="#373737"
                                  >
                                    {`R$ ${currencyFormatter(
                                      Number(invoice.invoice_amount)
                                    )}`}
                                  </Typography>
                                </Grid>

                                <Grid item mb={{ xs: 13, md: 0 }}>
                                  <Typography variant="body1" fontWeight={600}>
                                    {t("paymentAvailableUtil")}
                                  </Typography>

                                  <Typography variant="body1" fontWeight={300}>
                                    {format(
                                      new Date(invoice.validto),
                                      i18n.language.substring(0, 2) == "PT"
                                        ? "dd-MM-yyyy"
                                        : "MM-dd-yyyy"
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            {fastbuy.open ? (
                              <>
                                <Grid
                                  container
                                  item
                                  py={2}
                                  md={5}
                                  borderRadius={5}
                                  border="2px solid #1A263E"
                                  alignItems="center"
                                >
                                  <Grid
                                    container
                                    justifyContent="center"
                                    mt={{ xs: 2, md: 0 }}
                                    item
                                  >
                                    <Typography
                                      display={"flex"}
                                      alignItems={"center"}
                                      fontSize={18}
                                    >
                                      <img
                                        src={KahshLogo}
                                        style={{
                                          marginRight: "5px",
                                          cursor: "text"
                                        }}
                                        width="20%"
                                        alt=""
                                      />
                                      kahsh
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    container
                                    justifyContent="center"
                                    my={2}
                                  >
                                    <Grid
                                      display="flex"
                                      minWidth={200}
                                      minHeight={200}
                                      borderRadius={5}
                                      justifyContent="center"
                                      my={{ xs: 2, md: 0 }}
                                      item
                                      alignItems="center"
                                      border="2px solid #1A263E"
                                    >
                                      <QrCode
                                        value={fastbuy.qrCodeAddress}
                                        size={160}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    container
                                    justifyContent="center"
                                    item
                                    mb={{ xs: 2, md: 0 }}
                                  >
                                    <Button
                                      onClick={() => clipboardCopy(true)}
                                      className="copy-content-button"
                                    >
                                      {t("copyAddressCheckout")}
                                      <ContentCopyIcon
                                        sx={{ marginLeft: "10px" }}
                                      />
                                    </Button>
                                  </Grid>
                                </Grid>
                              </>
                            ) : (
                              <>
                                <Grid
                                  container
                                  item
                                  md={7}
                                  border="1px solid #DDDDDD"
                                  bgcolor="#FFFFFF"
                                  borderRadius={5}
                                  p={{ xs: 1, sm: 2, md: 5 }}
                                  className="still-not-kahsh-container"
                                >
                                  <Grid container mb={1}>
                                    <Typography color="#1C1439" variant="body1">
                                      {t("chooseAuthenticationMethod")}
                                    </Typography>
                                  </Grid>

                                  <Grid
                                    container
                                    justifyContent={{
                                      xs: "space-evenly",
                                      md: "space-between"
                                    }}
                                    border="1px solid #D1D5DB"
                                    borderRadius="20px"
                                    bgcolor="#FAFAFA"
                                    p={1}
                                    mb={2}
                                  >
                                    <Grid
                                      py={1}
                                      px={{ xs: 1, lg: 0 }}
                                      lg={5.8}
                                      id="auth1"
                                      display="flex"
                                      justifyContent="center"
                                      className="authentication auth"
                                      sx={{
                                        "&:hover": {
                                          backgroundColor: "#c4c4cd",
                                          borderRadius: "20px",
                                          cursor: "pointer"
                                        }
                                      }}
                                      onClick={() =>
                                        setAuthMethod({
                                          accounts: false,
                                          exchange: true
                                        })
                                      }
                                    >
                                      <img
                                        src={LogoExchange}
                                        color={
                                          authMethod.exchange
                                            ? "#ffffff"
                                            : "#1A0E38"
                                        }
                                        width={"100%"}
                                        style={{
                                          marginRight: "5px",
                                          maxWidth: 150,
                                          pointerEvents: "none"
                                        }}
                                        alt="kahsh logo"
                                      />
                                    </Grid>

                                    <Grid
                                      py={1}
                                      px={{ xs: 1, lg: 0 }}
                                      lg={5.8}
                                      id="auth2"
                                      display="flex"
                                      className="auth"
                                      justifyContent="center"
                                      sx={{
                                        "&:hover": {
                                          backgroundColor: "#c4c4cd",
                                          borderRadius: "15px",
                                          cursor: "pointer"
                                        }
                                      }}
                                      onClick={() =>
                                        setAuthMethod({
                                          exchange: false,
                                          accounts: true
                                        })
                                      }
                                    >
                                      <img
                                        src={logoAccounts}
                                        color={
                                          authMethod.exchange
                                            ? "#ffffff"
                                            : "#1A0E38"
                                        }
                                        width={"100%"}
                                        style={{
                                          marginRight: "5px",
                                          maxWidth: 150,
                                          pointerEvents: "none"
                                        }}
                                        alt="kahsh logo"
                                      />
                                    </Grid>
                                  </Grid>

                                  <OutlinedInput
                                    size="small"
                                    sx={{ marginBottom: 2 }}
                                    autoFocus
                                    className="checkout-input"
                                    onKeyDown={(e) => {
                                      handleOnKeyDown(e);
                                    }}
                                    fullWidth
                                    placeholder="Email"
                                    onChange={(e) => {
                                      setUserInfo({
                                        ...userInfo,
                                        email: e.target.value
                                      });
                                    }}
                                    value={userInfo.email}
                                  />

                                  <OutlinedInput
                                    size="small"
                                    className="checkout-input"
                                    onKeyDown={(e) => {
                                      handleOnKeyDown(e);
                                    }}
                                    fullWidth
                                    placeholder={t("password")}
                                    type={visible ? "text" : "password"}
                                    onChange={(e) => {
                                      setUserInfo({
                                        ...userInfo,
                                        password: e.target.value
                                      });
                                    }}
                                    value={userInfo.password}
                                    endAdornment={
                                      <InputAdornment
                                        position="end"
                                        onClick={() => setVisible(!visible)}
                                      >
                                        {visible ? (
                                          <VisibilityIcon cursor="pointer" />
                                        ) : (
                                          <VisibilityOffIcon cursor="pointer" />
                                        )}
                                      </InputAdornment>
                                    }
                                  />

                                  <Grid
                                    container
                                    alignItems="center"
                                    mb={2}
                                    wrap="nowrap"
                                  >
                                    <Checkbox
                                      size="small"
                                      checked={checked}
                                      onChange={() => setChecked(!checked)}
                                    />
                                    <Typography variant="caption">
                                      {t("PrivacyTerms1")}{" "}
                                      <a
                                        href="https://exchange.kahsh.com/termosdeuso"
                                        target="_blank"
                                        rel="noreferrer"
                                        tabIndex={-1}
                                      >
                                        {t("PrivacyTerms2")}
                                      </a>
                                      {t("and")}
                                      <a
                                        href="https://exchange.kahsh.com/politicadeprivacidade"
                                        target="_blank"
                                        rel="noreferrer"
                                        tabIndex={-1}
                                      >
                                        {t("PrivacyTerms3")}
                                      </a>
                                    </Typography>
                                  </Grid>

                                  <Button
                                    onClick={() => handleLoginFastBuy()}
                                    className={`checkout-button-filled ${
                                      (loading && !modal.twofa) ||
                                      !checked ||
                                      userInfo.email.length == 0 ||
                                      userInfo.password.length == 0
                                        ? "loading"
                                        : ""
                                    }`}
                                    fullWidth
                                    disabled={
                                      loading ||
                                      !checked ||
                                      userInfo.email.length == 0 ||
                                      userInfo.password.length == 0
                                        ? true
                                        : false
                                    }
                                  >
                                    {loading && !modal.twofa ? (
                                      <CircularProgress size={25} />
                                    ) : (
                                      t("generatePix")
                                    )}
                                  </Button>

                                  <Grid
                                    container
                                    justifyContent="center"
                                    my={1}
                                  >
                                    <Typography>{t("or")}</Typography>
                                  </Grid>

                                  <Button
                                    className="checkout-button-outlined"
                                    fullWidth
                                    onClick={() => setFastBuyRegister(true)}
                                  >
                                    <Typography variant="body2">
                                      {t("makeYourQuickAccount")}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      className="here-text"
                                      ml={1}
                                      color="#4733ff"
                                      fontWeight={700}
                                    >
                                      {t("here")}
                                    </Typography>
                                  </Button>
                                </Grid>
                              </>
                            )}
                          </Grid>

                          <Grid container justifyContent="flex-end" mt={4}>
                            <Button
                              className="checkout-button-filled"
                              fullWidth
                              sx={{ maxWidth: "30%" }}
                              onClick={() => {
                                setPaymentMethod({
                                  buyKahsh: false,
                                  exchange: false,
                                  metamask: false,
                                  qrCode: false
                                });
                              }}
                            >
                              {t("back")}
                            </Button>
                          </Grid>
                        </>
                      ) : paymentMethod.metamask ? (
                        <>
                          <Grid
                            container
                            bgcolor="#FAFAFA"
                            direction="row-reverse"
                            border="1px solid #DDDDDD"
                            borderRadius={5}
                            p={1.5}
                            justifyContent="space-between"
                          >
                            <Grid
                              container
                              item
                              md={7}
                              py={8}
                              justifyContent="center"
                            >
                              <Grid textAlign="center">
                                <Typography variant="body1" fontWeight={600}>
                                  {t("paymentFor")}
                                </Typography>
                                <Typography variant="body1" fontWeight={300}>
                                  {invoice.customer_email}
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                container
                                alignItems="baseline"
                                justifyContent="center"
                              >
                                <Typography
                                  fontWeight={300}
                                  fontSize="45px"
                                  color="#373737"
                                >
                                  {currencyFormatter(Number(invoice.netamount))}
                                </Typography>

                                <Typography
                                  fontSize="18px"
                                  fontWeight={600}
                                  color="#373737"
                                >
                                  KSH
                                </Typography>
                              </Grid>

                              <Grid textAlign="center">
                                <Typography variant="body1" fontWeight={600}>
                                  {t("paymentAvailableUtil")}
                                </Typography>

                                <Typography variant="body1" fontWeight={300}>
                                  {format(
                                    new Date(invoice.validto),
                                    i18n.language.substring(0, 2) == "PT"
                                      ? "dd-MM-yyyy"
                                      : "MM-dd-yyyy"
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                            {!paymentMethod.qrCode ? (
                              <>
                                <Grid container item md={5} alignItems="center">
                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    p={2}
                                    className="payment-methods-container"
                                    bgcolor="#FFFFFF"
                                    wrap="nowrap"
                                    alignItems="center"
                                  >
                                    <img
                                      src={FoxIcon}
                                      alt={"Metamask Fox icon"}
                                      width="55px"
                                    />
                                    <Grid
                                      pl={3}
                                      onClick={() => handleMetamask()}
                                    >
                                      <Typography>
                                        {t("connectMetaMask")}
                                      </Typography>
                                      <Typography
                                        color="#1C1439"
                                        fontWeight={700}
                                      >
                                        {t("payWithMetamask")}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    container
                                    item
                                    mt={{ xs: 2, lg: -8 }}
                                    xs={12}
                                    p={2}
                                    className="payment-methods-container"
                                    bgcolor="#FFFFFF"
                                    wrap="nowrap"
                                    alignItems="center"
                                    onClick={() =>
                                      setPaymentMethod({
                                        buyKahsh: false,
                                        metamask: true,
                                        exchange: false,
                                        qrCode: true
                                      })
                                    }
                                  >
                                    <img
                                      src={WalletIcon}
                                      alt={"Wallet icon"}
                                      width="55px"
                                    />
                                    <Grid pl={3}>
                                      <Typography>
                                        {t("generateQrCode")}
                                      </Typography>
                                      <Typography
                                        color="#1C1439"
                                        fontWeight={700}
                                      >
                                        {t("payWithOtherWallet")}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </>
                            ) : null}

                            {paymentMethod.qrCode ? (
                              <>
                                <Grid
                                  container
                                  item
                                  md={5}
                                  borderRadius={5}
                                  border="2px solid #1A263E"
                                  alignItems="center"
                                  py={2}
                                >
                                  <Grid
                                    container
                                    justifyContent="center"
                                    mt={{ xs: 2, md: 0 }}
                                    item
                                  >
                                    <Typography
                                      display={"flex"}
                                      alignItems={"center"}
                                      fontSize={18}
                                    >
                                      <img
                                        src={KahshLogo}
                                        style={{
                                          marginRight: "5px",
                                          cursor: "text"
                                        }}
                                        width="20%"
                                        alt=""
                                      />
                                      kahsh
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    container
                                    justifyContent="center"
                                    my={2}
                                  >
                                    <Grid
                                      display="flex"
                                      minWidth={200}
                                      minHeight={200}
                                      borderRadius={5}
                                      justifyContent="center"
                                      my={{ xs: 2, md: 0 }}
                                      item
                                      alignItems="center"
                                      border="2px solid #1A263E"
                                    >
                                      <QrCode
                                        value={invoice.wallet_address}
                                        size={160}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    container
                                    justifyContent="center"
                                    item
                                    mb={{ xs: 2, md: 0 }}
                                  >
                                    <Button
                                      onClick={() => clipboardCopy(null)}
                                      className="copy-content-button"
                                    >
                                      {t("copyAddressCheckout")}
                                      <ContentCopyIcon
                                        sx={{ marginLeft: "10px" }}
                                      />
                                    </Button>
                                  </Grid>
                                </Grid>
                              </>
                            ) : null}
                          </Grid>
                          <Grid
                                    container
                                    justifyContent="center"
                                    mt={{ xs: 2, md: 0 }}
                                    item
                                  >
                                    <Typography
                                      display={"flex"}
                                      alignItems={"center"}
                                      fontSize={18}
                                    >
                                      <img
                                        src={PixLogo}
                                        style={{
                                          marginRight: "5px",
                                          cursor: "text"
                                        }}
                                        width="20%"
                                        alt=""
                                      />
                                      Após realizar o pagamento aguarde alguns instantes para ser aprovado!
                                    </Typography>
                                  </Grid>

                          <Grid container justifyContent="flex-end" mt={4}>
                            <Button
                              sx={{ maxWidth: "30%", minWidth: "200px" }}
                              className="checkout-button-filled"
                              fullWidth
                              onClick={() => {
                                setPaymentMethod({
                                  buyKahsh: false,
                                  exchange: false,
                                  metamask: false,
                                  qrCode: false
                                });
                              }}
                            >
                              {t("back")}
                            </Button>
                          </Grid>
                        </>
                      ) : paymentMethod.exchange ? (
                        <>
                          <Grid
                            container
                            bgcolor="#FAFAFA"
                            direction="row-reverse"
                            border="1px solid #DDDDDD"
                            borderRadius={5}
                            justifyContent="space-between"
                          >
                            <Modal
                              open={modal.twofa}
                              onClose={() => {
                                setModal({ account: false, twofa: false });
                                setTwoFaInputs({
                                  input1: "",
                                  input2: "",
                                  input3: "",
                                  input4: "",
                                  input5: "",
                                  input6: ""
                                });
                              }}
                            >
                              <Grid className="checkout-modal" p={4}>
                                <img
                                  style={{ cursor: "text" }}
                                  width={80}
                                  src={CheckoutLocker}
                                  alt="blue locker with a green message baloon"
                                />
                                <Typography
                                  mt={2}
                                  color="#1A0E38"
                                  fontSize={18}
                                  fontWeight={700}
                                >
                                  {t("twoFaAuth")}
                                </Typography>

                                <Typography mb={2} textAlign="center">
                                  {t("twoFaMessageCheckout")}
                                </Typography>

                                <Grid container wrap="nowrap">
                                  <OutlinedInput
                                    size="small"
                                    inputRef={initialRef}
                                    className="checkout-input-2fa"
                                    onKeyDown={(e: KeyboardEventHandler) => {
                                      if (e.key === "Enter" && !loading) {
                                        handleExchangeWallet();
                                        return;
                                      }

                                      handleCopyEvent(e);
                                    }}
                                    autoFocus
                                    value={twoFaInputs.input1}
                                    onChange={(e) => {
                                      if (twoFaInputs.input1.length === 0)
                                        inputRef.current.focus();
                                      setTwoFaInputs({
                                        ...twoFaInputs,
                                        input1: e.target.value
                                      });
                                    }}
                                    inputProps={{ maxLength: 1 }}
                                  />
                                  <OutlinedInput
                                    size="small"
                                    inputRef={inputRef}
                                    className="checkout-input-2fa"
                                    onKeyDown={(e: KeyboardEventHandler) => {
                                      if (e.key === "Enter" && !loading)
                                        handleExchangeWallet();
                                      if (e.key === "Backspace") {
                                        setTwoFaInputs({
                                          ...twoFaInputs,
                                          input2: ""
                                        });
                                        if (twoFaInputs.input2 == "")
                                          initialRef.current.focus();
                                      }
                                      handleCopyEvent(e);
                                    }}
                                    value={twoFaInputs.input2}
                                    onChange={(e) => {
                                      if (twoFaInputs.input2.length === 0)
                                        inputRef2.current.focus();
                                      setTwoFaInputs({
                                        ...twoFaInputs,
                                        input2: e.target.value
                                      });
                                    }}
                                    inputProps={{ maxLength: 1 }}
                                  />
                                  <OutlinedInput
                                    size="small"
                                    inputRef={inputRef2}
                                    className="checkout-input-2fa"
                                    onKeyDown={(e: KeyboardEventHandler) => {
                                      if (e.key === "Enter" && !loading)
                                        handleExchangeWallet();
                                      if (e.key === "Backspace") {
                                        setTwoFaInputs({
                                          ...twoFaInputs,
                                          input3: ""
                                        });
                                        if (twoFaInputs.input3 == "")
                                          inputRef.current.focus();
                                      }
                                      handleCopyEvent(e);
                                    }}
                                    value={twoFaInputs.input3}
                                    onChange={(e) => {
                                      if (twoFaInputs.input3.length === 0)
                                        inputRef3.current.focus();
                                      setTwoFaInputs({
                                        ...twoFaInputs,
                                        input3: e.target.value
                                      });
                                    }}
                                    inputProps={{ maxLength: 1 }}
                                  />
                                  <OutlinedInput
                                    size="small"
                                    inputRef={inputRef3}
                                    className="checkout-input-2fa"
                                    onKeyDown={(e: KeyboardEventHandler) => {
                                      if (e.key === "Enter" && !loading)
                                        handleExchangeWallet();
                                      if (e.key === "Backspace") {
                                        setTwoFaInputs({
                                          ...twoFaInputs,
                                          input4: ""
                                        });
                                        if (twoFaInputs.input4 == "")
                                          inputRef2.current.focus();
                                      }
                                      handleCopyEvent(e);
                                    }}
                                    value={twoFaInputs.input4}
                                    onChange={(e) => {
                                      if (twoFaInputs.input4.length === 0)
                                        inputRef4.current.focus();
                                      setTwoFaInputs({
                                        ...twoFaInputs,
                                        input4: e.target.value
                                      });
                                    }}
                                    inputProps={{ maxLength: 1 }}
                                  />
                                  <OutlinedInput
                                    size="small"
                                    inputRef={inputRef4}
                                    className="checkout-input-2fa"
                                    onKeyDown={(e: KeyboardEventHandler) => {
                                      if (e.key === "Enter" && !loading)
                                        handleExchangeWallet();
                                      if (e.key === "Backspace") {
                                        setTwoFaInputs({
                                          ...twoFaInputs,
                                          input5: ""
                                        });
                                        if (twoFaInputs.input5 == "")
                                          inputRef3.current.focus();
                                      }
                                      handleCopyEvent(e);
                                    }}
                                    value={twoFaInputs.input5}
                                    onChange={(e) => {
                                      if (twoFaInputs.input5.length === 0)
                                        inputRef5.current.focus();
                                      setTwoFaInputs({
                                        ...twoFaInputs,
                                        input5: e.target.value
                                      });
                                    }}
                                    inputProps={{ maxLength: 1 }}
                                  />
                                  <OutlinedInput
                                    size="small"
                                    inputRef={inputRef5}
                                    className="checkout-input-2fa"
                                    onKeyDown={(e: KeyboardEventHandler) => {
                                      if (e.key === "Enter" && !loading)
                                        handleExchangeWallet();
                                      if (e.key === "Backspace") {
                                        setTwoFaInputs({
                                          ...twoFaInputs,
                                          input6: ""
                                        });
                                        if (twoFaInputs.input6 == "")
                                          inputRef4.current.focus();
                                      }
                                      handleCopyEvent(e);
                                    }}
                                    value={twoFaInputs.input6}
                                    onChange={(e) => {
                                      setTwoFaInputs({
                                        ...twoFaInputs,
                                        input6: e.target.value
                                      });
                                    }}
                                    inputProps={{ maxLength: 1 }}
                                  />
                                </Grid>

                                {error.twofa ? (
                                  <Grid
                                    container
                                    mt={2}
                                    justifyContent="center"
                                  >
                                    <Typography color="red">
                                      {t("twoFaErrorMessage")}
                                    </Typography>
                                  </Grid>
                                ) : null}
                                <Grid mt={2} container justifyContent="center">
                                  <Button
                                    onClick={() => handleExchangeWallet()}
                                    sx={{ width: "80%" }}
                                    className={`checkout-button-filled ${
                                      loading ||
                                      Object.values(twoFaInputs)
                                        .join()
                                        .replaceAll(",", "").length < 6
                                        ? "loading"
                                        : ""
                                    }`}
                                    disabled={
                                      loading ||
                                      Object.values(twoFaInputs)
                                        .join()
                                        .replaceAll(",", "").length < 6
                                        ? true
                                        : false
                                    }
                                  >
                                    {loading ? (
                                      <CircularProgress size={25} />
                                    ) : (
                                      t("payWithWallet")
                                    )}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Modal>

                            <Grid
                              item
                              md={5}
                              py={8}
                              container
                              justifyContent="center"
                            >
                              <Grid textAlign="center">
                                <Typography variant="body1" fontWeight={600}>
                                  {t("paymentFor")}
                                </Typography>

                                <Typography variant="body1" fontWeight={300}>
                                  {invoice.customer_email}
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                container
                                alignItems="baseline"
                                justifyContent="center"
                              >
                                <Typography
                                  fontWeight={300}
                                  fontSize="45px"
                                  color="#373737"
                                >
                                  {currencyFormatter(Number(invoice.netamount))}
                                </Typography>

                                <Typography
                                  fontSize="18px"
                                  fontWeight={600}
                                  color="#373737"
                                >
                                  KSH
                                </Typography>
                              </Grid>

                              <Grid textAlign="center">
                                <Typography variant="body1" fontWeight={600}>
                                  {t("paymentAvailableUtil")}
                                </Typography>

                                <Typography variant="body1" fontWeight={300}>
                                  {format(
                                    new Date(invoice.validto),
                                    i18n.language.substring(0, 2) == "PT"
                                      ? "dd-MM-yyyy"
                                      : "MM-dd-yyyy"
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid
                              container
                              item
                              md={7}
                              borderRadius={5}
                              border="1px solid #DDDDDD"
                              bgcolor="#FFFFFF"
                              alignItems="center"
                              justifyContent="center"
                              py={3}
                            >
                              <Grid container mb={1} pl={5}>
                                <Typography color="#1C1439" variant="body1">
                                  {t("chooseAuthenticationMethod")}
                                </Typography>
                              </Grid>

                              <Grid
                                container
                                item
                                justifyContent={{
                                  xs: "space-evenly",
                                  md: "space-between"
                                }}
                                border="1px solid #D1D5DB"
                                borderRadius="20px"
                                bgcolor="#FAFAFA"
                                md={10.5}
                                p={1}
                              >
                                <Grid
                                  py={1}
                                  px={{ xs: 1, lg: 0 }}
                                  lg={5.8}
                                  id="auth1"
                                  display="flex"
                                  justifyContent="center"
                                  className="authentication auth"
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "#c4c4cd",
                                      borderRadius: "20px",
                                      cursor: "pointer"
                                    }
                                  }}
                                  onClick={() =>
                                    setAuthMethod({
                                      accounts: false,
                                      exchange: true
                                    })
                                  }
                                >
                                  <img
                                    src={LogoExchange}
                                    color={
                                      authMethod.exchange
                                        ? "#ffffff"
                                        : "#1A0E38"
                                    }
                                    width={"100%"}
                                    style={{
                                      marginRight: "5px",
                                      maxWidth: 150,
                                      pointerEvents: "none"
                                    }}
                                    alt="kahsh logo"
                                  />
                                </Grid>

                                <Grid
                                  py={1}
                                  px={{ xs: 1, lg: 0 }}
                                  lg={5.8}
                                  id="auth2"
                                  display="flex"
                                  className="auth"
                                  justifyContent="center"
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "#c4c4cd",
                                      borderRadius: "15px",
                                      cursor: "pointer"
                                    }
                                  }}
                                  onClick={() =>
                                    setAuthMethod({
                                      exchange: false,
                                      accounts: true
                                    })
                                  }
                                >
                                  <img
                                    src={logoAccounts}
                                    color={
                                      authMethod.exchange
                                        ? "#ffffff"
                                        : "#1A0E38"
                                    }
                                    width={"100%"}
                                    style={{
                                      marginRight: "5px",
                                      maxWidth: 150,
                                      pointerEvents: "none"
                                    }}
                                    alt="kahsh logo"
                                  />
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                container
                                borderRadius={5}
                                p={{ xs: 1, sm: 2, md: 5 }}
                                className="exchange-container"
                              >
                                <OutlinedInput
                                  size="small"
                                  fullWidth
                                  placeholder="Email"
                                  value={userInfo.email}
                                  onKeyPress={(e) => {
                                    if (e.key == "Enter") {
                                      if (
                                        userInfo.email.length < 0 ||
                                        userInfo.password.length < 0 ||
                                        !checked
                                      )
                                        return null;

                                      handleExchangeWallet();
                                    }
                                  }}
                                  onChange={(e) => {
                                    setUserInfo({
                                      ...userInfo,
                                      email: e.target.value
                                    });
                                  }}
                                  className="checkout-input"
                                />

                                <OutlinedInput
                                  size="small"
                                  className="checkout-input"
                                  fullWidth
                                  placeholder={t("password")}
                                  type={visible ? "text" : "password"}
                                  onKeyPress={(e) => {
                                    if (e.key == "Enter") {
                                      if (
                                        userInfo.email.length < 0 ||
                                        userInfo.password.length < 0 ||
                                        !checked
                                      )
                                        return null;

                                      handleExchangeWallet();
                                    }
                                  }}
                                  onChange={(e) => {
                                    setUserInfo({
                                      ...userInfo,
                                      password: e.target.value
                                    });
                                  }}
                                  value={userInfo.password}
                                  endAdornment={
                                    <InputAdornment
                                      position="end"
                                      onClick={() => setVisible(!visible)}
                                    >
                                      {visible ? (
                                        <VisibilityIcon cursor="pointer" />
                                      ) : (
                                        <VisibilityOffIcon cursor="pointer" />
                                      )}
                                    </InputAdornment>
                                  }
                                />

                                <Grid
                                  mb={2}
                                  container
                                  wrap="nowrap"
                                  alignItems="center"
                                >
                                  <Checkbox
                                    size="small"
                                    checked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <Typography variant="caption">
                                    {t("PrivacyTerms1")}
                                    <a
                                      href="https://exchange.kahsh.com/termosdeuso"
                                      target="_blank"
                                      rel="noreferrer"
                                      tabIndex={-1}
                                    >
                                      {t("PrivacyTerms2")}
                                    </a>
                                    {t("and")}
                                    <a
                                      href="https://exchange.kahsh.com/politicadeprivacidade"
                                      target="_blank"
                                      rel="noreferrer"
                                      tabIndex={-1}
                                    >
                                      {t("PrivacyTerms3")}
                                    </a>
                                  </Typography>
                                </Grid>
                                <Button
                                  onClick={() => handleExchangeWallet()}
                                  className={`checkout-button-filled ${
                                    (loading && !modal.twofa) ||
                                    !checked ||
                                    userInfo.email.length == 0 ||
                                    userInfo.password.length == 0
                                      ? "loading"
                                      : ""
                                  }`}
                                  fullWidth
                                  disabled={
                                    loading ||
                                    !checked ||
                                    userInfo.email.length == 0 ||
                                    userInfo.password.length == 0
                                      ? true
                                      : false
                                  }
                                >
                                  {loading && !modal.twofa ? (
                                    <CircularProgress size={24} />
                                  ) : (
                                    t("makePayment")
                                  )}
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid container justifyContent="flex-end" mt={4}>
                            <Button
                              className="checkout-button-filled"
                              fullWidth
                              sx={{ maxWidth: "30%", minWidth: "200px" }}
                              onClick={() => {
                                setPaymentMethod({
                                  buyKahsh: false,
                                  exchange: false,
                                  metamask: false,
                                  qrCode: false
                                });
                              }}
                            >
                              {t("back")}
                            </Button>
                          </Grid>
                        </>
                      ) : null}
                    </Grid>
                  </Grid>

                  {invoice.return_url &&
                  !Object.values(paymentMethod).some((pm) => {
                    return pm == true;
                  }) ? (
                    <Grid
                      container
                      item
                      xs={12}
                      md={10.3}
                      justifyContent={{ xs: "center", md: "flex-end" }}
                      mt={3}
                      mb={{ xs: 6, md: 0 }}
                    >
                      <Button
                        className="checkout-button-filled"
                        fullWidth
                        sx={{ maxWidth: "300px" }}
                        onClick={() =>
                          (window.location.href = `${invoice.return_url}`)
                        }
                      >
                        {t("backToStore")}
                      </Button>
                    </Grid>
                  ) : null}
                </>
              )}
            </>
          )}
        </>
      ) : null}
    </Grid>
  );
};

export default CheckoutKahshPay;

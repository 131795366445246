import { Avatar, Typography, Box } from "@mui/material";

interface DocumentCardProps {
  picture?: string;
  title: string;
  description?: string;
  onClick?: () => void;
  selected?: boolean;
  icon?: JSX.Element;
}
export const DocumentCard: React.FC<DocumentCardProps> = ({
  picture,
  title,
  description,
  onClick,
  selected,
  icon
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      border={(theme) =>
        `1px solid ${selected ? theme.palette.primary.main : "#ddd"}`
      }
      gap={2}
      p={0.5}
      borderRadius="20px"
      onClick={onClick}
      sx={{ cursor: onClick ? "pointer" : "inherit" }}
    >
      <Box border="1px solid #ddd" borderRadius="18px">
        <Avatar
          variant="rounded"
          src={picture}
          sx={{ borderRadius: "10px", width: 64, height: 64 }}
        >
          {icon ? icon : title[0].toUpperCase()}
        </Avatar>
      </Box>
      <Box>
        <Typography>{title}</Typography>
        <Typography variant="caption">{description}</Typography>
      </Box>
    </Box>
  );
};

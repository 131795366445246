import { styled } from "@mui/material";

interface FileInputLabelProps {
  height?: number;
  width?: number;
  haspreview: number;
  error?: string;
}

export const FileInputLabel = styled("label")<FileInputLabelProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => (width ? width + "px" : 100 + "%")};
  height: ${({ height }) => (height ? height + "px" : 100 + "%")};
  border-radius: 20px;
  border: 1px
    ${({ haspreview, error, theme }) =>
      (haspreview ? "solid " : "dashed ") +
      (error
        ? theme.palette.error.main
        : haspreview
        ? theme.palette.success.main
        : "#ddd")};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  margin: 0 auto;

  svg {
    font-size: 120px;
    color: ${({ error, theme, haspreview }) =>
      error
        ? theme.palette.error.main
        : haspreview
        ? theme.palette.success.main
        : "#ddd"};
  }
`;

export const ButtonInputLabel = styled("label")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 200px;
  margin: ${({ theme }) => theme.spacing(2)} auto;
`;

export const Input = styled("input")`
  display: none;
`;

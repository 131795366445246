import { useEffect, useState, useCallback } from "react";

import { Grid, Typography, Box } from "@mui/material";

import { Button } from "../../components/buttons/Button/Button";

import axios from "axios";
import { useHistory, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import LogoKahshAndName from "../../images/logoKsh.svg";
import Verified from "../../images/VerifyEmail/Verified.svg";
import EmailVerificadoComSucesso from "../../images/VerifyEmail/VerifyEmailSide.png";

import { useAlert } from "../../hooks/alert";

import { urlVerifyEmail } from "../../services/global";

export function VerifyEmail() {
  const { t } = useTranslation();

  const history = useHistory();

  const { AlertMessage } = useAlert();

  const params: any = useParams();

  const [isLoading] = useState(false);

  const [seconds, setSeconds] = useState(5);

  useEffect(() => {
    setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else if (seconds === 0) {
        history.push("/login");
      }
    }, 1000);
  }, [history, seconds]);

  const handleVerifyEmail = useCallback(async () => {
    try {
      await axios.post(
        urlVerifyEmail,
        {
          token: `${params.token}`,
          returnUrl: "https://kahsh.com"
        },
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            "Access-Control-Allow-Origin": "*"
          }
        }
      );

      return AlertMessage("success", "verifiedEmail");
    } catch (error) {
      return AlertMessage("error", "unkownError", error);
    }
  }, [AlertMessage, params.token]);

  useEffect(() => {
    if (params?.token) {
      handleVerifyEmail();
    }
  }, [handleVerifyEmail, params?.token]);

  return (
    <Grid container minHeight="100vh">
      <Grid item xs={12} md={6}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          maxWidth={400}
          px={2}
          mx="auto"
          height="100%"
        >
          <img
            src={LogoKahshAndName}
            alt="logo"
            width="200px"
            style={{ marginRight: "auto" }}
          />

          <Typography py={5} variant="h5" color="secondary.main" mr="auto">
            {t("verifyEmailSuccess")}
          </Typography>

          <img
            src={Verified}
            alt="Verified"
            style={{
              maxWidth: 400,
              width: "50%",
              pointerEvents: "none"
            }}
          />

          <Typography pb={2} variant="body2">
            {t("successCreated")}
          </Typography>

          <Button
            loading={isLoading}
            onClick={() => history.push("/login")}
            sx={{ mt: 4 }}
          >
            {t("enter")}
          </Button>

          <Typography
            mt={2}
            variant="caption"
            color="secondary.main"
            fontWeight="bold"
          >
            {t("autoLogin")} {seconds}s
          </Typography>
        </Box>
      </Grid>
      <Grid item md={6} display={{ xs: "none", md: "flex" }}>
        <Grid
          item
          md={9}
          height="100%"
          ml="auto"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          <img
            src={EmailVerificadoComSucesso}
            alt="Recovery Password"
            width="80%"
            style={{
              margin: "auto",
              position: "relative",
              marginLeft: "-35%",
              maxHeight: "80vh",
              objectFit: "contain"
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

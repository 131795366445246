export const formatCurrency = (value: string, language: string) => {
  if (value) {
    value = Number(value).toFixed(2).toString()
    value = value.replace(/\D+/g, '');
    if (!value) value = '0'
    if(/(0\d0\d)/.test(value) && value.length === 4){
      value = value.replaceAll('0', '');
    }
    value = value.substring(0,value.length - 2) + "." + value.substring(value.length - 2);
    const res = new Intl.NumberFormat(language, { minimumFractionDigits: 2 }).format(parseFloat(value));
    return res;
  }
  return '';
};

export const formatCurrencySubmit = (value: string) => {
  if (value) {
    value = value.replace(/\D+/g, '');
    if(value.length === 1){
      value = '0' + value
    }
    value = value.substring(0,value.length - 2) + "." + value.substring(value.length - 2);
    return value
  }
  return '';
};
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Button } from "../Button/Button";

interface BackAndSubmitButtonProps {
  onSubmit?: () => void;
  onBack?: () => void;
  titleBack?: string;
  titleSubmit?: string;
  loading?: boolean;
}

export const BackAndSubmitButton: React.FC<BackAndSubmitButtonProps> = ({
  onSubmit,
  onBack,
  titleBack,
  titleSubmit,
  loading
}) => {
  const { t } = useTranslation();
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection={{ md: "row", xs: "column-reverse" }}
      justifyContent="flex-end"
      alignItems="center"
      gap={2}
    >
      {!!onBack && (
        <Button
          fullWidth
          onClick={onBack}
          variant="outlined"
          sx={{ maxWidth: 300 }}
        >
          {titleBack ? t(titleBack) : t("back")}
        </Button>
      )}
      {!!onSubmit && (
        <Button
          fullWidth
          onClick={onSubmit}
          sx={{ maxWidth: 300 }}
          loading={loading}
        >
          {titleSubmit ? t(titleSubmit) : t("next")}
        </Button>
      )}
    </Box>
  );
};

import { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "../components/buttons/Button/Button";
import { useTranslation } from "react-i18next";

import imagemNotFound2 from "../images/imagemNotFound2.png";

export const NotFound = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const navigationDashboardOrLogin = () => {
    if (localStorage.getItem("@kahsh-pay:user")) {
      history.push("/dashboard");
    } else {
      history.push("/login");
    }
  };

  useEffect(() => {
    if (location.pathname === "/") {
      navigationDashboardOrLogin();
    } else if (location.pathname !== "/notfound") {
      history.push("/notfound");
    }
  }, [history]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      minHeight="90vh"
      textAlign="center"
    >
      <img
        src={imagemNotFound2}
        width="100%"
        style={{ maxWidth: "40vh" }}
        alt={t("notFoundTitle2")}
      />

      <Typography pt={2} variant="h4" color="primary.main" fontWeight="bold">
        {t("pageNotFound")}
      </Typography>

      <Typography pt={1} pb={3} variant="body1" color="primary.main">
        {t("pageNotFoundSubtitle")}
      </Typography>

      <Button
        onClick={() => navigationDashboardOrLogin()}
        sx={{ height: "auto", width: "auto" }}
      >
        {t("backToStart")}
      </Button>
    </Grid>
  );
};

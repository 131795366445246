import {
  Article,
  BarChart,
  ContactSupport,
  RequestQuote,
  Settings,
  SwapHoriz
} from "@mui/icons-material";

export interface RouteItem {
  src?: JSX.Element;
  href: string;
  title: string;
  isOpen?: boolean;
  subMenu?: RouteItem[];
  isSubMenu?: boolean;
}

export const RouterLinkMenu: RouteItem[] = [
  {
    src: <BarChart />,
    href: "/dashboard",
    title: "dashboard"
  },
  {
    src: <SwapHoriz />,
    href: "/invoice",
    title: "createInvoice"
  },
  {
    src: <Article />,
    href: "/invoicelist",
    title: "invoiceList"
  },
  {
    src: <Settings />,
    href: "/settings",
    title: "settings",
    isOpen: false,
    subMenu: [
      {
        href: "/user",
        title: "manageUser",
        isSubMenu: true
      },
      {
        href: "/api",
        title: "manageApi",
        isSubMenu: true
      },
      {
        href: "/edit-user",
        title: "editUser",
        isSubMenu: true
      },
      {
        href: "/edit-password",
        title: "editPassword",
        isSubMenu: true
      }
    ]
  },
  {
    src: <RequestQuote />,
    href: "/extract",
    title: "extract"
  },
  {
    src: <ContactSupport />,
    href: "/support",
    title: "support"
  }
];

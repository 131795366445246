import { Box, Typography, Divider } from "@mui/material";

interface BaseCardProps {
  title: string | JSX.Element;
  value: string | JSX.Element;
}
export const BaseCard: React.FC<BaseCardProps> = ({ title, value }) => {
  return (
    <Box border="1px solid #ddd" borderRadius="15px">
      <Box px={2} py={1}>
        {typeof title === "string" ? (
          <Typography fontWeight="bold">{title}</Typography>
        ) : (
          title
        )}
      </Box>
      <Divider />
      <Box p={2}>
        {typeof value === "string" ? (
          <Typography variant="h5" component="p" textAlign="center">
            {value}
          </Typography>
        ) : (
          value
        )}
      </Box>
    </Box>
  );
};

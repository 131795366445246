import { Box, Grid, InputAdornment, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import CircularProgress from "@mui/material/CircularProgress";

import axios from "axios";
import { EXTRACT_URL } from "../../services/global";

import { useHistory } from "react-router-dom";
import {
  Cell,
  ListComponent
} from "../../components/listComponent/ListComponent";
import dataFormat from "../../hooks/dataFormat";
import { formatCurrency } from "../../utils/formatCurrency";
import { Search, Visibility } from "@mui/icons-material";
import { StatusLabel } from "../../components/statusLabelExtract/StatusLabel";
import { TextFieldStyled } from "../../components/inputs/text-field/text-field.styles";

const cells: Cell[] = [
  {
    id: "datePlanned",
    label: "Date",
    size: 0.25,
    button: true,
    format: (value) => dataFormat(value.datePlanned)
  },
  {
    id: "amount",
    label: "customerValue",
    size: 0.4,
    button: true,
    format: (value) => (
      <Typography ml={{ xs: 0, md: 1.5 }} component="span" variant="body2">{`${
        value.currencyIsoCode
      } • ${formatCurrency(value.amount, "pt-BR")}`}</Typography>
    )
  },
  {
    id: "status",
    label: "STATUS",
    size: 0.25,
    button: true,
    format: (value) => (
      <StatusLabel
        value={value.status}
        sx={{ ml: { xs: 0, md: "calc(10% + 20px)" } }}
      />
    )
  }
];

interface IPayments {
  currencyIsoCode: string;
  qty: string;
  amount: string;
  currencyId: string;
  datePlanned: Date;
  id: string;
  status: string;
  idNull: boolean;
}

export const Extract = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [search, setSearch] = useState<string>("");
  const [data, setData] = useState<IPayments[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get<IPayments[]>(EXTRACT_URL);
        const dataWithId = data?.map((payment, index, array) => {
          if (payment?.id) {
            return payment;
          }
          const id = Math.max(...array?.map((p) => (p?.id ? Number(p.id) : 0)));
          return { ...payment, id: `${id + index}`, idNull: true };
        });
        setData(dataWithId);
        setLoading(false);
      } catch (error) {}
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleClickItem = (invoice: IPayments) => {
    if (invoice?.idNull) {
      return history.push(
        `/extract/${invoice.currencyId}/${new Date(
          invoice.datePlanned
        ).toISOString()}`
      );
    }
    return history.push(`/extract/${invoice.id}`);
  };

  const filteredData = search
    ? data?.filter(
        (invoice) =>
          invoice?.id?.toUpperCase()?.includes(search?.toUpperCase()) ||
          invoice?.amount?.toUpperCase()?.includes(search?.toUpperCase())
      )
    : data;

  return (
    <Grid
      container
      direction="column"
      minHeight="calc(100vh - 62px - 16px - 16px)"
      borderRadius="15px"
      border="1px solid #DDDDDD"
      flexWrap="unset"
      p={4}
      gap={4}
    >
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent={{ xs: "center", md: "space-between" }}
      >
        <Typography pb={{ xs: 2, md: 0 }} variant="body1" color="#000000">
          {t("historic")}
        </Typography>

        <Box
          gap={2}
          width="100%"
          display="flex"
          alignItems="center"
          maxWidth={{ md: "60%", xs: "100%" }}
          flexDirection={{ xs: "column", md: "row" }}
        >
          <TextFieldStyled
            fullWidth
            size="small"
            label={t("search")}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search color="primary" />
                </InputAdornment>
              )
            }}
          />
        </Box>
      </Box>

      <Box>
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={2}
          >
            <Typography variant="body2" color="#000000">
              {t("loading")} {t("invoices")}
            </Typography>
            <CircularProgress />
          </Box>
        ) : (
          <ListComponent
            cells={cells}
            rows={filteredData}
            dynamicId="id"
            initialOrder={{
              orderBy: "created",
              direction: "asc"
            }}
            noDataText="noInvoiceData"
            button={{
              label: "Detalhes",
              action: handleClickItem,
              icon: <Visibility sx={{ color: "#B4B4C1" }} />
            }}
            onDoubleClick={handleClickItem}
          />
        )}
      </Box>
    </Grid>
  );
};

import { Route, Switch } from "react-router-dom";

import ApiAndVetex from "../api/ApiAndVetex";
import ApiList from "../api/ApiList";
import UserList from "../merchant/userList/UserList";
import { CreateUser } from "./Dashboard-createUser";
import DashboardInvoice from "./Dashboard-Invoice";
import DashboardInvoiceList from "./Dashboard-InvoiceList";
import DashboardMetrics from "./Dashboard-metrics";
import DashboardSettings from "./Dashboard-Settings";
import { ComplianceCreate } from "../compliance/ComplianceCreate";
import { ComplianceStatus } from "../compliance/ComplianceStatus";
import { NotFound } from "../NotFound";
import { DrawerNavigation } from "../../components/header/drawerNavigation/DrawerNavigation";

import { useState } from "react";
import Support from "../support/Support";

import "./Dashboard.css";
import { EditPassword } from "../../components/editPassword/EditPassword";
import { EditUser } from "../accountSettings/EditUser/EditUser";
import { Extract } from "../extract/Extract";
import { ExtractDetail } from "../extract/extractDetail";
import { Header } from "../../components/header/Header";

function DashboardList() {
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <>
      <Header setOpen={() => setOpenDrawer(true)} />
      <DrawerNavigation open={openDrawer} setOpen={() => setOpenDrawer(false)}>
        <Switch>
          <Route exact path="/dashboard" component={DashboardMetrics} />
          <Route exact path="/invoice" component={DashboardInvoice} />
          <Route
            exact
            path="/invoicelist/:id?"
            component={DashboardInvoiceList}
          />
          <Route exact path="/settings" component={DashboardSettings} />

          <Route exact path="/extract" component={Extract} />
          <Route
            exact
            path="/extract/:extractId/:datePlanned?"
            component={ExtractDetail}
          />
          <Route exact path="/user/create/:userId?" component={CreateUser} />
          <Route exact path="/user" component={UserList} />
          <Route exact path="/api/create/:id?" component={ApiAndVetex} />
          <Route exact path="/api" component={ApiList} />
          <Route exact path="/compliance" component={ComplianceStatus} />
          <Route exact path="/compliance/create" component={ComplianceCreate} />
          <Route exact path="/support" component={Support} />
          <Route path="/edit-password" component={EditPassword} />
          <Route exact path="/edit-user" component={EditUser} />

          <Route path="*" component={NotFound} />
        </Switch>
      </DrawerNavigation>
    </>
  );
}

export default DashboardList;

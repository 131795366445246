import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Box,
  Grid,
  InputAdornment,
  Typography,
  CircularProgress
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";

import { useTranslation } from "react-i18next";

import axios from "axios";

import { RolesDrawer } from "../../components/rolesDrawer/RolesDrawer";
import { TextFieldStyled } from "../../components/inputs/text-field/text-field.styles";
import { Button } from "../../components/buttons/Button/Button";

import { Api } from "../../data/models/Api";
import { urlApi } from "../../services/global";

import { useAlert } from "../../hooks/alert";

import { BackAndSubmitButton } from "../../components/buttons/BackAndSubmit/BackAndSubmit";
import {
  ListComponent,
  Cell
} from "../../components/listComponent/ListComponent";
import { StatusActive } from "../../components/statusActive/StatusActive";

const cells: Cell[] = [
  {
    id: "name",
    label: "Nome",
    size: 1,
    button: true
  },
  {
    id: "isactive",
    label: "Status",
    size: 0.1,
    button: true,
    format: (value) => (
      <StatusActive value={value.isactive} sx={{ ml: "10%" }} />
    )
  }
];

const ApiList = () => {
  const { t } = useTranslation();
  const { AlertMessage } = useAlert();
  const history = useHistory();

  const [search, setSearch] = useState("");
  const [data, setData] = useState<Api[]>([]);
  const [loading, setLoading] = useState(true);

  const filteredData = search
    ? data?.filter(
        (api) =>
          api.id?.toUpperCase()?.includes(search?.toUpperCase()) ||
          api.name?.toUpperCase()?.includes(search?.toUpperCase())
      )
    : data;

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await axios.get<Api[]>(urlApi);
      setLoading(false);
      setData(data);
    } catch (err) {
      setLoading(false);
      AlertMessage("error", "defaultMessage", err);
    }
  }, [AlertMessage]);

  const handleEditClick = (value: Api) => {
    history.push(`/api/create/${value.id}`);
  };

  const handleInactive = async (value: Api) => {
    try {
      await axios.delete(`${urlApi}/${value.id}`);
      AlertMessage("success", "successfullyDeleteUser");
      await getData();
    } catch (err) {
      AlertMessage("error", "defaultMessage", err);
    }
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Grid
      container
      direction="column"
      minHeight="calc(100vh - 62px - 16px - 16px)"
      border="1px solid #DDDDDD"
      borderRadius="15px"
      p={4}
      width="100%"
    >
      <RolesDrawer />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection={{ xs: "column", md: "row" }}
        gap={2}
      >
        <Typography variant="body1" color="#000000">
          {t("apiList")}
        </Typography>

        <Box
          display="flex"
          alignItems="center"
          gap={2}
          flexDirection={{ xs: "column", md: "row" }}
          maxWidth={{ md: "60%", xs: "100%" }}
          width="100%"
        >
          <TextFieldStyled
            fullWidth
            size="small"
            value={search}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon color="primary" />
                </InputAdornment>
              )
            }}
            label={t("invoiceSearch")}
            onChange={(e) => setSearch(e.target.value)}
          />

          <Button onClick={() => history.push("/api/create")}>
            {t("newApi")}
          </Button>
        </Box>
      </Box>

      {loading ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt={4}
          gap={2}
        >
          <Typography variant="body2" color="#000000">
            {t("loading")} {t("users")}
          </Typography>
          <CircularProgress />
        </Box>
      ) : (
        <Box my={4}>
          <ListComponent
            cells={cells}
            rows={filteredData}
            initialOrder={{
              orderBy: "name",
              direction: "asc"
            }}
            dynamicId="id"
            noDataText="noUserData"
            moreButtons={[
              { label: "edit", action: handleEditClick },
              {
                label: "inactivate",
                action: handleInactive,
                viewKey: "isactive"
              }
            ]}
            onDoubleClick={(value) => handleEditClick(value)}
          />
        </Box>
      )}
      <Grid container justifyContent="flex-end" mt="auto" ml="auto">
        <BackAndSubmitButton onBack={() => history.push("/settings")} />
      </Grid>
    </Grid>
  );
};

export default ApiList;

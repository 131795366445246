import { FieldValues } from "react-hook-form";
import { countriesLabels } from "../constants/countries";

const itemTypeLabel = {
  street_number: "addressNumber",
  route: "address1",
  sublocality: "district",
  sublocality_level_1: "district",
  administrative_area_level_4: "district",
  administrative_area_level_2: "city",
  administrative_area_level_1: "region",
  country: "country",
  postal_code: "zipcode"
};

export const formatGoogleAutoCompleteResponse = (
  response: google.maps.GeocoderResult
): FieldValues => {
  let result: FieldValues = {};
  response.address_components.forEach(
    (item) =>
      (result[itemTypeLabel[item.types[0]] || itemTypeLabel[item.types[1]]] =
        item.short_name)
  );
  result.lat = response.geometry.location.lat();
  result.lng = response.geometry.location.lng();
  if (result?.country) {
    result.country = countriesLabels[result?.country];
  }
  return result;
};

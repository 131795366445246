import { CheckCircleOutline } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { BackAndSubmitButton } from "../../components/buttons/BackAndSubmit/BackAndSubmit";
import { Button } from "../../components/buttons/Button/Button";

interface ComplianceStep {
  onSubmit: (stepData: FieldValues) => void;
  onBack: () => void;
  compliance: FieldValues | null;
}

export const FinalStep: React.FC<ComplianceStep> = ({ onSubmit, onBack }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Box>
      <Box
        p={4}
        sx={{ border: "1px solid #ddd" }}
        borderRadius={"40px"}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap={2}
        mb={2}
      >
        <CheckCircleOutline
          sx={{
            color: (theme) => theme.palette.success.main,
            fontSize: (theme) => theme.typography.h2
          }}
        />
        <Typography variant="h6" fontWeight="bold" color="success.main">
          {t("successfullyRegisterTitle")}
        </Typography>
        <Typography maxWidth={350}>{t("successfullyRegister")}</Typography>
        <Typography>{t("waitAnalysis")}</Typography>
        <Button
          onClick={() => history.push("/compliance")}
          variant="text"
          sx={{ fontWeight: "bold", width: "auto" }}
        >
          {t("viewStatus")}
        </Button>
      </Box>
      <BackAndSubmitButton titleBack="logout" onBack={onBack} />
    </Box>
  );
};

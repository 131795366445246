import {
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
  Typography,
  CircularProgress
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, FieldValues } from "react-hook-form";
import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { urlPermission, urlRole } from "../../../services/global";
import { TextField } from "../../inputs/text-field/text-field.component";
import { Button } from "../../buttons/Button/Button";
import { useAlert } from "../../../hooks/alert";
import { Role } from "../../../data/models/Role";
import { Checkbox as CheckboxComponent } from "../../inputs/checkbox/checkbox.component";

interface PermissionsDrawerProps {
  onCreate: () => void;
  onClose: () => void;
  role: Role | null;
}

interface Permission {
  name: string;
  description: string;
  value: string;
  checked?: boolean;
}

const validator = yup.object().shape({
  name: yup.string().required("nameRequired")
});

export const PermissionsDrawer: React.FC<PermissionsDrawerProps> = ({
  onCreate,
  onClose,
  role
}) => {
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const { t, i18n } = useTranslation();
  const { AlertMessage } = useAlert();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validator)
  });

  const handleCreate = async (data: FieldValues) => {
    setLoading(true);
    try {
      data.permissions = permissions
        .filter((permission) => permission.checked)
        .map((permission) => permission.value);

      if (role) {
        data.id = role.id;
      }

      await axios.post(urlRole, data);
      setLoading(false);
      AlertMessage("success", "successfullyCreateRole");
      onCreate();
      handleClose();
    } catch (err) {
      setLoading(false);
      AlertMessage("error", "defaultMessage", err);
    }
  };

  const handleChange = (index: number) => {
    setPermissions((prev) =>
      prev.map((permission, i) =>
        index !== i
          ? permission
          : { ...permission, checked: !permission.checked }
      )
    );
  };

  const handleChangeAll = () => {
    const currentValue = permissions.every((permission) => permission.checked);
    setPermissions((prev) =>
      prev.map((permission) => ({ ...permission, checked: !currentValue }))
    );
  };

  const getData = useCallback(async () => {
    try {
      if (role) {
        reset({
          name: role?.name,
          description: role?.description,
          isactive: role?.isactive
        });
      }

      setLoadingData(true);
      const res = await axios.get<Permission[]>(urlPermission, {
        params: { lang: i18n.language.substring(0, 2) }
      });
      setLoadingData(false);
      setPermissions(
        res.data.map(({ name, description, value }) => ({
          name,
          description,
          value,
          checked: role
            ? role.permissions.some((permission) => permission?.value === value)
            : false
        }))
      );
    } catch (err) {
      setLoadingData(false);
      AlertMessage("error", "defaultMessage", err);
    }
  }, [AlertMessage, i18n.language, reset, role]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleClose = () => {
    setPermissions([]);
    reset();
    onClose();
  };

  return (
    <Box height="100%">
      <Box
        sx={{ border: "1px solid #ddd" }}
        p={4}
        mb={4}
        borderRadius={"15px"}
        display="flex"
        flexDirection="column"
        gap={4}
      >
        <Typography fontSize="24px">
          {role ? t("editRole") : t("createRole")}
        </Typography>
        <Grid
          container
          spacing={2}
          direction={{ xs: "row", sm: "row-reverse" }}
        >
          <Grid item xs={12} sm={3} md={2}>
            <CheckboxComponent
              control={control}
              disabled={!role}
              defaultChecked={true}
              name="isactive"
              label={t("activate")}
            />
          </Grid>

          <Grid item xs={12} sm={9} md={10}>
            <TextField
              required
              control={control}
              name="name"
              label="name"
              helperText={errors?.name?.message}
            />
          </Grid>
        </Grid>
        <TextField
          control={control}
          name="description"
          label="descriptionLowerCase"
        />
      </Box>

      <Box
        sx={{ border: "1px solid #ddd" }}
        p={4}
        borderRadius={"15px"}
        display="flex"
        flexDirection="column"
        gap={4}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection={{ xs: "column", sm: "row" }}
          gap={2}
        >
          <Typography fontSize="24px">{t("permissions")}</Typography>
          <Box sx={{ border: "1px solid #ddd" }} borderRadius={"15px"} px={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={permissions.every(
                    (permission) => permission.checked
                  )}
                  onChange={handleChangeAll}
                />
              }
              label={t("selectAll")}
            />
          </Box>
        </Box>

        <Grid container spacing={2}>
          {permissions.map((permission, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={permission.value}>
              <FormControlLabel
                key={permission.value}
                label={
                  <Typography variant="body2">{permission.name}</Typography>
                }
                control={
                  <Checkbox
                    size="small"
                    checked={permission.checked}
                    onChange={() => {
                      handleChange(index);
                    }}
                  />
                }
              />
            </Grid>
          ))}
          {loadingData && (
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography>
                {t("loading")} {t("permissions")}
              </Typography>
              <CircularProgress />
            </Grid>
          )}
        </Grid>
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        gap={2}
        pt={4}
        pb={8}
        ml="auto"
        maxWidth="600px"
        width="100%"
      >
        <Button onClick={handleClose} variant="outlined">
          {t("back")}
        </Button>
        <Button loading={loading} onClick={handleSubmit(handleCreate)}>
          {t("save")}
        </Button>
      </Box>
    </Box>
  );
};

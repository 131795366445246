import { CheckCircleOutline, MoreHoriz, Cancel } from "@mui/icons-material";
import { Box, Typography, CircularProgress, Skeleton } from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { BackAndSubmitButton } from "../../components/buttons/BackAndSubmit/BackAndSubmit";
import { urlMerchantCompliance } from "../../services/global";

interface StatusLabel {
  id: string;
  icon: JSX.Element;
  title: string;
  subtitle: string;
  date: string;
  color: string;
}

interface StatusResponse {
  status: string;
  notes: string;
}

const labels = {
  A: {
    icon: (
      <CheckCircleOutline
        sx={{
          color: (theme) => theme.palette.success.main,
          fontSize: (theme) => theme.typography.h2
        }}
      />
    ),
    id: "A",
    title: "approvedTitle",
    subtitle: "approvedSubtitle",
    date: "approvedDate",
    color: "success.main"
  },
  EMPTY: {
    icon: (
      <MoreHoriz
        sx={{
          color: (theme) => theme.palette.primary.main,
          fontSize: (theme) => theme.typography.h2
        }}
      />
    ),
    id: "EMPTY",
    title: "pendingTitle",
    subtitle: "pendingSubtitle",
    date: "pendingDate",
    color: "primary.main"
  },
  R: {
    icon: (
      <Cancel
        sx={{
          color: (theme) => theme.palette.error.main,
          fontSize: (theme) => theme.typography.h2
        }}
      />
    ),
    id: "R",
    title: "rejectedTitle",
    subtitle: "rejectedSubtitle",
    date: "rejectedDate",
    color: "error.main"
  },
  P: {
    id: "P",
    icon: <CircularProgress />,
    title: "analysisTitle",
    subtitle: "analysisSubtitle",
    date: "analysisDate",
    color: "primary.main"
  }
};

export const ComplianceStatus: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [status, setStatus] = useState<StatusLabel | null>(null);
  const [notes, setNotes] = useState("");

  const getData = async () => {
    const { data } = await axios.get<StatusResponse>(urlMerchantCompliance);
    if (data?.status) {
      return setStatus(labels[data.status]);
    }
    if (data?.notes) {
      setNotes(data.notes);
    }
    return setStatus(labels.EMPTY);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box width="100%">
      <Box
        p={4}
        sx={{ border: "1px solid #ddd" }}
        borderRadius={"40px"}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap={2}
        mb={4}
      >
        {!!status ? (
          <>
            {status?.icon}
            <Typography variant="h6" fontWeight="bold" color={status?.color}>
              {t(status?.title).toUpperCase()}
            </Typography>
            <Typography textAlign="center" maxWidth={300}>
              {t(status?.subtitle)}
            </Typography>
            {notes && (
              <Box
                p={4}
                sx={{ border: "1px solid #ddd" }}
                borderRadius={"40px"}
                width="80%"
                gap={2}
              >
                {/* // TODO Implementar
                <Box display="flex" justifyContent="space-evenly" gap={4}>
                  <Box>
                    <Typography color="#ddd">COMPLIANCE</Typography>
                    compliance name
                  </Box>
                  <Box>
                    <Typography color="#ddd">
                      {t(status?.date).toUpperCase()}
                    </Typography>
                    30/09/2022
                  </Box>
                </Box>
                */}
                <Box mt={2}>
                  <Typography color="#ddd">
                    {t("rejectedReason").toUpperCase()}
                  </Typography>
                  {notes}
                </Box>
              </Box>
            )}
          </>
        ) : (
          <Box display="flex" width={"100%"} flexDirection="column" gap={2}>
            <Skeleton
              variant="rectangular"
              width={"60%"}
              sx={{ mx: "auto" }}
              height={60}
            />
            <Skeleton
              variant="rectangular"
              width={"60%"}
              sx={{ mx: "auto", my: 2 }}
              height={60}
            />
            <Skeleton
              variant="rectangular"
              width={"60%"}
              sx={{ mx: "auto" }}
              height={60}
            />
          </Box>
        )}
      </Box>
      {(status?.id === "EMPTY" || status?.id === "R") && (
        <BackAndSubmitButton
          titleSubmit="new"
          onSubmit={() => history.push("/compliance/create")}
          onBack={() => {}}
        />
      )}
      {status?.id !== "EMPTY" && status?.id !== "R" && (
        <BackAndSubmitButton titleSubmit="new" onBack={() => {}} />
      )}
    </Box>
  );
};

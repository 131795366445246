import { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Skeleton,
  Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FieldValues, useForm } from "react-hook-form";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";

import { TextField } from "../../../components/inputs/text-field/text-field.component";
import { PhoneInput } from "../../../components/inputs/phoneInput/PhoneInput";
import { GooglePlacesAutocomplete } from "../../../components/inputs/google-places-autocomplete/google-places-autocomplete.component";
import { TextFieldMask } from "../../../components/inputs/text-field-mask/text-field-mask.component";
import { Select } from "../../../components/inputs/select/select.component";
import { normalizeString } from "../../../utils/normalizeString";
import {
  urlCities,
  urlCountries,
  urlDistrict,
  urlMerchant,
  urlRegions
} from "../../../services/global";
import { useAlert } from "../../../hooks/alert";
import { Merchant } from "../../../data/models/Merchant";
import { useAuth } from "../../../contexts/authContext";
import { BackAndSubmitButton } from "../../../components/buttons/BackAndSubmit/BackAndSubmit";
import KahshLogo from "../../../images/KahshLogo.png";

interface IEditUserSelect {
  value: any;
  label: string;
  isoCode?: string;
}

const validator = yup.object().shape({
  phone: yup
    .string()
    .required("phoneRequired")
    .test(`test-phone`, function (value) {
      const { path, createError } = this;
      return (
        isValidPhoneNumber(`${value}`) ||
        createError({ path, message: "phoneInvalid" })
      );
    }),
  address1: yup.string().required("addressAutoCompleteInvalid"),
  cityId: yup.string().required("cityRequired"),
  regionId: yup.string().required("regionRequired"),
  countryId: yup.string().required("countryRequired"),
  addressNumber: yup.string().required("addressNumberRequired")
});

export const EditUser = () => {
  const [backdrop, setBackdrop] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [cities, setCities] = useState<IEditUserSelect[]>([]);
  const [districts, setDistricts] = useState<IEditUserSelect[]>([]);
  const [districtDefault, setDistrictDefault] =
    useState<IEditUserSelect | null>(null);
  const [regions, setRegions] = useState<IEditUserSelect[]>([]);
  const [countries, setCountries] = useState<IEditUserSelect[]>([]);
  const [place, setPlace] = useState<FieldValues | null>(null);
  const { t } = useTranslation();
  const { AlertMessage } = useAlert();
  const { user, merchant } = useAuth();
  const isCpf = merchant && merchant?.taxid?.length <= 11 ? true : false;

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validator)
  });

  const accessPermitted =
    user?.permissions.includes("MERCHANT_WRITE") || user?.isowner;

  const setCountryData = useCallback(
    async (countryValue: string) => {
      if (countryValue) {
        const selectedCountry = countries.find(
          (country) =>
            country?.isoCode === countryValue || country?.value === countryValue
        );
        if (selectedCountry) {
          try {
            setValue("countryId", selectedCountry?.value);

            const { data: regionsData } = await axios.get(urlRegions, {
              params: { countryId: selectedCountry?.value }
            });
            if (regionsData?.length) {
              setRegions(
                regionsData.map(({ name, id, code }) => ({
                  value: id,
                  label: name,
                  isoCode: code
                }))
              );
            } else {
              setBackdrop(false);
            }
          } catch (err) {
            setBackdrop(false);
            return AlertMessage("error", "defaultMessage", err);
          }
        } else {
          setBackdrop(false);
        }
      }
    },
    [AlertMessage, countries, setValue]
  );

  const setData = useCallback(
    (merchant: Merchant) => {
      setDistrictDefault(
        merchant?.address?.districtName
          ? {
              value: "0",
              label: merchant?.address?.districtName
            }
          : null
      );
      setPlace({
        country: merchant?.address?.countryId || "",
        region: merchant?.address?.regionId || "",
        city: merchant?.address?.cityId || "",
        district:
          merchant?.address?.districtId || merchant?.address?.districtName
            ? "0"
            : ""
      });
      reset({
        lat: Number(merchant?.address?.lat) || 0,
        lng: Number(merchant?.address?.lng) || 0,
        name: merchant?.name || "",
        corporateName: merchant?.corporateName || "",
        email: merchant?.email || "",
        phone: merchant?.phoneDdi
          ? `+${merchant?.phoneDdi}${merchant?.phone}`
          : "",
        taxid: merchant?.taxid || "",
        countryId: merchant?.address?.countryId || "",
        zipcode: merchant?.address?.zipcode || "",
        addressNumber: merchant?.address?.addressNumber || "",
        address2: merchant?.address?.address2 || ""
      });
      setValue("address1", merchant?.address?.address1 || "");
      setLoading(false);
    },
    [reset, setValue]
  );

  const setCityData = useCallback(
    async (cityValue: string) => {
      if (!cities?.length) {
        return;
      }

      const selectedCity = cities.find(
        (city) =>
          normalizeString(city?.label) === normalizeString(cityValue) ||
          city?.value === cityValue
      );
      if (selectedCity) {
        try {
          setValue("cityId", selectedCity?.value);
          const { data: districtData } = await axios.get(urlDistrict, {
            params: { cityId: selectedCity?.value }
          });

          setDistricts([
            ...districtData.map(({ name, id }) => ({
              value: id,
              label: name
            })),
            !!districtDefault && districtDefault
          ]);
          setBackdrop(false);
        } catch (err) {
          setBackdrop(false);
          return AlertMessage("error", "defaultMessage", err);
        }
      } else {
        setBackdrop(false);
      }
    },
    [AlertMessage, cities, setValue]
  );

  const setRegionData = useCallback(
    async (regionValue: string) => {
      if (!regions?.length) {
        return;
      }
      const selectedRegion = regions.find(
        (region) =>
          region?.isoCode === regionValue || region?.value === regionValue
      );
      if (selectedRegion) {
        try {
          setValue("regionId", selectedRegion?.value);
          const { data: citiesData } = await axios.get(urlCities, {
            params: { regionId: selectedRegion?.value }
          });
          if (citiesData?.length) {
            setCities(
              citiesData.map(({ name, id }) => ({ value: id, label: name }))
            );
          } else {
            setBackdrop(false);
          }
        } catch (err) {
          setBackdrop(false);
          return AlertMessage("error", "defaultMessage", err);
        }
      } else {
        setBackdrop(false);
      }
    },
    [AlertMessage, regions, setValue]
  );

  const setDistrictData = useCallback(
    async (districtValue: string) => {
      if (!districts?.length) {
        return;
      }

      const selectedDistrict = districts.find(
        (city) =>
          normalizeString(city?.label) === normalizeString(districtValue) ||
          city?.value === districtValue
      );
      if (selectedDistrict) {
        setValue("districtId", selectedDistrict?.value);
      }
      setBackdrop(false);
    },
    [districts, setValue]
  );

  const getCountries = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(urlCountries);
      setCountries(
        data.map(({ name, id, isoCode }) => ({
          value: id,
          label: name,
          isoCode
        }))
      );
      const { data: dataMerchant } = await axios.get<Merchant>(urlMerchant);
      setData(dataMerchant);
    } catch (err) {
      setLoading(false);
      setBackdrop(false);
      return AlertMessage("error", "defaultMessage", err);
    }
  }, [AlertMessage]);

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  useEffect(() => {
    if (place?.country) {
      setCountryData(place?.country);
    }
    if (place?.address1) {
      setValue("address1", place?.address1);
    }
    if (place?.addressNumber) {
      setValue("addressNumber", place?.addressNumber);
    }
    if (place?.zipcode) {
      setValue("zipcode", place?.zipcode);
    }
    if (place?.lat && place?.lng) {
      setValue("lat", place?.lat);
      setValue("lng", place?.lng);
    }
  }, [setCountryData, place, setValue]);

  useEffect(() => {
    if (place?.region) {
      setRegionData(place?.region);
    }
  }, [place?.region, setRegionData]);

  useEffect(() => {
    if (place?.city) {
      setCityData(place?.city);
    }
  }, [place?.city, setCityData]);

  useEffect(() => {
    if (place?.district) {
      setDistrictData(place?.district);
    }
  }, [place?.district, setDistrictData]);

  const handleUpdateMerchantUser = async (values: FieldValues) => {
    try {
      setLoadingSubmit(true);
      const parsedPhone = parsePhoneNumber(values.phone);
      if (parsedPhone) {
        values.phoneDdi = parsedPhone.countryCallingCode;
        values.phone = parsedPhone.nationalNumber;
      }
      if (
        values?.districtId === "0" ||
        !districts.some((district) => district.value === values?.districtId)
      ) {
        values.districtName =
          values?.districtId === "0"
            ? districtDefault?.label
            : values?.districtId;
        delete values?.districtId;
      }
      await axios.post(urlMerchant, values);
      AlertMessage("success", "updateMerchant");
      setLoadingSubmit(false);
    } catch (err) {
      setLoadingSubmit(false);
      AlertMessage("error", "defaultMessage", err);
    }
  };

  const resetAddress = () => {
    setValue("countryId", "");
    setValue("address1", "");
    setValue("addressNumber", "");
    setValue("zipcode", "");
    setValue("regionId", "");
    setValue("cityId", "");
    setValue("districtId", "");

    setRegions([]);
    setCities([]);
    setDistricts([]);
  };

  if (loading) {
    return (
      <Box
        borderRadius="15px"
        border="1px solid #ddd"
        minHeight="calc(100vh - 62px - 16px - 16px)"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          justifyContent="space-between"
          p={4}
        >
          <Box display="flex" width={"100%"} gap={2}>
            <Skeleton
              variant="rectangular"
              sx={{ mr: "auto" }}
              width={"30%"}
              height={60}
            />
          </Box>
          <Box display="flex" gap={2}>
            <Box width={"100%"}>
              <Skeleton variant="rectangular" width={"100%"} height={60} />
            </Box>
            <Box width={"40%"}>
              <Skeleton variant="rectangular" width={"100%"} height={60} />
            </Box>
          </Box>
          <Box display="flex" gap={2}>
            <Box width={"100%"}>
              <Skeleton variant="rectangular" width={"100%"} height={60} />
            </Box>
            <Box width={"100%"}>
              <Skeleton variant="rectangular" width={"100%"} height={60} />
            </Box>
          </Box>
          <Box display="flex" gap={2}>
            <Box width={"100%"}>
              <Skeleton variant="rectangular" width={"100%"} height={60} />
            </Box>
            <Box width={"100%"}>
              <Skeleton variant="rectangular" width={"100%"} height={60} />
            </Box>
          </Box>
          <Box display="flex" width={"100%"} gap={2}>
            <Skeleton
              variant="rectangular"
              sx={{ ml: "auto" }}
              width={"30%"}
              height={60}
            />
          </Box>
        </Box>
      </Box>
    );
  }
  console.log("====================================");
  console.log(user);
  console.log("====================================");
  return (
    <Box display="flex" minHeight="calc(100vh - 62px - 16px - 16px)">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        display="flex"
        flexDirection="column"
        padding={4}
        border="1px solid #DDDDDD"
        borderRadius="15px"
        justifyContent={accessPermitted ? "space-between" : "unset"}
        width="100%"
        gap={2}
      >
        <Typography variant="body1" color="#000000">
          {t("editUserScreen")}
        </Typography>

        <Grid
          display="flex"
          width="100%"
          padding={4}
          border="1px solid #DDDDDD"
          borderRadius="15px"
          alignItems="center"
          justifyContent="center"
          flexDirection={{ xs: "column", lg: "unset" }}
          gap={2}
        >
          <Grid
            display="flex"
            width={{ xs: "120px", lg: "200px" }}
            height={{ xs: "140px", lg: "200px" }}
            padding={1}
            alignItems="center"
            justifyContent="center"
            border="1px solid #DDDDDD"
            borderRadius="15px"
          >
            <Avatar
              src={KahshLogo}
              variant="rounded"
              sx={{ width: "100px", height: "100px", m: "auto" }}
            />

            {/* <FileInput
              onChange={setImage}
              icon={<Person sx={{ width: "100px", height: "100px" }} />}
              accept="image/*"
            /> */}
          </Grid>

          <Grid container direction="column" gap={2}>
            <TextField label="name" control={control} name="name" disabled />
            <TextField
              label="corporateName"
              control={control}
              name="corporateName"
              disabled={!accessPermitted}
            />

            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              gap={2}
              width="100%"
            >
              <TextField
                label="email"
                control={control}
                helperText={errors?.email?.message}
                name="email"
                required
                type="email"
                disabled
              />
            </Box>
            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              gap={2}
              width="100%"
            >
              {isCpf ? (
                <TextFieldMask
                  mask="999.999.999-99"
                  label="CPF"
                  control={control}
                  helperText={errors?.taxid?.message}
                  name="taxid"
                  required
                  disabled
                />
              ) : (
                <TextFieldMask
                  mask="99.999.999/9999-99"
                  label="CNPJ"
                  control={control}
                  helperText={errors?.taxid?.message}
                  name="taxid"
                  required
                  disabled
                />
              )}
              <PhoneInput
                label="phone"
                control={control}
                helperText={errors?.phone?.message}
                name="phone"
                required
                disabled={!accessPermitted}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          width="100%"
          padding={{ xs: 2, md: 5 }}
          border="1px solid #DDDDDD"
          borderRadius="15px"
          gap={2}
        >
          <Grid item xs={12}>
            {accessPermitted ? (
              <GooglePlacesAutocomplete
                place={place}
                setPlace={(selectedPlace) => {
                  resetAddress();
                  setBackdrop(true);
                  setPlace(selectedPlace);
                }}
                placeholder={watch("address1")}
                error={errors?.address1?.message}
                required
              />
            ) : (
              <TextField
                label="address"
                control={control}
                name="address1"
                disabled
              />
            )}
          </Grid>

          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            gap={2}
            width="100%"
          >
            <Select
              label={t("country")}
              control={control}
              watch={setCountryData}
              helperText={errors?.countryId?.message}
              name="countryId"
              options={countries}
              required
              disabled={!accessPermitted}
            />

            <Select
              label="region"
              control={control}
              watch={setRegionData}
              helperText={errors?.regionId?.message}
              name="regionId"
              options={regions}
              required
              disabled={!accessPermitted}
            />
          </Box>

          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            gap={2}
            width="100%"
          >
            <Select
              label="city"
              control={control}
              watch={setCityData}
              helperText={errors?.cityId?.message}
              name="cityId"
              options={cities}
              required
              disabled={!accessPermitted}
            />

            <Select
              label={t("district")}
              control={control}
              watch={setDistrictData}
              helperText={errors?.districtId?.message}
              name="districtId"
              options={districts}
              newOptionDefaultValue={
                districts.some(
                  (district) => district.value === watch("districtId")
                ) && watch("districtId") !== "default"
                  ? ""
                  : watch("districtId")
              }
              addButton
              required
              disabled={!accessPermitted}
            />
          </Box>
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            gap={2}
            width="100%"
          >
            <TextFieldMask
              mask={"99.999-999"}
              label="zipCode"
              control={control}
              helperText={errors?.zipcode?.message}
              name="zipcode"
              required
              disabled={!accessPermitted}
            />

            <TextField
              label={t("number")}
              control={control}
              name="addressNumber"
              required
              helperText={errors?.addressNumber?.message}
              disabled={!accessPermitted}
            />
          </Box>
          <TextField
            label="address2"
            control={control}
            helperText={errors?.address2?.message}
            name="address2"
            disabled={!accessPermitted}
          />
        </Grid>
        {accessPermitted && (
          <BackAndSubmitButton
            titleSubmit="buttonUpdateYourData"
            onSubmit={handleSubmit(handleUpdateMerchantUser)}
            loading={loadingSubmit}
          />
        )}
      </Box>
    </Box>
  );
};

import { styled, ListItemButton, Select } from "@mui/material";

export const ListItemDrawer = styled(ListItemButton)`
  border-radius: 0.5rem;
  &.selected {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: #fff;
    &.MuiListItemIcon-root {
      color: #fff;
    }
    &:hover {
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  span {
    font-size: 1rem;
    font-weight: 600;
    white-space: nowrap;
  }

  p {
    font-size: 0.7rem;
    color: #787a7b;
    font-weight: 400;
  }

  &.subMenuItem,
  &.subMenuSelected {
    color: ${({ theme }) => theme.palette.grey[400]} !important;
    margin-left: ${({ theme }) => theme.spacing(1.5)};
  }
  &.subMenuSelected {
    color: ${({ theme }) => theme.palette.primary.main} !important;
  }
`;

export const LanguageSelector = styled(Select)`
  .MuiOutlinedInput-notchedOutline {
    border-width: 0 !important;
    background-color: transparent;
  }

  .MuiSelect-select {
    display: flex;
    align-items: center;

    p {
      display: none;
    }
  }
`;

import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";

import axios from "axios";
import { baseURL, urlInvoiceList } from "../../services/global";

import { useParams } from "react-router-dom";
import { useAlert } from "../../hooks/alert";
import { currencyFormatter } from "./../../hooks/currencyFormatter";
import {
  Cell,
  ListComponent
} from "../../components/listComponent/ListComponent";
import { Visibility } from "@mui/icons-material";
import dataFormat from "../../hooks/dataFormat";
import { StatusLabel } from "../../components/statusLabelExtract/StatusLabel";
import { InvoiceDetailDrawer } from "../../components/InvoiceDetailDrawer/InvoiceDetailDrawer";
import { downloadCSV } from "../../utils/downloadInvoiceListCSV";
import { SearchInvoiceDrawer } from "../../components/searchInvoiceDrawer/SearchInvoiceDrawer";
import { Invoice } from "../../data/models/Invoice";
import { SearchInput } from "../../components/searchInput/SearchInput";

const cells: Cell[] = [
  {
    id: "customer_name",
    label: "customerName",
    size: 0.4,
    button: true
  },
  {
    id: "invoice_amount",
    label: "customerValue",
    size: 0.25,
    button: true,
    format: (value) => (
      <Typography ml={{ xs: 0, md: 1.6 }} variant="body2">
        {value.invoice_currency.iso_code}{" "}
        {currencyFormatter(value.invoice_amount)}
      </Typography>
    )
  },
  {
    id: "created",
    label: "transactionDate",
    size: 0.32,
    button: true,
    format: (value) => (
      <Typography ml={{ xs: 0, md: 3.4 }} variant="body2">
        {dataFormat(value.created)}
      </Typography>
    )
  },
  {
    id: "status",
    label: "STATUS",
    size: 0.23,
    button: true,
    format: (value) => (
      <StatusLabel
        value={value.status}
        sx={{ ml: { xs: 0, md: "calc(35px + 10%)" } }}
      />
    )
  }
];

const DashboardInvoiceList = () => {
  const { t } = useTranslation();
  const { AlertMessage } = useAlert();
  const params: any = useParams();

  const [search, setSearch] = useState<any>("");
  const [openDetail, setOpenDetail] = useState(params?.id || "");
  const [data, setData] = useState<Invoice[]>([]);
  const [loading, setLoading] = useState(true);

  const handleSearch = async (query: string) => {
    try {
      setLoading(true);
      const { data } = await axios.get(urlInvoiceList + query);
      setData(data);
      setLoading(false);
    } catch (e) {
      AlertMessage("error", "unkownError");
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(baseURL + "/invoice/list");
        setData(data);
        setLoading(false);
      } catch (error) {
        AlertMessage("error", "invoiceNotFound", error);
      }
      setLoading(false);
    };
    fetchData();
  }, [AlertMessage]);

  const filteredData =
    data?.length && search
      ? data?.filter((invoice) => {
          return (
            invoice.customer_name.includes(search) ||
            invoice.customer_email.includes(search) ||
            invoice.id.includes(search) ||
            invoice.documentno.includes(search) ||
            invoice.merchant_invoice_id.includes(search) ||
            invoice.paymentMethod.name.includes(search) ||
            invoice?.items?.some(
              (item) =>
                item.name?.includes(search) ||
                item.description?.includes(search)
            )
          );
        })
      : data;

  return (
    <>
      <InvoiceDetailDrawer
        open={openDetail}
        setOpen={() => setOpenDetail("")}
      />
      <SearchInvoiceDrawer
        onSearch={handleSearch}
        onDownload={() => downloadCSV(data)}
        loading={loading}
      />
      <Grid
        container
        minHeight="calc(100vh - 62px - 16px - 16px)"
        border="1px solid #DDDDDD"
        borderRadius="15px"
      >
        <Box display="flex" flexDirection="column" p={4} width="100%">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexDirection={{ xs: "column", md: "row" }}
            gap={2}
          >
            <Typography variant="body1" color="#000000">
              {t("invoiceList")}
            </Typography>
            <SearchInput onChange={setSearch} />
          </Box>
          {loading ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mt={4}
              gap={2}
            >
              <Typography variant="body2" color="#000000">
                {t("loading")}
              </Typography>
              <CircularProgress />
            </Box>
          ) : (
            <Box mt={4}>
              <ListComponent
                cells={cells}
                rows={filteredData}
                dynamicId="id"
                initialOrder={{
                  orderBy: "id",
                  direction: "asc"
                }}
                noDataText="noInvoiceData"
                button={{
                  label: "Detalhes",
                  action: (invoice) => setOpenDetail(invoice.id),
                  icon: <Visibility sx={{ color: "#B4B4C1" }} />
                }}
                onDoubleClick={(invoice) => setOpenDetail(invoice.id)}
              />
            </Box>
          )}
        </Box>
      </Grid>
    </>
  );
};

export default DashboardInvoiceList;

import { styled, Box } from "@mui/material";

export const BackgroundBar = styled(Box)`
  background: ${({ theme }) => theme.palette.primary.main};
  position: fixed;
  right: 0;
  height: 100%;
  width: 40vw;

  ${({ theme }) => theme.breakpoints.down("md")} {
    display: none;
  }
`;

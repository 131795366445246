import axios from "axios";
import { urlInvoice, urlInvoicePay } from "./global";

interface Invoice {
  invoice: {
    id: string;
    items: any[] | undefined;
    isvalid: boolean;
    ispaid: boolean;
    customer_email: string;
    customer_name: string;
    kahsh_quotation: string;
    netamount: string;
    wallet_address: string;
    merchant_id: any;
    merchant_invoice_id: string;
    invoice_id: any;
    fee: string;
    fee_amount: string;
    amount: string;
    invoice_amount: string;
    validto: string;
    currency: {
      iso_code: string;
      precision: number;
    };
    invoice_currency: {
      iso_code: string;
      precision: number;
    };
    return_url: string;
    isprocessing: boolean;
  };
}

class CheckoutServices {
  async PostMyCheckout(user: string, pwd: string, invoice: String | null) {
    return await axios.post(urlInvoicePay, {
      username: user,
      password: pwd,
      invoiceCode: invoice
    });
  }
  async getMyCheckout(codeInvoice: String | null) {
    const invoice: Invoice = await axios
      .get(urlInvoice + `?invoiceCode=${codeInvoice}`)
      .then((res: any) => {
        return res.data;
      })
      .catch((err) => {
        if (err?.response?.data?.errorCode === 104) {
          return (window.location.href = "/invoicenotfound");
        }
        return err;
      });
    return invoice;
  }
}

export default new CheckoutServices();

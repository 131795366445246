export const TRANSLATIONS_PT = {
  // ============= Global =============
  hourFormat: "HH:mm",
  dateFormat: "dd/MM/yyyy",
  currentCountry: "BR",
  language: "pt-BR",
  knowMore: "Saiba mais",
  bePartOfIt: "Faça parte",
  register: "Cadastrar-se",
  fastRegister: "Cadastro rápido",
  incompleteRegister:
    "Cadastro incompleto, por favor cadastre-se e tente novamente.",
  enter: "Entrar",
  createAnAccount: "Criar conta",
  notHaveAccount: "Ainda não tem uma conta?",
  forgotPassword: "Esqueceu a senha?",
  siginUp: "Cadastre-se",
  user: "USUÁRIO",
  merchantLogin: "Login do lojista",
  verifyYourEmail: "Verifique seu e-mail",
  resetPassword: "Redefinir senha",
  forgotPassord: "Esqueceu a senha?",
  messageCopy: "Copiado com sucesso para área de transferência",
  copy: "Copiar",
  resendEmail: "REENVIAR E-MAIL",
  fullName: "Nome completo",
  spamVerify: "*Verifique sua caixa de spam",
  removeItemCertain: "Tem certeza de que deseja remover este item?",
  addOption: "Adicionar Opção",
  registerUser: "Cadastrar Usuário",
  fetchInvoice: "Buscar fatura",
  paid: "Pagas",
  pending: "Pendentes",
  canceled: "Canceladas",
  applyFilter: "Aplicar filtro",
  useCookiesExperience:
    "Utilizamos cookies para fornecer uma melhor experiência para nossos usuários.",
  toAccept: "Aceitar",
  backToStart: "Voltar ao Início",
  noDocumentNo: "Sem número de documento.",
  // ============= LABELS =============
  email: "Email",
  corporateEmail: "Email Corporativo",
  cancel: "Cancelar",
  next: "Proximo",
  confirm: "Confirmar",
  cadastrar: "Cadastrar",
  confirmPassword: "Confirmar senha",
  confirmEmail: "Confirme e-mail",
  password: "Senha",
  name: "Nome",
  corporateName: "Nome Fantasia",
  segment: "Segmento",
  phone: "Telefone",
  birthDateShop: "Data de Fundação",
  document: "CPF/CNPJ",
  country: "País",
  address: "Endereço",
  number: "Número",
  zipCode: "CEP",
  city: "Cidade",
  district: "Bairro",
  region: "Estado",
  address2: "Complemento",
  edit: "Editar",
  birthDate: "Data de Nascimento",
  permissions: "Permissões",
  selectAll: "Selecionar Tudo",
  searchUser: "Buscar Usuário",
  loading: "Carregando",
  data: "Dados",
  users: "Usuários",
  role: "Regra de Acesso",
  none: "Nenhum",
  inactivate: "Inativar",
  activate: "Ativar",
  gender: "Gênero",
  owner: "Proprietário",
  searchInvoice: "Buscar fatura",
  noInvoiceData: "Nenhuma fatura encontrada",
  invoices: "Faturas",
  Date: "DATA",
  search: "Buscar",
  active: "Ativo",
  inactive: "Inativo",
  balance: "Saldo",
  general: "Geral",
  periodReceive: "Período a receber",
  rewards: "Recompensas",

  // ============= Email Verify =============
  subtitleEmailVerify: `Sua conta foi criada com sucesso. Para concluir o processo, verifique seu e-mail para uma solicitação de validação.`,
  homeScreen: "Tela inicial",

  // ====== verifyEmail translate
  verifyEmailSuccess: "Email verificado com sucesso",
  successCreated: "Sua conta foi criada com sucesso.",
  autoLogin: "Entrando no sistema em ",
  verifiedEmail: "Email verificado!",

  // ============= Password Reset =============
  passwordReset: "Redefinição de senha",
  enterEmail: "Por favor, digite seu email",
  emailSent:
    "Se o email existir será encaminhado um link para redefinir sua senha, por favor verifique seu email.",

  //============= DashBoard =============
  dashboard: "Painel",
  createInvoice: "Criar Fatura",
  invoiceList: "Lista de Faturas",
  settings: "Configurações",
  accountSettings: "Configurar conta",
  logout: "Sair",
  extract: "Extrato",
  support: "Suporte",
  historic: "Histórico",
  editFinancial: "Editar Financeiro",
  editPassword: "Editar Senha",

  // ============= DashBoard Metrics=============
  editProfile: "EDITAR PERFIL",
  invoicesPaid: "Faturas pagas",
  invoicePaid: "Fatura paga",
  statusPaid: "Pago",
  statusCanceled: "Cancelado",
  statusPending: "Pendente",
  statusDefaulter: "Em atraso",
  invoicePaidFor: "QUANTIA PAGA PARA",
  invoicesPending: "Faturas pendentes",
  invoiceProcessing: "Processando pagamento",
  totalInvoices: "Total de faturas",
  recentTransactions: "Transações Recentes",
  recentlyPaid: "Pagas recentemente",
  yourPasswordHasBeenReset: "Sua senha foi redefinida com sucesso",
  filterByDate: "Filtrar por data",
  chooseDate: "Escolha a data",
  chooseAuthenticationMethod: "Escolha a forma de autenticação:",
  initDate: "Data inicial",
  finalDate: "Data final",
  dateError: "A data final não pode ser menor que a data inicial!",

  //============= DashBoard Settings=============
  createUser: "Criar Usuário",
  logoUpdate: "ATUALIZE SUA LOGO",
  editAccount: "Editar Conta",
  reTypePassword: "Confirmação de Senha",
  updatePassword: "Atualize sua Senha",
  merchantInfo: "Informações do Lojista",
  businessName: "Nome da Empresa",
  emailAddress: "Endereço de Email",
  phoneNum: "Número de Telefone",
  walletInfo: "Informações da Carteira",
  walletAddress: "Endereço da Carteira",
  twoFaAuth: "AUTENTICAÇÃO DOIS FATORES",
  twoFaErrorMessage: "Código 2FA inválido",
  twoFAauthmessage: "Digite o código de 6-dígitos de seu autenticador.",
  twoFaMessage:
    "O código 2FA é obrigatório e caso ainda não o possuir por favor acessar Minha conta da",
  twoFaMessage2: "na aba Autenticação 2FA e faça a ativação.",
  twoFaMessageCheckout:
    "Para sua segurança digite os números do autenticador para concluir o pagamento",
  auth: "AUTENTICAÇÃO",
  updateInfo: "Atualizar Informações",
  apiInfo: "Informações da API",
  apiKey: "Chave da API",
  apiSecret: "Segredo da API",
  nullFields:
    "Por favor verifique se os campos usuário e senha estão preenchidos corretamente.",
  fillAllFields: "Por favor preencha todos os campos.",
  changePassword: "Alterar Senha",
  passwordActual: "Senha atual",

  createAPI: "Criar API",
  wallet: "Carteira",

  //============= DashBoard Invoice =============

  createCostumer: "Criar Fatura de Consumidor",
  invoiceSearch: "Buscar fatura",
  csvExport: "Exportar em CSV",
  transactionDate: "DATA DE TRANSAÇÃO",
  transactionDateLowerCase: "Data de transação",
  customerName: "NOME DO CLIENTE",
  lowerCaseCustomerName: "Nome do cliente",
  customerEmail: "E-MAIL DO CLIENTE",
  lowerCaseEmail: "E-mail do cliente",
  customerDocument: "NUMERO DO DOCUMENTO",
  lowerCaseCustomerDocument: "Número do documento",
  invoiceValidTo: "Validade da Fatura",
  customerValue: "VALOR TOTAL",
  lowerCaseCustomerValue: "Valor Total",
  customerCoin: "MOEDAS",
  lowerCaseCustomerCoin: "Moedas",
  invoiceCode: "Código da Fatura",
  coin: "Moeda",
  checkoutUrl: "Url para Checkout",
  addItem: "Adicionar itens",
  removeItem: "Remover item",
  items: "Item",
  itemDescription: "DESCRIÇÃO DO ITEM",
  lowerCaseItemDescription: "Descrição do Item",
  itemsDescription: "Descrição dos itens",
  noInvoiceItems: "Não há itens disponíveis para listar",
  unitaryValue: "VALOR UNITÁRIO",
  value: "Valor",
  unitaryValueLowerCase: "Valor Unitário",
  invoiceData: "Dados da Fatura",
  qty: "QUANTIDADE",
  qtyLowerCase: "Quantidade",
  totalItem: "TOTAL DO ITEM",
  totalItems: "Total dos Itens",
  zeroInvoiceItems: "Não há itens adicionados na fatura.",
  toastError:
    "Erro ao criar fatura, por favor verifique todos os campos e tente novamente.",
  invoiceID: "ID FATURA",
  amount: "QUANTIA",
  description: "DESCRIÇÃO",
  descriptionLowerCase: "Descrição",
  seeAll: "Ver Tudo",

  //============= Payment Page =============
  nameError: "Nome completo inválido",
  documentError: "Documento inválido",
  documentEqualsError: "Já existe um usuário com esse CPF cadastrado.",
  or: "ou",
  exchangeRate: "Cotação da exchange",
  successfullCreateAccount: "Conta criada com sucesso",
  successfullCreateAccountBody:
    "Você recebera um e-mail para confirmar o seu cadastro",
  howDoYou: "Como você gostaria de pagar",
  finish: "Finalizar",
  rewardAmount: "Recompensa",
  feesAmount: "Taxas",
  netAmount: "Total líquido",
  fastRegisterBody:
    "Compre, venda e gerencie os seus fundos a qualquer momento e de qualquer lugar.",
  paymentFor: "PAGAMENTO PARA:",
  stillNotKahsh: "Ainda não tem Kahsh?",
  buyHereNow: "Compre agora e pague com Pix",
  hasKahsh: "Tenho Kahsh na Metamask",
  notEnoughCoins: "Saldo insuficiente para realizar o pagamento",
  connectMetaMask: "Conectar Metamask",
  haveYouBoughtFromExchange: "Já comprou na Kahsh Exchange?",
  seller: "Vendedor",
  timeLeft: "Tempo restante",
  requestPayment: `PEDIDO DE PAGAMENTO POR KAHSH`,
  infoPayment1: `JÁ TEM KAHSH NA SUA CARTEIRA?`,
  infoPayment2: `Escaneie o QRCode com sua carteira`,
  infoPayment3: `AINDA NÃO TEM KAHSH?`,
  infoPayment4: `Clique em comprar Kahsh abaixo`,
  clickToCopy: "Clique aqui para copiar",
  payWithKahsh: "Pagar na Carteira",
  buyKahsh: "Comprar Kahsh",
  paymentMethods: "Formas de pagamento",
  payWithMetamask: "Pague direto da sua carteira",
  payWithWallet: "Pague com a carteira da Exchange",
  buyYourKahsh: "Compre Kahsh \n agora na Exchange",
  paymentAvailableUtil: "PAGAMENTO DISPONÍVEL ATÉ: ",
  copyAddress: "Copiar carteira Kahsh",
  copyAddressCheckout: "Copiar endereço:",
  invoiceDatils: "DETALHES DA FATURA",
  invoiceDetailsLowerCase: "Detalhes da fatura",
  totalItens: "TOTAL DE ITENS",
  feesPayment: "TAXAS",
  feesPaymentLowerCase: "Taxas",
  amountLowerCase: "Quantidade",
  subTotalPayment: "SUBTOTAL",
  subTotalPaymentLowerCase: "Subtotal",
  makePayment: "Efetuar pagamento",
  paymentSuccess: "Pagamento já efetuado!",
  paymentCanceled: "Pagamento cancelado!",
  ordered: "Pedido realizado em:",
  back: "Voltar",
  backToStore: "Voltar para a loja",

  //============= Merchant Account Settings =============
  localShop: "Loja local",
  updateYourLogo: "Atualize seu logotipo",
  updateInformation: "Atualizar informação",
  retypePAssword: "Digite novamente a senha",

  //============= Register =============
  merchantName: "Nome do lojista",
  kshWalletAddress: "Endereço da carteira KSH",
  bySigningUp: "Ao se inscrever, você concorda com o KahshPay ",
  termsOfService: "Termos do serviço e política de privacidade.",
  alreadyHaveAnAccount: "já tem uma conta? ",
  signIn: "Entrar.",
  dontHaveAccountSiginUp: "Não tem uma conta ainda? ",
  signUp: "Inscreva-se!",

  //============= AccountCreatedEmailSend =============
  checkYourSpamBox: "*Verifique sua caixa de spam",
  accountSuccessfullyCreated: "Sua conta foi criada com sucesso.",
  enterSytem: "ENTRAR NO SISTEMA",

  LoggingIntoSystem: "Entrando no sistema em 5s",

  //============= EmailVerifySuccess =============

  emailSuccessfullyVerified: "E-mail verificado com sucesso",

  //============= Compre com Kahsh =============
  valueInReais: "Valor em Reais",
  valueInUppercase: "VALOR EM REAIS",
  valueInKsh: "Valor em KSH",
  makeYourQuickAccount: "Faça sua conta rápida",
  here: "aqui",
  generatePix: "Gerar pix",
  generatePixError: "Erro ao gerar Pix, por favor tente novamente",
  generateQrCode: "Gerar QRCode",
  payWithOtherWallet: "Pagar por outra carteira",
  PrivacyTerms1: "Li e concordo com os ",
  PrivacyTerms2: "Termos de Uso ",
  PrivacyTerms3: "Política de Privacidade.",
  and: "e ",
  isNotStrongPassword:
    "Sua senha precisa ter ao menos: \n - 8 dígitos  \n - 1 letra minúscula \n - 1 letra maiúscula \n - 1 número \n - 1 símbolo!",
  senhaDoesNotMatch: "Senha não confere!",
  emailDoesNotMatch: "Email não confere!",
  invalidCPF: "CPF inválido!",
  pixCopyPaste: "Copiar Pix",
  KSHAddress: "Carteira Kahsh",
  PIXAddress: "Endereço PIX",

  // =========== CREATE MERCHANT =============
  startFree: "COMECE GRÁTIS",
  createMerchantTitle: "Conte-nos um pouco sobre sua loja",
  createMerchantSubtitle1: "Esta é informação inicial sobre o seu negócio.",
  createMerchantSubtitle2: "Você pode alterar a qualquer momento.",
  imageLabel: "Chegou a hora de crescer seu negócio online",
  stepShopLabel: "Sua Loja",
  stepAddressLabel: "Endereço",
  stepSegmentLabel: "Segmento",
  stepSecurityLabel: "Segurança",
  stepFinishLabel: "Finish",
  step: "Passo",
  of: "de",
  kahshEcosystem: "Kahsh Ecosystem",
  alreadyHaveEcosystemAccount1: "Se você já possui uma conta no",
  alreadyHaveEcosystemAccount2: "use o mesmo e-mail e senha!",
  agreeTerms1: "Li e concordo com os",
  agreeTerms2: "Termos de Uso",
  agreeTerms3: "e",
  agreeTerms4: "Politica de Privacidade",

  // =========== COMPLIANCE =============
  stepMerchantData: "Dados do lojista",
  stepDocumentFile: "Documentos",
  stepSelfieFile: "Selfie",
  stepAddressFile: "Endereço",
  stepSocialContract: "Contrato Social",
  successfullyRegisterTitle: "Cadastro Efetuado",
  successfullyRegister: "Seu cadastro foi efetuado com sucesso",
  waitAnalysis: "Aguarde a análise e aprovação de seus documentos.",
  viewStatus: "Ver status do Cadastro",
  useValidDocument: "Use um documento de identificação válido",
  onlyDocumentList:
    "Apenas os seguintes documentos listados abaixo serão aceitos.",
  anyOtherReject: "Todos os outros serão rejeitados.",
  selectDocument:
    "Escolha o arquivo no seu computador e certifique-se de que todas as informações estejam nítidas.",
  validDocument:
    "Certifique-se de que seja um documento valido e que todas as informações estejam nítidas.",
  selfieWithDoc: "Selfie com Documento de identificação",
  selfieInfos:
    "Para validar sua conta, tire uma foto de seu rosto e ao lado segurando o seu documento aberto!",
  doNotUseGlasses: "Não utilizar óculos ou acessórios",
  doNotUseCap: "Não utilizar chapéu, boné ou toucas",
  illuminatedPlace: "Esteja em lugar iluminado",
  useSameDoc: "Documento precisa ser o mesmo enviado no passo anterior",
  doNotCoverYourFace: "Documento não deve cobrir o rosto",
  proofAddress: "Comprovante de endereço",
  yourNameDoc: "É necessário que o documento esteja em seu nome.",
  addProofAddress: "Anexar comprovante de endereço",
  socialContract: "Contrato social",
  addSocialContract: "Anexar contrato social",

  approvedTitle: "Aprovado",
  approvedSubtitle: "Seu cadastro foi aprovado com sucesso",
  approvedDate: "Data da Aprovação",

  pendingTitle: "Pendente",
  pendingSubtitle: "Seu cadastro está aguardando análise, Por favor, aguarde!",
  pendingDate: "Data do Cadastro",

  rejectedTitle: "Rejeitado",
  rejectedSubtitle: "Infelizmente o seu cadastro não foi aprovado!",
  rejectedDate: "Data da Rejeição",
  rejectedReason: "Motivo da Rejeição",

  analysisTitle: "Em análise",
  analysisSubtitle:
    "O seu cadastro está em sendo analisado... Por favor, aguarde!",
  analysisDate: "Data do Cadastro",

  // =========== NOT FOUND =============
  notFoundTitle: "FATURA NÃO  \n ENCONTRADA",
  invoiceNotFound: "Fatura não encontrada",
  notFoundSubtitle:
    "Nós não conseguimos encontrar a fatura que você  \n pesquisou. Tente pesquisar novamente.",

  // =========== NOT FOUND 2 =============
  pageNotFound: "Página não encontrada!",
  pageNotFoundSubtitle: "A página que você está procurando não foi encontrada",
  notFoundTitle2: "Imagem página não encontrada",

  // ============= VALIDATORS REQUIRED=============
  required: "Obrigatório",
  nameRequired: "Nome é obrigatório",
  corporateNameRequired: "Nome Fantasia é obrigatório",
  descriptionRequired: "Descrição é obrigatória",
  valueRequired: "Valor é obrigatório",
  amountRequired: "Quantidade é obrigatória",
  phoneRequired: "Telefone é obrigatório",
  emailRequired: "Email é obrigatório",
  documentRequired: "CPF/CNPJ é obrigatório",
  addressRequired: "Endereço é obrigatório",
  countryRequired: "País é obrigatório",
  segmentRequired: "Segmento é obrigatório",
  addressNumberRequired: "Numero é obrigatório",
  zipCodeRequired: "CEP é obrigatório",
  regionRequired: "Estado é obrigatório",
  cityRequired: "Cidade é obrigatória",
  DistrictRequired: "Bairro é obrigatório",
  passwordRequired: "Senha é obrigatória",
  confirmPasswordRequired: "Confirmação de senha é obrigatória",
  birthDateShopRequired: "Data de Fundação é obrigatória",
  birthDateRequired: "Data de Nascimento é obrigatória",
  totalValueRequired: "Valor Total é obrigatório",
  agreeRequired:
    "Você precisa aceitar os termos e politica de privacidade para prosseguir",

  // ============= VALIDATORS =============
  addressAutoCompleteInvalid: "O endereço selecionado não possui nome da rua",
  cpfInvalid: "CPF invalido!",
  cnpjInvalid: "CNPJ invalido!",
  zipCodeInvalid: "CEP invalido!",
  phoneInvalid: "Telefone invalido!",
  fullNameInvalid: "Nome incompleto!",
  invalidName: "Nome deve conter apenas letras",
  invalidCorporateName: "Nome Fantasia deve conter apenas letras",
  emailInvalid: "Email inválido!",
  birthDateShopFuture: "Data de fundação não pode ser no futuro!",
  birthDateFuture: "Data de nascimento não pode ser no futuro!",
  passwordEqualsError: "As senhas não conferem",
  invalidActualPassword: "Senha atual inválida.",
  invalidPassword: "Senha inválida.",
  invalidDate: "Data invalida",
  maximumSize: "O tamanho do arquivo é maior que o máximo permitido de 5mb",
  unkownError:
    "Erro desconhecido. Contate um administrador ou tente novamente mais tarde.",

  // ====== changePassword translate
  // ====== changePassword translate
  enterNewPassword: "Por favor insira sua nova senha",
  newPassword: "Nova senha",
  save: "Salvar",
  oneUpperCase: "Deve conter ao menos uma letra maiúscula!",
  oneLowerCase: "Deve conter ao menos uma letra minúscula!",
  oneNumber: "Deve conter um número!",
  eightDigits: "Deve possuir mais que 8 dígitos.",
  passwordsMatch: "As senhas não coincidem.",
  changePasswordSuccess: "Mudança de senha bem sucedida!",
  invalidToken: "Token inválido.",

  //============= ERROR =============
  error400:
    "Email ou senha incorretos, favor verifique seus dados e tente novamente.",
  error10caracteres:
    "É necessário conter nome e sobrenome e ao menos 10 characters",
  noPermissionsFound:
    "É necessário marcar permissões ou selecionar uma Regra de Acesso para o usuário",
  //============= ERROR =============
  defaultMessage: "Ops! Algo deu errado, tente novamente mais tarde",
  naohafaturaspendentes: "Não há faturas pendentes",
  naoexistemtransacoesrecentes: "Não existem transações recentes",
  "Passwordandemaildonotmatch.": "Email e senha não conferem",
  "Useralreadyexistsinthedatabase.": "Usuário já cadastrado",
  "TaxIDviolatesuniqueconstraint.":
    "CPF/CNPJ já está sendo utilizado por outro usuário",
  Accounthasbeentemporarilydisabledduetomanyfailedloginattempts:
    "Conta foi temporariamente desativada devido a quantidade de tentativas falhas de login. Atualize sua senha.",
  Youremailneedstobeverified: "Seu e-mail precisa ser verificado.",
  FAcodenotprovided: "Código de Autenticação de dois fatores não inserido.",
  Incompleteregister: "Registro incompleto.",

  RoleIDnotfound: "ID de cargo não encontrado.",
  Rolenotfound: "Cargo não encontrado.",
  Invoicenotfound: "Fatura não encontrada.",
  Userpasswordincorrect: "Senha do usuário incorreta.",
  Invalidactiontoken: "Token Inválido.",
  TaxIDviolatesuniqueconstraint: "ID não é único.",
  Itisnecessarytoputinvoice_amountoritems:
    "É necessário inserir quantidade na fatura ou nos itens.",
  Currenciescannotbyconverted: "Moedas não podem ser convertidas.",
  Errorgetingthetransaction: "Erro ao conseguir a transação.",
  Walletdonotmatch: "As carteiras não coincidem.",

  //=============== METAMASK =============
  metaMaskDeny: "Por favor autorize a Metamask para continuar com o pagamento.",
  metaMaskNotInstalled: `Por favor instale o Metamask ou ative a extensão, para mais informações acesse https://metamask.io`,
  gasError:
    "Aparentemente você está sem gás para realizar a transação, por favor verifique os valores em carteira e tente novamente.",
  metaMaskError:
    "Erro ao abrir a Metamask, por favor revise os valores e tente novamente.",

  //============= APIs =============
  apiList: "Lista de APIs",
  newApi: "Nova API",
  manageApi: "Gerenciar API",
  manageUser: "Gerenciar Usuários",
  userName: "Nome do usuário",
  type: "Tipo",
  people: "Pessoas",
  searchSustomers: "Pesquisar clientes",
  addNewCustomers: "Incluir novos clientes",
  editNewCustomers: "Editar novos clientes",
  deleteCustomers: "Excluir clientes",
  customerContract: "Contrato de clientes",
  customerAgreementAlert: "Contrato de clientes - Alerta",
  bank: "Banco",
  searchBanks: "Pesquisar bancos",
  addNewBanks: "Incluir novos bancos",
  editNewBanks: "Editar novos bancos",
  excludeBanks: "Excluir bancos",
  account: "Conta",
  searchAccounts: "Pesquisar contas",
  addNewAccounts: "Incluir novas contas",
  editNewAccounts: "Editar novas contas",
  deleteAccounts: "Excluir contas",
  cities: "Cidades",
  searchCities: "Pesquisar cidades",
  addNewCities: "Incluir novas cidades",
  EditNewCities: "Editar novas cidades",
  excludeCities: "Excluir cidades",
  applayToken: "token de aplicação",
  applaykey: "Chave de aplicação",

  //============= MErchant List =============
  userList: "Lista de Usuários",
  newUser: "Novo Usuário",
  editUser: "Editar Usuário",
  registrationSccessful: "Registro feito com sucesso!",
  successfullyDeleteUser: "Usuário inativado com sucesso",

  //============= Edit User =============
  editUserScreen: "Usuário",
  companyName: "Nome da Empresa",
  buttonUpdateYourData: "Atualize seus dados",
  updateMerchant: "Loja atualizada com sucesso",
  selectCountry: "Selecione um país para prosseguir",

  //============= CAMPO DE BUSCA LATERAL =============
  invoicesCode: "Código da fatura",
  invoiceId: "Id da fatura",
  documentNumber: "Número do documento",
  cleanFilter: "Limpar filtros",
  quickPaymentLink: "Link de pagamento rápido",

  //============= ROLE =============
  successfullyCreateRole: "Regra de Acesso criado com sucesso",
  successfullyInactiveRole: "Regra de Acesso inativado com sucesso",
  createRole: "Criar Regra de Acesso",
  editRole: "Editar Regra de Acesso",
  searchRole: "Buscar Regra de Acesso",
  roleList: "Lista de Regras de Acesso",
  noUserData: "Nenhum usuário encontrado",
  noRoleData: "Nenhuma Regra de Acesso encontrado",
  noData: "Nenhum Dado encontrado",
  passwordRecoverSubtitle: "Digite seu e-mail para recuperar sua senha",
  wannaLogin: "Gostaria de entrar?",
  enterSub: "Login!",
  //============= ERROR =============
  EMAIL_NOT_FOUND: "Email não encontrado",
  passwordSuccessReset: "Sucesso no envio de recuperação de senha!",
  passwordResetSuccessfully: "Senha redefinida com sucesso!",
  Usernotfound: "E-mail e/ou senha incorretos.",
  //============= API =============
  createApi: "Criar API",
  editApi: "Editar API",

  //============= access denied =============
  accessDeniedTitle: "AlGO DEU ERRADO",
  accessDeniedSubtitle1:
    "Não foi possivel decifrar a rota da página que você está procurando ",
  accessDeniedSubtitle2: "ou você não tem permissão para decolar.",
  accessDeniedSubtitle3: " Recalcule a rota!"
};

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { urlDashboard } from "../../services/global";

import {
  Box,
  Grid,
  InputAdornment,
  Modal,
  Skeleton,
  TextField,
  Typography,
  IconButton,
  Button
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

import InfoIcon from "../../images/info.png";
import InvoiceImg from "../../images/Invoice.png";
import kahshLogoSvg from "../../images/kahshLogoSvg.svg";

import axios from "axios";

import DashboardChart from "./Dashboard-Chart";

import coinFormat from "../../hooks/coinFormat";
import dataFormat from "../../hooks/dataFormat";

import { TextFieldStyled } from "../../components/inputs/text-field/text-field.styles";
import { useAuth } from "../../contexts/authContext";

import "./Dashboard.css";
import { isValid, format } from "date-fns";
import { capitalize } from "../../utils/capitalize";
import { InvoiceDetailDrawer } from "../../components/InvoiceDetailDrawer/InvoiceDetailDrawer";
import { Invoice } from "../../data/models/Invoice";

const DashboardMetrics = () => {
  const { t, i18n } = useTranslation();
  const { merchant } = useAuth();

  interface IInvoicesArray {
    paid: Array<any>;
    pending: Array<any>;
    total: number;
  }

  const [loading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invoices, setInvoices] = useState<IInvoicesArray>({
    paid: [],
    pending: [],
    total: 0
  });

  const [invoicesPerPeriod, setInvoicesPerPeriod] = useState<any>("");
  const [totalPaidInvoices, setTotalPaidInvoices] = useState<number>(0);
  const [totalInvoices, setTotalInvoices] = useState({ paid: 0, pendings: 0 });

  const [date, setDate] = useState<any>({
    initDate: null,
    finalDate: null
  });

  const [modal, setModal] = useState<boolean>(false);

  const [dateError, setDateError] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState("");

  const handleClose = () => {
    setModal(false);
  };

  useEffect(() => {
    if (isValid(new Date(date.initDate)) && isValid(new Date(date.finalDate))) {
      if (date.finalDate < date.initDate) return setDateError(true);
      setModal(false);
      setDateError(false);
    }

    handleGraph(date.initDate, date.finalDate);
  }, [date.initDate, date.finalDate]);

  useEffect(() => {
    handleDashboard();
  }, []);

  const handleGraph = async (initDate: any, finalDate: any) => {
    if (!initDate) initDate = new Date(0);
    if (!finalDate) finalDate = new Date();

    await axios
      .get(
        `${urlDashboard}/evolution?isoCode=BRL&initDate=${initDate.toISOString()}&finalDate=${finalDate.toISOString()}`,
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            "Access-Control-Allow-Origin": "*"
          }
        }
      )
      .then((res) => {
        setInvoicesPerPeriod(res.data);
        let total = 0;

        for (let i = 0; i < res.data.length; i++) {
          total += Number(res.data[i].netamount);
        }

        setTotalPaidInvoices(total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function handleDashboard() {
    setIsLoading(true);
    axios
      .get(urlDashboard + "?limitPaid=3&limitPending=5", {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "Access-Control-Allow-Origin": "*"
        }
      })
      .then((response) => {
        let paids = 0,
          pendings = 0;

        for (let i = 0; i < response.data.total_invoices.length; i++) {
          if (response.data.total_invoices[i].status === "Paids")
            paids = response.data.total_invoices[i].qtd;

          if (response.data.total_invoices[i].status === "Pendings")
            pendings = response.data.total_invoices[i].qtd;
        }

        paids = Number(paids);
        pendings = Number(pendings);

        setInvoices({
          paid: response.data.paid_invoices,
          pending: response.data.pending_invoices,
          total: paids + pendings
        });

        setTotalInvoices({
          paid: paids,
          pendings: pendings
        });

        setIsLoading(false);
      })
      .catch((error) => {
        console.log("erro do login", error);
      });
  }

  return (
    <Grid
      container
      direction="column"
      minHeight="calc(100vh - 62px - 16px - 16px)"
    >
      <Modal open={modal} onClose={handleClose}>
        <Box className="modal-box-container">
          <Grid container>
            <Box className="datepicker-box">
              <Typography className="datepicker-text">
                {t("initDate")}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  openTo="day"
                  value={date.initDate}
                  onChange={(value) => {
                    setDate({ ...date, initDate: value });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              {dateError && (
                <Typography
                  mt={-3}
                  color="red"
                  sx={{
                    width: "100%",
                    zIndex: 9999,
                    position: "absolute"
                  }}
                >
                  {t("dateError")}
                </Typography>
              )}
            </Box>
            <Box className="datepicker-box">
              <Typography className="datepicker-text">
                {t("finalDate")}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  openTo="day"
                  value={date.finalDate}
                  onChange={(value) => {
                    setDate({ ...date, finalDate: value });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
        </Box>
      </Modal>
      {!loading ? (
        <Grid
          item
          container
          justifyContent={{ md: "center", lg: "space-between" }}
          direction={{ md: "row", xs: "column" }}
          xs={12}
        >
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            flexDirection={{ md: "row", xs: "column" }}
          >
            <img
              style={{
                width: "100px",
                cursor: "auto"
              }}
              src={kahshLogoSvg}
              alt="Logo Kahsh"
            />

            <Box textAlign={{ xs: "center", md: "left" }}>
              <Typography
                variant="h5"
                component="h2"
                fontWeight={600}
                color="text.primary"
              >
                {merchant?.name}
              </Typography>

              <Typography variant="caption" color="text.secondary">
                {capitalize(merchant?.address?.cityName)}
                {!!merchant?.address?.regionName &&
                  ", " + capitalize(merchant?.address?.regionName)}
                {!!merchant?.address?.countryName &&
                  ", " + capitalize(merchant?.address?.countryName)}
              </Typography>
            </Box>
          </Box>
          <Box
            px={{ xs: 2, md: 0 }}
            display="flex"
            alignItems="center"
            gap={1}
            flexDirection={{ sm: "row", xs: "column" }}
          >
            <Box
              borderRadius="30px"
              width="100%"
              p={2}
              textAlign="center"
              bgcolor="#fafafa"
            >
              <Typography mb={1} variant="h6" whiteSpace="nowrap">
                {t("invoicesPaid")}
              </Typography>

              {isLoading ? (
                <Grid display="flex" justifyContent="center">
                  <Skeleton width={100} height={30} />
                </Grid>
              ) : (
                <Typography variant="body1" fontWeight={600}>
                  {coinFormat(totalInvoices.paid)}
                </Typography>
              )}
            </Box>
            <Box
              borderRadius="30px"
              p={2}
              width="100%"
              textAlign="center"
              bgcolor="#fafafa"
            >
              <Typography mb={1} variant="h6" whiteSpace="nowrap">
                {t("invoicesPending")}
              </Typography>

              {isLoading ? (
                <Grid display="flex" justifyContent="center">
                  <Skeleton width={100} height={30} />
                </Grid>
              ) : (
                <Typography variant="body1" fontWeight={600}>
                  {totalInvoices.pendings}
                </Typography>
              )}
            </Box>
            <Box
              borderRadius="30px"
              p={2}
              width="100%"
              textAlign="center"
              bgcolor="#fafafa"
            >
              <Typography mb={1} variant="h6" whiteSpace="nowrap">
                {t("totalInvoices")}
              </Typography>

              {isLoading ? (
                <Grid display="flex" justifyContent="center">
                  <Skeleton width={100} height={30} />
                </Grid>
              ) : (
                <Typography variant="body1" fontWeight={600}>
                  {invoices.total}
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
      ) : (
        <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
      )}
      <Grid container gap={2} my={2}>
        <Grid
          item
          container
          justifyContent="space-between"
          direction={{ md: "row", xs: "column" }}
          xs={12}
          md={6}
          border="1px solid #ddd"
          borderRadius="15px"
          p={2}
          maxWidth={{ xs: "100%", md: "calc(50% - 8px) !important" }}
        >
          <Box
            display="flex"
            flexDirection={{ lg: "row", xs: "column" }}
            gap={2}
            mb={2}
            width="100%"
            alignItems={{ lg: "center", xs: "flex-start" }}
            justifyContent="space-between"
          >
            {isLoading ? (
              <Grid container justifyContent="space-between">
                <Skeleton width="20vh" height={60} sx={{ mr: 4 }} />
                <Skeleton width="20vh" height={60} />
              </Grid>
            ) : (
              <>
                <Box>
                  <Typography
                    mb={1}
                    variant="body1"
                    fontWeight={600}
                    whiteSpace="nowrap"
                  >
                    {t("invoicesPaid")}
                  </Typography>

                  <Typography fontSize="36px" fontWeight={300}>
                    {`${coinFormat(totalPaidInvoices, 2)}`}
                    <Typography variant="caption" fontWeight="bold">
                      BRL
                    </Typography>
                  </Typography>
                </Box>
                <TextFieldStyled
                  sx={{ mb: 1.5, maxWidth: 300 }}
                  size="small"
                  label={t("filterByDate")}
                  onClick={() => setModal(true)}
                  variant="outlined"
                  fullWidth
                  value={
                    date.initDate
                      ? `${dataFormat(date.initDate)} a ${dataFormat(
                          date.finalDate
                        )}`
                      : ""
                  }
                  inputProps={{
                    style: { cursor: "pointer" }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarMonthIcon />
                      </InputAdornment>
                    )
                  }}
                  FormHelperTextProps={{ style: { color: "black" } }}
                />
              </>
            )}
          </Box>
          {!!invoicesPerPeriod && (
            <DashboardChart
              data={invoicesPerPeriod.map((e) => {
                return Number(e.netamount);
              })}
              labels={invoicesPerPeriod.map((e) => {
                return `${format(
                  new Date(e.created),
                  i18n.language.substring(0, 2) === "PT"
                    ? "dd-MM-yyyy"
                    : "MM-dd-yyyy"
                )}`;
              })}
              stack="created"
            />
          )}
        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          direction={{ md: "row", xs: "column" }}
          xs={12}
          md={6}
          border="1px solid #ddd"
          p={2}
          pb={0}
          borderRadius="15px"
          maxWidth={{ xs: "100%", md: "calc(50% - 8px) !important" }}
        >
          {isLoading ? (
            <Grid
              container
              display="flex"
              direction="column"
              justifyContent="center"
            >
              <Skeleton width="80%" height={50} />
              <Skeleton width="80%" height={50} />
              <Skeleton width="80%" height={50} />
            </Grid>
          ) : (
            <>
              <Typography width="100%" mb={1} variant="body1" fontWeight={600}>
                {t("recentlyPaid")}
              </Typography>
              {invoices.paid.map((invoicePaid: Invoice) => (
                <Box
                  key={invoicePaid.id}
                  display="flex"
                  alignItems="center"
                  bgcolor="#fafafa"
                  color="text.primary"
                  gap={2}
                  p={1}
                  mb={2}
                  width={{ xs: "calc(100% - 16px)", md: "100%" }}
                  borderRadius="15px"
                  component={Button}
                  onClick={() => setOpenDrawer(invoicePaid.id)}
                >
                  <img
                    style={{ width: "42px", pointerEvents: "fill" }}
                    src={InvoiceImg}
                    alt=""
                  />

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={2}
                    width="100%"
                  >
                    <Box textAlign="left">
                      <Typography variant="body2">
                        {t("invoicePaid")}
                      </Typography>

                      <Typography variant="caption">
                        ID: {invoicePaid?.id}
                      </Typography>
                    </Box>
                    <Typography variant="body2" fontWeight={600} mr={1}>
                      {`${invoicePaid?.currency?.iso_code}  ${coinFormat(
                        Number(invoicePaid.amount),
                        2
                      )}`}
                    </Typography>
                  </Box>
                </Box>
              ))}

              {invoices.paid.length < 1 && (
                <Typography mb={7} mt={2} className="recent-invoices-text">
                  {t("naoexistemtransacoesrecentes")}
                </Typography>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <InvoiceDetailDrawer
        open={openDrawer}
        setOpen={() => setOpenDrawer("")}
      />

      <Grid item xs={12} p={2} border="1px solid #ddd" borderRadius="15px">
        <Typography variant="body1" fontWeight={600}>
          {t("invoicesPending")}
        </Typography>

        {isLoading ? (
          <Grid
            pl={3}
            container
            display="flex"
            direction="column"
            justifyContent="center"
          >
            <Skeleton width={50} height={30} />
            <Skeleton width={120} height={30} />
            <Skeleton width={120} height={30} />
          </Grid>
        ) : (
          <Box
            display="flex"
            justifyContent="space-around"
            flexDirection={{ md: "row", xs: "column" }}
            gap={2}
          >
            {invoices.pending.map((invoicePending: Invoice) => (
              <Box
                key={invoicePending.id}
                display="flex"
                flexDirection="column"
                alignItems="center"
                borderRadius="15px"
                border={{ md: 0, xs: "1px solid #ddd" }}
                p={{ md: 0, xs: 2 }}
              >
                <Typography variant="caption">
                  {`ID: ${invoicePending?.id}`}
                </Typography>

                <Grid display="flex" alignItems="baseline">
                  <Typography fontWeight={300} fontSize="36px" color="#373737">
                    {`${coinFormat(Number(invoicePending?.netamount), 2)}`}
                  </Typography>

                  <Typography fontSize="18px" fontWeight={600} color="#373737">
                    KSH
                  </Typography>
                </Grid>
                <Grid>
                  <Grid container alignItems="baseline">
                    <IconButton
                      onClick={() => setOpenDrawer(invoicePending?.id)}
                    >
                      <img
                        src={InfoIcon}
                        alt="extrato"
                        style={{ width: "15px", pointerEvents: "fill" }}
                      />
                    </IconButton>

                    <Typography ml={1} fontWeight={600} color="#373737">
                      {`R$  ${coinFormat(
                        Number(invoicePending.invoice_amount),
                        2
                      )}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ))}

            {invoices.pending.length < 1 && (
              <Typography className="pending-invoices-text" my={4.5}>
                {t("naohafaturaspendentes")}
              </Typography>
            )}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default DashboardMetrics;

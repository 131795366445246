import { Grid, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BaseCard } from "../../../components/baseCard/BaseCard";
import { IExtract, StatusLabelEnum } from "../../../data/models/Extract";
import dataFormat from "../../../hooks/dataFormat";
import { currencyFormatter } from "../../../hooks/currencyFormatter";

interface ExtractGeneralProps {
  extract: IExtract | null;
}

export const ExtractGeneral: React.FC<ExtractGeneralProps> = ({ extract }) => {
  const { t } = useTranslation();

  if (!extract) {
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <Typography>{t("noData")}</Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={12} textAlign="right" pr={1}>
        {t("Date")}: {dataFormat(extract?.datePlanned)}
      </Grid>
      <Grid item md={8}>
        <BaseCard
          title={t("periodReceive")}
          value={`${dataFormat(extract?.totals?.initialDate)} a ${dataFormat(
            extract?.totals?.finalDate
          )}`}
        />
      </Grid>
      <Grid item md={4}>
        <BaseCard
          title={"Status"}
          value={
            <Typography
              color={StatusLabelEnum[extract?.status].color}
              variant="h5"
              component="p"
              textAlign="center"
            >
              {t(StatusLabelEnum[extract?.status].label)}
            </Typography>
          }
        />
      </Grid>
      <Grid item md={4}>
        <BaseCard
          title={t("value")}
          value={`${extract?.currencyIsoCode} ${currencyFormatter(
            extract?.totals?.amount
          )}`}
        />
      </Grid>
      <Grid item md={4}>
        <BaseCard
          title={t("feesAmount")}
          value={`${extract?.currencyIsoCode} ${currencyFormatter(
            extract?.totals?.feeAmount
          )}`}
        />
      </Grid>
      <Grid item md={4}>
        <BaseCard
          title={t("rewards")}
          value={`${extract?.currencyIsoCode} ${currencyFormatter(
            extract?.totals?.rewardAmount
          )}`}
        />
      </Grid>
      <Grid item md={12}>
        <BaseCard
          title={t("balance")}
          value={`${extract?.currencyIsoCode} ${currencyFormatter(
            extract?.totals?.netamount
          )}`}
        />
      </Grid>
    </Grid>
  );
};

import { Backdrop, Box, CircularProgress, Skeleton } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";
import { TextFieldMask } from "../../components/inputs/text-field-mask/text-field-mask.component";
import { TextField } from "../../components/inputs/text-field/text-field.component";
import { PhoneInput } from "../../components/inputs/phoneInput/PhoneInput";
import { DatePicker } from "../../components/inputs/date-picker/datePicker";
import { Select } from "../../components/inputs/select/select.component";
import axios from "axios";
import {
  urlCities,
  urlCountries,
  urlDistrict,
  urlMerchant,
  urlRegions
} from "../../services/global";
import { useAlert } from "../../hooks/alert";
import { normalizeString } from "../../utils/normalizeString";
import { GooglePlacesAutocomplete } from "../../components/inputs/google-places-autocomplete/google-places-autocomplete.component";
import { BackAndSubmitButton } from "../../components/buttons/BackAndSubmit/BackAndSubmit";
import { useAuth } from "../../contexts/authContext";
import { Merchant } from "../../data/models/Merchant";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { cpfValidator } from "../../utils/cpfValidator";
import { cnpjValidator } from "../../utils/cnpjValidator";

interface SelectInterface {
  value: any;
  label: string;
  isoCode?: string;
}

interface ComplianceStep {
  onSubmit: (stepData: FieldValues) => void;
  onBack: () => void;
  compliance: FieldValues | null;
}

export const MerchantData: React.FC<ComplianceStep> = ({
  onSubmit,
  onBack,
  compliance
}) => {
  const { merchant } = useAuth();
  const isCpf = merchant && merchant?.taxid?.length <= 11 ? true : false;

  const validator = yup.object().shape({
    name: yup
      .string()
      .required("nameRequired")
      .matches(/^[a-zA-Z\s]/, "invalidName")
      .matches(/^[a-zA-Z\s]+\s+[a-zA-Z]/, "fullNameInvalid"),
    phone: yup
      .string()
      .required("phoneRequired")
      .test(`test-phone`, function (value) {
        const { path, createError } = this;
        return (
          isValidPhoneNumber(`${value}`) ||
          createError({ path, message: "phoneInvalid" })
        );
      }),
    taxid: yup
      .string()
      .required("documentRequired")
      .transform((document) => document.replace(/\D+/g, ""))
      .test(`test-valid-document`, function (value) {
        const { path, createError } = this;
        if (isCpf) {
          return (
            cpfValidator(value) || createError({ path, message: "cpfInvalid" })
          );
        }
        return (
          cnpjValidator(value) || createError({ path, message: "cnpjInvalid" })
        );
      }),
    birthdate: yup
      .date()
      .typeError("invalidDate")
      .required("birthDateShopRequired")
      .max(new Date(), "birthDateShopFuture")
      .nullable(),
    countryId: yup.string().required("countryRequired"),
    address1: yup.string().required("addressAutoCompleteInvalid"),
    addressNumber: yup.string().required("addressNumberRequired"),
    zipcode: yup
      .string()
      .required("zipCodeRequired")
      .transform((document) => document.replace(/\D+/g, ""))
      .min(8, "zipCodeInvalid"),
    regionId: yup.string().required("regionRequired"),
    cityId: yup.string().required("cityRequired"),
    districtId: yup.string().required("DistrictRequired"),
    email: yup.string().required("emailRequired").email("emailInvalid")
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validator)
  });

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const { AlertMessage } = useAlert();

  const [place, setPlace] = useState<FieldValues | null>(null);
  const [countries, setCountries] = useState<SelectInterface[]>([]);
  const [regions, setRegions] = useState<SelectInterface[]>([]);
  const [cities, setCities] = useState<SelectInterface[]>([]);
  const [districts, setDistricts] = useState<SelectInterface[]>([]);

  const handleNextStep = (data: FieldValues) => {
    const parsedPhone = parsePhoneNumber(data.phone);
    if (parsedPhone) {
      data.phoneDdi = parsedPhone.countryCallingCode;
      data.phone = parsedPhone.nationalNumber;
    }
    data.taxid = data.taxid.replace(/\D+/g, "");
    data.zipcode = data.zipcode.replace(/\D+/g, "");
    data.birthdate = new Date(data.birthdate).toISOString();

    if (!districts.some((district) => district.value === data?.districtId)) {
      data.district = data?.districtId;
      delete data?.districtId;
    }
    onSubmit(data);
  };

  const setCountryData = useCallback(
    async (countryValue: string) => {
      if (countryValue) {
        const selectedCountry = countries.find(
          (country) =>
            country?.isoCode === countryValue || country?.value === countryValue
        );
        if (selectedCountry) {
          try {
            setValue("countryId", selectedCountry?.value);
            const { data: regionsData } = await axios.get(urlRegions, {
              params: { countryId: selectedCountry?.value }
            });
            if (regionsData?.length) {
              setRegions(
                regionsData.map(({ name, id, code }) => ({
                  value: id,
                  label: name,
                  isoCode: code
                }))
              );
            } else {
              setBackdrop(false);
            }
          } catch (err) {
            setBackdrop(false);
            return AlertMessage("error", "defaultMessage", err);
          }
        } else {
          setBackdrop(false);
        }
      }
    },
    [AlertMessage, countries, setValue]
  );

  const setRegionData = useCallback(
    async (regionValue: string) => {
      if (!regions?.length) {
        return;
      }
      const selectedRegion = regions.find(
        (region) =>
          region?.isoCode === regionValue || region?.value === regionValue
      );
      if (selectedRegion) {
        try {
          setValue("regionId", selectedRegion?.value);
          const { data: citiesData } = await axios.get(urlCities, {
            params: { regionId: selectedRegion?.value }
          });
          if (citiesData?.length) {
            setCities(
              citiesData.map(({ name, id }) => ({ value: id, label: name }))
            );
          } else {
            setBackdrop(false);
          }
        } catch (err) {
          setBackdrop(false);
          return AlertMessage("error", "defaultMessage", err);
        }
      } else {
        setBackdrop(false);
      }
    },
    [AlertMessage, regions, setValue]
  );

  const setCityData = useCallback(
    async (cityValue: string) => {
      if (!cities?.length) {
        return;
      }

      const selectedCity = cities.find(
        (city) =>
          normalizeString(city?.label) === normalizeString(cityValue) ||
          city?.value === cityValue
      );
      if (selectedCity) {
        try {
          setValue("cityId", selectedCity?.value);
          const { data: districtData } = await axios.get(urlDistrict, {
            params: { cityId: selectedCity?.value }
          });
          if (districtData.length) {
            setDistricts(
              districtData.map(({ name, id }) => ({
                value: id,
                label: name
              }))
            );
          } else {
            setBackdrop(false);
          }
        } catch (err) {
          setBackdrop(false);
          return AlertMessage("error", "defaultMessage", err);
        }
      } else {
        setBackdrop(false);
      }
    },
    [AlertMessage, cities, setValue]
  );

  const setDistrictData = useCallback(
    async (districtValue: string) => {
      if (!districts?.length) {
        return;
      }

      const selectedDistrict = districts.find(
        (city) =>
          normalizeString(city?.label) === normalizeString(districtValue) ||
          city?.value === districtValue
      );
      if (selectedDistrict) {
        setValue("districtId", selectedDistrict?.value);
      }
      setBackdrop(false);
    },
    [districts, setValue]
  );

  const resetAddress = () => {
    setValue("countryId", "");
    setValue("address1", "");
    setValue("addressNumber", "");
    setValue("zipcode", "");
    setValue("regionId", "");
    setValue("cityId", "");
    setValue("districtId", "");

    setRegions([]);
    setCities([]);
    setDistricts([]);
  };

  const setData = useCallback(
    (merchant: Merchant) => {
      setPlace({
        country: compliance?.countryId || merchant?.address?.countryId || "",
        region: compliance?.regionId || merchant?.address?.regionId || "",
        city: compliance?.cityId || merchant?.address?.cityId || "",
        district: compliance?.districtId || merchant?.address?.districtId || ""
      });
      reset({
        lat: compliance?.lat || Number(merchant?.address?.lat) || 0,
        lng: compliance?.lng || Number(merchant?.address?.lng) || 0,
        name: compliance?.name || merchant?.name || "",
        corporateName:
          compliance?.corporateName || merchant?.corporateName || "",
        email: compliance?.email || merchant?.email || "",
        phone: compliance?.phoneDdi
          ? `+${compliance?.phoneDdi}${compliance?.phone}`
          : merchant?.phoneDdi
          ? `+${merchant?.phoneDdi}${merchant?.phone}`
          : "",
        taxid: compliance?.taxid || merchant?.taxid || "",
        birthdate: compliance?.birthdate
          ? new Date(compliance?.birthdate)
          : merchant?.birthdate
          ? new Date(merchant.birthdate)
          : null,
        countryId: compliance?.countryId || merchant?.address?.countryId || "",
        zipcode: compliance?.zipcode || merchant?.address?.zipcode || "",
        addressNumber:
          compliance?.addressNumber || merchant?.address?.addressNumber || "",
        address2: compliance?.address2 || merchant?.address?.address2 || ""
      });
      setValue("address1", merchant?.address?.address1 || "");
      setLoading(false);
    },
    [reset, setValue, compliance]
  );

  const getCountries = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(urlCountries);
      setCountries(
        data.map(({ name, id, isoCode }) => ({
          value: id,
          label: name,
          isoCode
        }))
      );
      const { data: dataMerchant } = await axios.get<Merchant>(urlMerchant);
      setData(dataMerchant);
    } catch (err) {
      setLoading(false);
      setBackdrop(false);
      return AlertMessage("error", "defaultMessage", err);
    }
  }, [AlertMessage, setData]);

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  useEffect(() => {
    if (place?.country) {
      setCountryData(place?.country);
    }
    if (place?.address1) {
      setValue("address1", place?.address1);
    }
    if (place?.addressNumber) {
      setValue("addressNumber", place?.addressNumber);
    }
    if (place?.zipcode) {
      setValue("zipcode", place?.zipcode);
    }
    if (place?.lat && place?.lng) {
      setValue("lat", place?.lat);
      setValue("lng", place?.lng);
    }
  }, [setCountryData, place, setValue]);

  useEffect(() => {
    if (place?.region) {
      setRegionData(place?.region);
    }
  }, [place?.region, setRegionData]);

  useEffect(() => {
    if (place?.city) {
      setCityData(place?.city);
    }
  }, [place?.city, setCityData]);

  useEffect(() => {
    if (place?.district) {
      setDistrictData(place?.district);
    }
  }, [place?.district, setDistrictData]);

  useEffect(() => {
    if (place?.district) {
      setDistrictData(place?.district);
    }
  }, [place?.district, setDistrictData]);

  return (
    <Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        p={4}
        sx={{ border: "1px solid #ddd" }}
        borderRadius={"40px"}
        display="flex"
        flexDirection="column"
        gap={2}
      >
        {loading ? (
          <Box display="flex" width={"100%"} flexDirection="column" gap={2}>
            <Skeleton
              variant="text"
              width={"100%"}
              sx={{ fontSize: "3rem", maxWidth: 300 }}
            />
            <Box display="flex" gap={2}>
              <Box width={"100%"}>
                <Skeleton
                  variant="text"
                  width={"80%"}
                  sx={{ fontSize: "1rem" }}
                />
                <Skeleton variant="rectangular" width={"100%"} height={60} />
              </Box>
              <Box width={"100%"}>
                <Skeleton
                  variant="text"
                  width={"80%"}
                  sx={{ fontSize: "1rem" }}
                />
                <Skeleton variant="rectangular" width={"100%"} height={60} />
              </Box>
            </Box>
            <Box display="flex" width={"100%"} gap={2}>
              <Box width={"100%"}>
                <Skeleton
                  variant="text"
                  width={"80%"}
                  sx={{ fontSize: "1rem" }}
                />
                <Skeleton variant="rectangular" width={"100%"} height={60} />
              </Box>
              <Box width={"100%"}>
                <Skeleton
                  variant="text"
                  width={"80%"}
                  sx={{ fontSize: "1rem" }}
                />
                <Skeleton variant="rectangular" width={"100%"} height={60} />
              </Box>
              <Box width={"100%"}>
                <Skeleton
                  variant="text"
                  width={"80%"}
                  sx={{ fontSize: "1rem" }}
                />
                <Skeleton variant="rectangular" width={"100%"} height={60} />
              </Box>
            </Box>
          </Box>
        ) : (
          <>
            <TextField
              label={"name"}
              control={control}
              helperText={errors?.name?.message}
              name="name"
              required
            />
            <TextField
              label={t("corporateName")}
              control={control}
              name="corporateName"
            />
            <Box
              display="flex"
              gap={2}
              alignItems="flex-start"
              flexDirection={{ md: "row", xs: "column" }}
            >
              <TextField
                label={t("corporateEmail")}
                control={control}
                helperText={errors?.email?.message}
                name="email"
                required
                type="email"
              />

              {isCpf ? (
                <TextFieldMask
                  mask="999.999.999-99"
                  label="CPF"
                  control={control}
                  helperText={errors?.taxid?.message}
                  name="taxid"
                  required
                />
              ) : (
                <TextFieldMask
                  mask="99.999.999/9999-99"
                  label="CNPJ"
                  control={control}
                  helperText={errors?.taxid?.message}
                  name="taxid"
                  required
                />
              )}
            </Box>
            <Box
              display="flex"
              gap={2}
              alignItems="flex-start"
              flexDirection={{ md: "row", xs: "column" }}
            >
              <DatePicker
                label={t("birthDateShop")}
                control={control}
                helperText={errors?.birthdate?.message}
                name="birthdate"
                maxDate={new Date()}
                required
              />

              <PhoneInput
                label="phone"
                control={control}
                helperText={errors?.phone?.message}
                name="phone"
                required
              />
            </Box>
          </>
        )}
      </Box>
      <br />
      {!loading && (
        <Box
          p={4}
          sx={{ border: "1px solid #ddd" }}
          borderRadius={"40px"}
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <GooglePlacesAutocomplete
            place={place}
            setPlace={(selectedPlace) => {
              resetAddress();
              setBackdrop(true);
              setPlace(selectedPlace);
            }}
            placeholder={watch("address1")}
            error={errors?.address1?.message}
            required
          />
          {place && (
            <>
              <Select
                label={t("country")}
                control={control}
                watch={setCountryData}
                helperText={errors?.countryId?.message}
                name="countryId"
                options={countries}
                required
              />

              <Box
                display="flex"
                gap={2}
                alignItems="flex-start"
                flexDirection={{ md: "row", xs: "column" }}
              >
                <TextField
                  label={t("number")}
                  control={control}
                  name="addressNumber"
                  required
                  helperText={errors?.addressNumber?.message}
                />
                <TextFieldMask
                  mask={"99.999-999"}
                  label="zipCode"
                  control={control}
                  helperText={errors?.zipcode?.message}
                  name="zipcode"
                  required
                />
              </Box>
              <Box
                display="flex"
                alignItems="flex-start"
                flexDirection={{ xs: "column", md: "row" }}
                gap={2}
              >
                <Select
                  label={t("region")}
                  control={control}
                  watch={setRegionData}
                  helperText={errors?.regionId?.message}
                  name="regionId"
                  options={regions}
                  required
                />
                <Select
                  label={t("city")}
                  control={control}
                  watch={setCityData}
                  helperText={errors?.cityId?.message}
                  name="cityId"
                  options={cities}
                  required
                />
                <Select
                  label={t("district")}
                  control={control}
                  watch={setDistrictData}
                  helperText={errors?.districtId?.message}
                  name="districtId"
                  options={districts}
                  newOptionDefaultValue={
                    districts.some(
                      (district) => district.value === watch("districtId")
                    ) && watch("districtId") !== "default"
                      ? ""
                      : watch("districtId")
                  }
                  addButton
                  required
                />
              </Box>
              <TextField
                label={t("address2")}
                control={control}
                helperText={errors?.address2?.message}
                name="address2"
              />
            </>
          )}
        </Box>
      )}
      <br />
      {!loading && (
        <BackAndSubmitButton onSubmit={handleSubmit(handleNextStep)} />
      )}
    </Box>
  );
};

import { Box, Stepper, Typography, Step, StepLabel } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/authContext";
import { useAlert } from "../../hooks/alert";
import { urlMerchantCompliance } from "../../services/global";
import { DocumentFile } from "./DocumentFile";
import { FinalStep } from "./FinalStep";
import { MerchantData } from "./merchantData";
import { ProofAddress } from "./ProofAddress";
import { SelfieFile } from "./SelfieFile";
import { SocialContract } from "./socialContract";

export const ComplianceCreate = () => {
  const [complianceData, setComplianceData] = useState<FieldValues>({});
  const [step, setStep] = useState(0);

  const { AlertMessage } = useAlert();
  const { merchant } = useAuth();
  const { t } = useTranslation();

  const isCpf = merchant && merchant?.taxid?.length === 11 ? true : false;

  const steps = [
    "stepMerchantData",
    "stepDocumentFile",
    "stepSelfieFile",
    isCpf ? "stepAddressFile" : "stepSocialContract"
  ];

  const handleSubmit = async (stepData: FieldValues) => {
    try {
      if (step === steps.length - 1) {
        const submitData = { ...complianceData, ...stepData };
        const formData = new FormData();
        Object.keys(submitData).forEach((key) => {
          formData.append(key, submitData[key]);
        });
        await axios.post(urlMerchantCompliance, formData, {
          headers: {
            "Content-Type": "multipart/formdata"
          }
        });
      }
      setComplianceData((prev) => ({ ...prev, ...stepData }));
      setStep((prev) => prev + 1);
    } catch (err) {
      AlertMessage("error", "defaultMessage", err);
    }
  };

  const handleBack = () => {
    if (step > 0) {
      setStep((prev) => prev - 1);
    }
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Stepper
        activeStep={step}
        connector={<Typography mx={1}>|</Typography>}
        sx={{ my: 4, mx: "auto" }}
      >
        {steps.map((label, index) => (
          <Step
            key={label}
            completed={index < step}
            sx={{
              p: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: { xs: "column", sm: "row" }
            }}
          >
            <StepLabel
              sx={{
                ".Mui-active": {
                  color: (theme) => theme.palette.primary.main
                }
              }}
            />

            <Typography variant="caption" fontSize={{ sm: 10, xs: 7 }} mt={0.5}>
              {t(label)}
            </Typography>
          </Step>
        ))}
      </Stepper>
      {step === 0 && (
        <MerchantData
          onSubmit={handleSubmit}
          onBack={handleBack}
          compliance={complianceData}
        />
      )}
      {step === 1 && (
        <DocumentFile
          onSubmit={handleSubmit}
          onBack={handleBack}
          compliance={complianceData}
        />
      )}
      {step === 2 && (
        <SelfieFile
          onSubmit={handleSubmit}
          onBack={handleBack}
          compliance={complianceData}
        />
      )}
      {step === 3 && isCpf && (
        <ProofAddress
          onSubmit={handleSubmit}
          onBack={handleBack}
          compliance={complianceData}
        />
      )}
      {step === 3 && !isCpf && (
        <SocialContract
          onSubmit={handleSubmit}
          onBack={handleBack}
          compliance={complianceData}
        />
      )}
      {step === 4 && (
        <FinalStep
          onSubmit={handleSubmit}
          onBack={handleBack}
          compliance={complianceData}
        />
      )}
    </Box>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useCallback } from "react";

import { useHistory, useParams } from "react-router-dom";

import {
  Checkbox,
  FormControlLabel,
  Grid,
  Tab,
  Typography,
  Box,
  Skeleton
} from "@mui/material";

import axios from "axios";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { useTranslation } from "react-i18next";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { FieldValues, useForm } from "react-hook-form";

import { Button } from "../../components/buttons/Button/Button";
import { TextField } from "../../components/inputs/text-field/text-field.component";

import { Checkbox as CheckboxComponent } from "../../components/inputs/checkbox/checkbox.component";

import { useAlert } from "../../hooks/alert";

import {
  urlApi,
  urlApiVtex,
  urlPermission,
  urlRole
} from "../../services/global";

import { Api } from "../../data/models/Api";

import { RolesDrawer } from "../../components/rolesDrawer/RolesDrawer";

import "./ApiList.styles.css";
import { BackAndSubmitButton } from "../../components/buttons/BackAndSubmit/BackAndSubmit";

interface Permission {
  name: string;
  description: string;
  value: string;
  checked?: boolean;
}

const validatorVetex = yup.object().shape({
  name: yup.string().required("nameRequired")
});

const validatorApi = yup.object().shape({
  name: yup.string().required("nameRequired")
});

const ApiAndVetex = () => {
  const { t, i18n } = useTranslation();
  const { AlertMessage } = useAlert();
  const history = useHistory();

  const [tabSelected, setTabSelected] = useState("api");

  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [roles, setRoles] = useState<any[]>([]);
  const [permissionsView, setPermissionsView] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(false);

  const params: any = useParams();

  const handleChangeAbas = (event: React.SyntheticEvent, newValue: string) => {
    if (!params.id) setTabSelected(newValue);
  };

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(tabSelected === "api" ? validatorApi : validatorVetex)
  });

  const handleChange = (index: number) => {
    setPermissions((prev) =>
      prev.map((permission, i) =>
        index !== i
          ? permission
          : { ...permission, checked: !permission.checked }
      )
    );
  };

  const handleChangeAll = () => {
    const currentValue = permissions.every((permission) => permission.checked);
    setPermissions((prev) =>
      prev.map((permission) => ({ ...permission, checked: !currentValue }))
    );
  };

  const handleSaveApi = async (data: FieldValues) => {
    try {
      setLoading(true);

      if (
        !data?.roleId &&
        !permissions.some((permission) => permission.checked) &&
        !isOwner
      ) {
        setLoading(false);
        return AlertMessage("error", "noPermissionsFound");
      }

      if (data?.roleId) {
        const role = roles.find((role) => role.value === data.roleId);

        if (role) {
          data.permissions = role.permissions.map(
            (permission) => permission.value
          );
        }
      } else {
        delete data?.roleId;
        data.permissions = permissions
          .filter((permission) => permission.checked)
          .map((permission) => permission.value);
      }

      if (params?.id) data.id = params?.id;

      await axios.post(urlApi, data);
      setLoading(false);
      history.push("/api");
    } catch (err) {
      setLoading(false);
      return AlertMessage("error", "defaultMessage", err);
    }
  };

  const handleSaveVetex = async (data: FieldValues) => {
    try {
      setLoading(true);

      if (params?.id) data.id = params?.id;

      await axios.post(urlApiVtex, data);
      setLoading(false);
      history.push("/api");
    } catch (err) {
      setLoading(false);
      return AlertMessage("error", "defaultMessage", err);
    }
  };

  const getRoles = useCallback(async () => {
    try {
      const res = await axios.get(urlRole);

      if (!params?.id) setLoadingData(false);

      setRoles(
        res.data?.map((role) => ({
          label: role.name,
          value: role.id,
          permissions: role.permissions
        }))
      );
    } catch (err) {
      setLoadingData(false);
      AlertMessage("error", "defaultMessage", err);
    }
  }, [AlertMessage, params?.id]);

  const getPermissions = useCallback(
    async (api?: Api) => {
      try {
        const res = await axios.get<Permission[]>(urlPermission, {
          params: { lang: i18n.language.substring(0, 2) }
        });

        setPermissions(
          res.data.map(({ name, description, value }) => ({
            name,
            description,
            value,
            checked: api
              ? api.permissions.some(
                  (permission) => permission?.value === value
                )
              : false
          }))
        );
      } catch (err) {
        AlertMessage("error", "defaultMessage", err);
      }
    },
    [AlertMessage, i18n.language]
  );

  const onChangeRole = async () => {
    await getRoles();
  };

  const getApi = useCallback(async () => {
    try {
      const res = await axios.get(`${urlApi}/${params?.id}`);

      if (res.data?.isowner) {
        setIsOwner(true);
      } else {
        if (!res.data?.rolesId) {
          setPermissionsView(true);
        }
        await getRoles();
        await getPermissions(res.data);
      }

      if (res.data?.isvtex) {
        setTabSelected("vtex");
        reset({
          name: res.data?.name || "",
          ApiClient: res.data?.username || "",
          ApiSecret: res.data?.passwd || ""
        });
      } else {
        reset({
          name: res.data?.name || "",
          roleId: res.data?.rolesId || "",
          isactive: res.data?.isactive
        });
      }

      setLoadingData(false);
    } catch (err) {
      setLoadingData(false);
      AlertMessage("error", "defaultMessage", err);
    }
  }, [AlertMessage, getPermissions, getRoles, params?.id, reset]);

  useEffect(() => {
    if (params?.id) {
      getApi();
    } else {
      getRoles();
      getPermissions();
    }
    setValue("roleId", "");
  }, [getPermissions, getRoles, setValue, getApi, params?.id]);

  if (loadingData) {
    return (
      <Box
        borderRadius="15px"
        border="1px solid #ddd"
        minHeight="calc(100vh - 62px - 16px - 16px)"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          justifyContent="space-between"
          p={4}
        >
          <Box display="flex" width={"100%"} gap={2}>
            <Skeleton
              variant="rectangular"
              sx={{ mr: "auto" }}
              width={"30%"}
              height={60}
            />
          </Box>
          <Box display="flex" gap={2}>
            <Box width={"100%"}>
              <Skeleton variant="rectangular" width={"100%"} height={60} />
            </Box>
            <Box width={"40%"}>
              <Skeleton variant="rectangular" width={"100%"} height={60} />
            </Box>
          </Box>
          <Box display="flex" gap={2}>
            <Box width={"100%"}>
              <Skeleton variant="rectangular" width={"100%"} height={60} />
            </Box>
            <Box width={"100%"}>
              <Skeleton variant="rectangular" width={"100%"} height={60} />
            </Box>
          </Box>
          <Box display="flex" gap={2}>
            <Box width={"100%"}>
              <Skeleton variant="rectangular" width={"100%"} height={60} />
            </Box>
            <Box width={"100%"}>
              <Skeleton variant="rectangular" width={"100%"} height={60} />
            </Box>
          </Box>
          <Box display="flex" width={"100%"} gap={2}>
            <Skeleton
              variant="rectangular"
              sx={{ ml: "auto" }}
              width={"30%"}
              height={60}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Grid container id="apiAndVetex">
      <TabContext value={tabSelected}>
        <TabList onChange={handleChangeAbas}>
          <Tab label="API" value="api" />
          <Tab label="VTEX" value="vtex" />
        </TabList>

        <Grid
          minHeight="calc(100vh - 64px - 16px - 16px - 35px)"
          container
          p={4}
          mt="-11px"
          borderRadius="0 15px 15px 15px"
          border="1px solid #DDDDDD"
          direction="column"
        >
          <TabPanel value="api" sx={{ p: 0, height: "100%" }}>
            <RolesDrawer onChange={onChangeRole} />
            {tabSelected === "api" && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
                gap={4}
              >
                <Box
                  p={4}
                  gap={4}
                  display="flex"
                  borderRadius={"15px"}
                  flexDirection="column"
                  sx={{ border: "1px solid #ddd" }}
                >
                  <Typography variant="body1" color="#000000">
                    {params?.id ? t("editApi") : t("createApi")}
                  </Typography>

                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={9} md={10}>
                      <TextField
                        required
                        label="name"
                        name="name"
                        control={control}
                        helperText={errors?.name?.message}
                      />
                    </Grid>

                    <Grid item xs={12} sm={3} md={2}>
                      <CheckboxComponent
                        name="isactive"
                        control={control}
                        label={t("activate")}
                        defaultChecked={true}
                        disabled={!params?.id || isOwner}
                      />
                    </Grid>
                  </Grid>

                  {params?.id && (
                    <Button
                      sx={{
                        maxWidth: 320,
                        ml: "auto",
                        mr: { xs: "auto", sm: 0 }
                      }}
                      loading={loading}
                      onClick={handleSubmit(handleSaveApi)}
                    >
                      {t("save")}
                    </Button>
                  )}
                </Box>

                <Box
                  sx={{ border: "1px solid #ddd" }}
                  p={4}
                  borderRadius={"15px"}
                  display="flex"
                  flexDirection="column"
                  gap={4}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexDirection={{ xs: "column", sm: "row" }}
                    gap={2}
                  >
                    <Typography variant="body1" color="#000000">
                      {t("permissions")}
                    </Typography>
                    <Box
                      sx={{ border: "1px solid #ddd" }}
                      borderRadius={"15px"}
                      px={2}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={permissions.every(
                              (permission) => permission.checked
                            )}
                            onChange={handleChangeAll}
                          />
                        }
                        label={t("selectAll")}
                      />
                    </Box>
                  </Box>

                  <Grid container spacing={2}>
                    {permissions.map((permission, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        key={permission.value}
                      >
                        <FormControlLabel
                          key={permission.value}
                          label={permission.name}
                          control={
                            <Checkbox
                              size="small"
                              checked={permission.checked}
                              onChange={() => {
                                handleChange(index);
                              }}
                            />
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>

                <Grid container>
                  <BackAndSubmitButton
                    onBack={() => history.push("/api")}
                    onSubmit={handleSubmit(handleSaveApi)}
                    titleSubmit={params?.id ? "save" : "createApi"}
                  />
                </Grid>
              </Box>
            )}
          </TabPanel>

          <TabPanel value="vtex" sx={{ p: 0, height: "100%" }}>
            {tabSelected === "vtex" && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gap={4}
                height="100%"
              >
                <Box
                  p={4}
                  sx={{ border: "1px solid #ddd" }}
                  borderRadius="15px"
                  display="flex"
                  flexDirection="column"
                  gap={4}
                >
                  <Typography variant="body1" color="#000000">
                    {params?.id ? t("edit") : t("Cadastro")} Vetex
                  </Typography>

                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={9} md={10}>
                      <TextField
                        required
                        autoFocus
                        type="text"
                        label="name"
                        name="name"
                        control={control}
                        helperText={errors?.name?.message}
                      />
                    </Grid>

                    <Grid item xs={12} sm={3} md={2}>
                      <CheckboxComponent
                        size="small"
                        name="isactive"
                        control={control}
                        label={t("activate")}
                        defaultChecked={true}
                        disabled={!params?.id || isOwner}
                      />
                    </Grid>
                  </Grid>

                  <Box
                    gap={4}
                    display="flex"
                    alignItems="flex-start"
                    flexDirection={{ md: "row", xs: "column" }}
                  >
                    <TextField
                      required
                      type="text"
                      name="ApiClient"
                      label="AppKey"
                      control={control}
                      helperText={errors?.ApiClient?.message}
                    />

                    <TextField
                      required
                      autoFocus
                      type="text"
                      name="ApiSecret"
                      label="AppToken"
                      control={control}
                      helperText={errors?.ApiSecret?.message}
                    />
                  </Box>
                </Box>

                <Grid container>
                  <BackAndSubmitButton
                    onBack={() => history.push("/api")}
                    onSubmit={handleSubmit(handleSaveVetex)}
                    titleSubmit={params?.id ? "save" : "createApi"}
                  />
                </Grid>
              </Box>
            )}
          </TabPanel>
        </Grid>
      </TabContext>
    </Grid>
  );
};

export default ApiAndVetex;

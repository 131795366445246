import { Block, InfoOutlined, Portrait } from "@mui/icons-material";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { FieldValues } from "react-hook-form";
import { BackAndSubmitButton } from "../../components/buttons/BackAndSubmit/BackAndSubmit";
import { DocumentCard } from "../../components/documentCard/DocumentCard";
import { FileInput } from "../../components/inputs/fileInput/FileInput";
import { TooltipCompliance } from "../../styles/TooltipCompliance";

import { useState } from "react";
import { useTranslation } from "react-i18next";

interface ComplianceStep {
  onSubmit: (stepData: FieldValues) => void;
  onBack: () => void;
  compliance: FieldValues | null;
}

export const SelfieFile: React.FC<ComplianceStep> = ({
  onSubmit,
  onBack,
  compliance
}) => {
  const { t } = useTranslation();
  const [selfieDocument, setSelfieDocument] = useState<File | null>(
    compliance?.selfieDocument || null
  );
  const [hasError, setHasError] = useState(false);

  const handleSubmit = () => {
    if (!selfieDocument) {
      return setHasError(true);
    }
    onSubmit({ selfieDocument });
  };

  return (
    <Grid container spacing={4}>
      <Grid
        item
        container
        xs={12}
        md={6}
        direction="column"
        justifyContent="space-between"
        gap={1}
      >
        <Box>
          <Box display="flex" alignItems="center" gap={2}>
            <Avatar sx={{ background: (theme) => theme.palette.primary.main }}>
              3
            </Avatar>
            <Typography fontWeight="bold">
              {t("selfieWithDoc")}
              <Typography variant="body2" component="span" display={"block"}>
                {t("selfieInfos")}
              </Typography>
            </Typography>
          </Box>
        </Box>
        <DocumentCard icon={<Block />} title={t("doNotUseGlasses")} />
        <DocumentCard icon={<Block />} title={t("doNotUseCap")} />
        <DocumentCard icon={<Block />} title={t("useSameDoc")} />
        <DocumentCard icon={<Block />} title={t("illuminatedPlace")} />
        <DocumentCard icon={<Block />} title={t("doNotCoverYourFace")} />

        <BackAndSubmitButton onSubmit={handleSubmit} onBack={onBack} />
      </Grid>
      <Grid item container xs={12} md={6} direction="column">
        <Box display="flex" flexDirection="column" gap={2} height="100%">
          <Box display="flex" gap={1} alignItems="center">
            <TooltipCompliance
              placement="top"
              title={t("validDocument")}
              style={{ backgroundColor: "#fff" }}
            >
              <InfoOutlined />
            </TooltipCompliance>
            <Typography variant="body2">{t("selectDocument")}</Typography>
          </Box>
          <FileInput
            onChange={(file) => {
              setHasError(false);
              setSelfieDocument(file);
            }}
            defaultValue={selfieDocument}
            icon={<Portrait />}
            accept="image/*, .pdf"
            error={hasError}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

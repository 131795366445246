import { useCallback, useEffect, useState } from "react";
import {
  InputAdornment,
  Typography,
  Box,
  CircularProgress,
  Grid
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";

import "./UserList.styles.css";
import { useHistory } from "react-router-dom";
import { TextFieldStyled } from "../../../components/inputs/text-field/text-field.styles";
import { Button } from "../../../components/buttons/Button/Button";
import axios from "axios";
import { urlUser } from "../../../services/global";
import { User } from "../../../data/models/User";
import { RolesDrawer } from "../../../components/rolesDrawer/RolesDrawer";
import {
  Cell,
  ListComponent
} from "../../../components/listComponent/ListComponent";
import { useAlert } from "../../../hooks/alert";
import { BackAndSubmitButton } from "../../../components/buttons/BackAndSubmit/BackAndSubmit";
import { StatusActive } from "../../../components/statusActive/StatusActive";

const cells: Cell[] = [
  {
    id: "name",
    label: "name",
    size: 0.4,
    button: true
  },
  {
    id: "email",
    label: "email",
    size: 0.5,
    button: true
  },
  {
    id: "isactive",
    label: "Status",
    size: 0.1,
    button: true,
    format: (value) => (
      <StatusActive value={value.isactive} sx={{ ml: "10%" }} />
    )
  }
];

const UserList = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");
  const [data, setData] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const { AlertMessage } = useAlert();

  const filteredData = search
    ? data?.filter(
        (user) =>
          user.name?.toUpperCase()?.includes(search?.toUpperCase()) ||
          user.username?.toUpperCase()?.includes(search?.toUpperCase()) ||
          user.email?.toUpperCase()?.includes(search?.toUpperCase())
      )
    : data;

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axios.get<User[]>(urlUser);
      setLoading(false);
      setData(res.data);
    } catch (err) {
      setLoading(false);
      AlertMessage("error", "defaultMessage", err);
    }
  }, [AlertMessage]);

  const handleEditClick = (value: User) => {
    history.push(`/user/create/${value.id}`);
  };

  const handleInactive = async (value: User) => {
    try {
      await axios.delete(`${urlUser}/${value.id}`);
      AlertMessage("success", "successfullyDeleteUser");
      await getData();
    } catch (err) {
      AlertMessage("error", "defaultMessage", err);
    }
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Grid
      container
      direction="column"
      minHeight="calc(100vh - 62px - 16px - 16px)"
      border="1px solid #DDDDDD"
      borderRadius="15px"
      p={4}
      width="100%"
    >
      <RolesDrawer />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection={{ xs: "column", md: "row" }}
        gap={2}
      >
        <Typography variant="body1" color="#000000">
          {t("userList")}
        </Typography>

        <Box
          display="flex"
          alignItems="center"
          gap={2}
          flexDirection={{ xs: "column", md: "row" }}
          maxWidth={{ md: "60%", xs: "100%" }}
          width="100%"
        >
          <TextFieldStyled
            fullWidth
            size="small"
            value={search}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon color="primary" />
                </InputAdornment>
              )
            }}
            label={t("searchUser")}
            onChange={(e) => setSearch(e.target.value)}
          />

          <Button onClick={() => history.push("/user/create")}>
            {t("newUser")}
          </Button>
        </Box>
      </Box>

      {loading ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt={4}
          gap={2}
        >
          <Typography variant="body2" color="#000000">
            {t("loading")} {t("users")}
          </Typography>
          <CircularProgress />
        </Box>
      ) : (
        <Box my={4}>
          <ListComponent
            cells={cells}
            rows={filteredData}
            initialOrder={{
              orderBy: "name",
              direction: "asc"
            }}
            avatar={{
              url: "",
              path: "name"
            }}
            noDataText="noUserData"
            moreButtons={[
              { label: "edit", action: handleEditClick },
              {
                label: "inactivate",
                action: handleInactive,
                viewKey: "isactive"
              }
            ]}
            onDoubleClick={(value) => handleEditClick(value)}
          />
        </Box>
      )}
      <Grid container justifyContent="flex-end" mt="auto" ml="auto">
        <BackAndSubmitButton onBack={() => history.push("/settings")} />
      </Grid>
    </Grid>
  );
};

export default UserList;

import { useHistory } from "react-router-dom";
import {
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  Box,
  Container
} from "@mui/material";
import { useTranslation } from "react-i18next";
import NotFoundImage from "../images/error/Error404.png";
import { useState } from "react";
import { TextFieldStyled } from "../components/inputs/text-field/text-field.styles";
import { Search } from "@mui/icons-material";

export const InvoiceNotFound: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [value, setValue] = useState("");
  const [error, setError] = useState("");

  const handleSearch = async () => {
    if (value) {
      history.push(`/checkout?invoice=${value}`);
    } else {
      setError(t("required"));
    }
  };

  const handleKeyboardEvent = async (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <Grid container component={Container} minHeight="100vh">
      <Grid container item xs={12} md={7}>
        <img
          src={NotFoundImage}
          alt={t("notFoundTitle")}
          width="100%"
          style={{
            margin: "auto",
            objectFit: "contain"
          }}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems={{ md: "flex-start", xs: "center" }}
          justifyContent={{ md: "center", xs: "flex-start" }}
          px={2}
          height="100%"
        >
          <Typography
            variant="h2"
            color="primary.main"
            fontWeight="bold"
            fontSize={{ md: 52, xs: 36 }}
          >
            {t("notFoundTitle")}
          </Typography>

          <Typography py={3} fontSize={21} fontWeight={300} color="#1A0E38">
            {t("notFoundSubtitle")}
          </Typography>

          <TextFieldStyled
            label={t("invoiceCode")}
            size="small"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e) => handleKeyboardEvent(e)}
            error={!!error}
            fullWidth
            helperText={error}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      backgroundColor: (theme) => theme.palette.primary.main,
                      borderRadius: "0 15px 15px 0",
                      marginRight: "-14px",
                      width: 48,
                      "&:hover": {
                        backgroundColor: (theme) => theme.palette.primary.main,
                        opacity: 0.9
                      }
                    }}
                    tabIndex={-1}
                    onClick={() => {
                      handleSearch();
                    }}
                    edge="end"
                  >
                    <Search sx={{ color: "#fff" }} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

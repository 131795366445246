import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut
} from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBexLxaS-mEOffbR1W3z0Qt3NmIRqxn25U",
  authDomain: "kahshpay.firebaseapp.com",
  projectId: "kahshpay",
  storageBucket: "kahshpay.appspot.com",
  messagingSenderId: "439436220162",
  appId: "1:439436220162:web:66a6781ba2d55be5bd941b",
  measurementId: "G-N9BHXELD2D"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const database = getDatabase(app);

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
  }
};

const sendPasswordReset = async (email) => {
  await sendPasswordResetEmail(auth, email);
};

const registerWithEmailAndPassword = (auth, email, password) => {
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      return userCredential.user;
    })
    .catch((error) => {
      return error;
    });
};

const logout = () => {
  signOut(auth);
  window.location.href = "/login";
};

export {
  auth,
  db,
  database,
  signInWithEmailAndPassword,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout
};

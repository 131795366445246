import { AlertColor } from "@mui/material";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from "react";
import AlertComponent from "../components/alertComponent/AlertComponent";
import { useTranslation } from "react-i18next";
import { TRANSLATIONS_PT } from "./../translate/pt";

type AlertProps = {
  message: string;
  severity: AlertColor;
};

interface AlertContextData {
  AlertMessage: (
    severity: AlertColor,
    defaultMessage: string,
    response?: any
  ) => void;
  Alert: () => JSX.Element;
}

type AlertContextProps = {
  children: ReactNode;
};

const AlertContext = createContext({} as AlertContextData);

const AlertProvider: React.FC<AlertContextProps> = ({ children }) => {
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const { t } = useTranslation();

  const AlertMessage = useCallback(
    (severity: AlertColor, defaultMessage: string, response?: any) => {
      if (!response?.response?.data?.message) {
        return setAlert({ message: defaultMessage, severity });
      }

      let responseMessage =
        typeof response?.response?.data?.message === "string"
          ? response?.response?.data?.message
          : response?.response?.data?.message[0];

      let responseMessageReplaced = responseMessage
        .replaceAll(" ", "")
        .replaceAll(".", "");

      if (TRANSLATIONS_PT[responseMessageReplaced]) {
        return setAlert({
          message: responseMessageReplaced,
          severity
        });
      }

      return setAlert({
        message: responseMessage,
        severity
      });
    },
    []
  );

  const Alert = useCallback(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(null);
      }, 5000);
      return (
        <AlertComponent
          open
          severity={alert.severity}
          message={t(alert.message)}
          onClick={() => setAlert(null)}
        />
      );
    } else {
      return <div />;
    }
  }, [alert, t]);

  const providerValue = useMemo(
    () => ({ AlertMessage, Alert }),
    [AlertMessage, Alert]
  );
  return (
    <AlertContext.Provider value={providerValue}>
      {children}
    </AlertContext.Provider>
  );
};

const useAlert = () => {
  const context = useContext(AlertContext);

  if (!context) {
    throw new Error("useAlert must be used within an AlertProvider");
  }

  return context;
};

export { useAlert, AlertProvider };
